import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  TemplateRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'src/app/service/product.service';

interface CruiseDate {
  date: string;
  cruise: string;
}

@Component({
  selector: 'trpx-group-booking',
  templateUrl: './group-booking.component.html',
  styleUrls: ['./group-booking.component.scss'],
})
export class GroupBookingComponent implements OnInit {
  isLoading: boolean = false;
  groupBookModalRef?: BsModalRef;

  groupBookingForms: any = FormGroup;
  countryCode: string = '+91';
  countryData: any;

  formData: any;
  isDisabled: boolean = false;

  @Input() ticketData: any;
  @Input() isEnquiry: any;
  @Input() isDisneyEnquiry: any;

  cruiseForm: any = FormGroup;

  disneyCruiseDates: CruiseDate[] = [];

  disneyCruise: any;
  filteredDates: CruiseDate[] = [];

  @Output('closeModal') closeModal: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
    public router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public productService: ProductService,
    private modalService: BsModalService,
    private cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.getData().subscribe((response: any) => {
      this.countryData = response;
      this.cdr.detectChanges();
    });
    this.disneyCruiseDates = [
      { date: '05-08 Jan 2026', cruise: '3-Night Cruise from Singapore' },
      { date: '12-15 Jan 2026', cruise: '3-Night Cruise from Singapore' },
      { date: '19-22 Jan 2026', cruise: '3-Night Cruise from Singapore' },
      { date: '26-29 Jan 2026', cruise: '3-Night Cruise from Singapore' },
      { date: '02-05 Feb 2026', cruise: '3-Night Cruise from Singapore' },
      { date: '16-19 Feb 2026', cruise: '3-Night Cruise from Singapore' },
      { date: '23-26 Feb 2026', cruise: '3-Night Cruise from Singapore' },
      { date: '02-05 Mar 2026', cruise: '3-Night Cruise from Singapore' },
      { date: '09-12 Mar 2026', cruise: '3-Night Cruise from Singapore' },
      { date: '23-26 Mar 2026', cruise: '3-Night Cruise from Singapore' },
      { date: '01-05 Jan 2026', cruise: '4-Night Cruise from Singapore' },
      { date: '08-12 Jan 2026', cruise: '4-Night Cruise from Singapore' },
      { date: '15-19 Jan 2026', cruise: '4-Night Cruise from Singapore' },
      { date: '22-26 Jan 2026', cruise: '4-Night Cruise from Singapore' },
      { date: '29 Jan - 02 Feb 2026', cruise: '4-Night Cruise from Singapore' },
      { date: '05-09 Feb 2026', cruise: '4-Night Cruise from Singapore' },
      { date: '19-23 Feb 2026', cruise: '4-Night Cruise from Singapore' },
      { date: '26 Feb - 02 Mar 2026', cruise: '4-Night Cruise from Singapore' },
      { date: '05-09 Mar 2026', cruise: '4-Night Cruise from Singapore' },
      { date: '12-16 Mar 2026', cruise: '4-Night Cruise from Singapore' },
      { date: '19-23 Mar 2026', cruise: '4-Night Cruise from Singapore' },
      { date: '26-30 Mar 2026', cruise: '4-Night Cruise from Singapore' },
      {
        date: '15-18 Dec 2025',
        cruise: 'Disney Adventure Maiden Voyage 3-Night Cruise from Singapore',
      },
      {
        date: '27 Dec 2025 - 1 Jan 2026',
        cruise: '5-Night Cruise from Singapore',
      },
    ];

    this.disneyCruise = [
      {
        cruise: '3-Night Cruise from Singapore',
        ticket_id: '35e8bf1b-fd85-416c-8224-a483f6ef577f',
      },
      {
        cruise: '4-Night Cruise from Singapore',
        ticket_id: '2ca7505e-b09f-4a16-b5a4-8265000a6b55',
      },
      {
        cruise: 'Disney Adventure Maiden Voyage 3-Night Cruise from Singapore',
        ticket_id: '6ea05ddf-ec6c-4167-9e8f-ca0bf8ab80f4',
      },
      {
        cruise: '5-Night Cruise from Singapore',
        ticket_id: '18f23358-252d-42d9-a799-a7b28f3d81f4',
      },
    ];
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const data = localStorage.getItem('customer_profile');
      this.formData = data ? JSON.parse(data) : {};

      this.resetForm();
      this.setupCruiseValueChanges();
    }
  }

  getData() {
    return this.http.get('./assets/json/countrycodes.json');
  }

  openModal(groupBookModal: TemplateRef<any>) {
    this.groupBookModalRef = this.modalService.show(groupBookModal);
  }

  close() {
    this.closeModal.emit('');
  }

  receiveCountryCode(code: string) {
    this.groupBookingForms.controls['country_code'].setValue(code);
  }

  resetForm(data?: any) {
    this.groupBookingForms = this.fb.group({
      first_name: [data ? data.first_name : '', [Validators.required]],
      last_name: [data ? data.last_name : '', [Validators.required]],
      email: [data ? data.email : '', [Validators.required, Validators.email]],
      country_code: [
        this.countryCode ? this.countryCode : '+91',
        Validators.required,
      ],
      mobile_number: [data ? data.mobile_number : '', Validators.required],
      message: [data ? data.message : '', Validators.required],
      ticket_id: [
        this.ticketData.ticket_id ? this.ticketData.ticket_id : '',
        Validators.required,
      ],
      cruise: [''],
      date: [''],
    });
  }

  setupCruiseValueChanges(): void {
    this.groupBookingForms
      .get('cruise')
      ?.valueChanges.subscribe((selectedCruise: string) => {
        const selectedCruiseData = this.disneyCruise.find(
          (item: { cruise: string }) => item.cruise === selectedCruise
        );
        const ticketId = selectedCruiseData
          ? selectedCruiseData.ticket_id
          : this.ticketData?.ticket_id;
        this.groupBookingForms.get('ticket_id')?.setValue(ticketId);

        // Filter available dates based on the selected cruise
        this.filteredDates = this.disneyCruiseDates.filter(
          (item) => item.cruise === selectedCruise
        );
        this.groupBookingForms.get('date')?.setValue(''); // Reset the date field
      });
  }

  groupBookingSubmit(form: any) {
    if (!form.controls['email'].valid) {
      this.toastr.warning('Please enter a valid email address.');
      return; // Prevent form submission if email is invalid
    }
    if (form.valid) {
      this.isDisabled = true;

      if (this.isDisneyEnquiry) {
        // Format the message to include Cruise and Date
        const cruise = form.value.cruise || ''; // Default to an empty string if not selected
        const date = form.value.date || ''; // Default to an empty string if not selected
        const originalMessage = form.value.message || ''; // Original message input

        // Append Cruise and Date to the message field
        form.value.message = `Cruise: ${cruise} | Date: ${date} | ${originalMessage}`;

        delete form.value.cruise;
        delete form.value.date;

        this.productService.groupBookingProduct(form.value).subscribe(
          () => {
            this.toastr.success('Disney Enquiry Form Submitted Successfully!');
            this.close();
            // this.resetForm();
            this.isLoading = false;
            this.isDisabled = false;
          },
          () => {
            this.toastr.warning('Something went wrong!. Try Again');
          }
        );
      } else if (!this.isEnquiry && !this.isDisneyEnquiry) {
        this.productService.groupBookingProduct(form.value).subscribe(
          () => {
            this.toastr.success('Group Form Submitted Successfully!');
            this.close();
            // this.resetForm();
            this.isLoading = false;
            this.isDisabled = false;
          },
          () => {
            this.toastr.warning('Something went wrong!. Try Again');
          }
        );
      } else if (this.isEnquiry && !this.isDisneyEnquiry) {
        this.productService.enquiryProduct(form.value).subscribe(
          () => {
            this.toastr.success('Enquiry Form Submitted Successfully!');
            this.close();
            // this.resetForm();
            this.isLoading = false;
            this.isDisabled = false;
          },
          () => {
            this.toastr.warning('Something went wrong!. Try Again');
          }
        );
      }
    } else {
      this.toastr.warning('Please Fill And Submit!');
    }
  }
}
