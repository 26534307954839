<div class="personal-info">
    <div class="row">
        <div class="col-md-12">
            <div class="heading_32">Personal Info</div>
        </div>
    </div>
    <form class="trpx_contact_form" [formGroup]="updateForm" (ngSubmit)="updateFormSubmit(formData?.id, updateForm)">
        <div class="row">
            <div class="col-12 col-md-6">
                <mat-form-field class="mat_field_width" appearance="outline">
                    <mat-label> First Name</mat-label>
                    <input matInput appAlphabetic placeholder="Enter Your First Name" required
                        formControlName="first_name" [ngModel]="formData?.first_name" maxlength="20">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field class="mat_field_width" appearance="outline">
                    <mat-label> Last Name</mat-label>
                    <input matInput appAlphabetic placeholder="Enter Your Last Name" required
                        formControlName="last_name" [ngModel]="formData?.last_name" maxlength="20">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field class="mat_field_width" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="Enter your email ID" [ngModel]="formData?.email" required
                        formControlName="email">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6 position-relative pointer-event-none">
                <mat-form-field class="mat_select_field_country">
                    <mat-select [(value)]="selectedDial" formControlName="country_code"
                        [ngModel]="formData?.country_code">
                        <mat-option *ngFor="let item of countryData" [value]="item.dial_code" disabled>
                            {{ item.dial_code }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="mat_field_width" appearance="outline">
                    <mat-label class="mat_field_mobile_label">WhatsApp Number</mat-label>
                    <input type="tel" [disabled]="true" numbersOnly matInput placeholder="123-456-7890"
                        class="mat_field_mobile_number" required formControlName="mobile_number"
                        [ngModel]="formData?.mobile_number" readonly>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field class="mat_field_width mat_date_field_fullwidth" appearance="outline">
                    <mat-label>Date of Birth</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="dob" [ngModel]="formData?.dob"
                        [max]="minAge" changeDateFormat>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-error class="mat_field_error" *ngIf="updateForm.controls['dob'].hasError('invalidAge')">
                    Minimum age required: 18 years old
                </mat-error>
            </div>
            <div class="col-12 col-md-6" *ngIf="!isTravelAgent">
                <mat-form-field class="mat_field_width mat_date_field_fullwidth" appearance="outline">
                    <mat-label>Gender</mat-label>
                    <mat-select formControlName="gender" [ngModel]="formData?.gender">
                        <mat-option *ngFor="let item of genders" [value]="item.genderName">
                            {{item.genderName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6" *ngIf="isTravelAgent">
                <mat-form-field class="mat_field_width" appearance="outline">
                    <mat-label>Company Name</mat-label>
                    <input matInput appAlphabetic placeholder="Enter Company Name" formControlName="company_name"
                        [ngModel]="formData?.company_name">
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="isTravelAgent">

            <div class="col-12 col-md-6">
                <mat-form-field class="mat_field_width" appearance="outline">
                    <mat-label>Address</mat-label>
                    <input matInput appAlphabetic placeholder="Enter Address" formControlName="address"
                        [ngModel]="formData?.address">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field class="mat_field_width" appearance="outline">
                    <mat-label>City</mat-label>
                    <input matInput appAlphabetic placeholder="Enter City" formControlName="city"
                        [ngModel]="formData?.city">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field class="mat_field_width" appearance="outline">
                    <mat-label>State</mat-label>
                    <input matInput appAlphabetic placeholder="Enter State" formControlName="state"
                        [ngModel]="formData?.state">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field class="mat_field_width" appearance="outline">
                    <mat-label>Zip Code</mat-label>
                    <input matInput appAlphabetic placeholder="Enter Zip Code" formControlName="zip_code"
                        [ngModel]="formData?.zip_code">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field class="mat_field_width" appearance="outline">
                    <mat-label>GST No.</mat-label>
                    <input matInput appAlphabetic placeholder="Enter GST No." formControlName="gst_no"
                        [ngModel]="formData?.gst_no">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field class="mat_field_width" appearance="outline">
                    <mat-label>PAN No.</mat-label>
                    <input matInput appAlphabetic placeholder="Enter PAN No." formControlName="pancard_no"
                        [ngModel]="formData?.pancard_no">
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <button class="btn btn-primary button-green"
                    [disabled]="updateForm.controls['dob'].hasError('invalidAge')">
                    Update Profile
                </button>
            </div>
        </div>
    </form>
</div>