import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-accomodation-form',
  templateUrl: './accomodation-form.component.html',
  styleUrls: ['./accomodation-form.component.scss'],
})
export class AccomodationFormComponent {
  @Input() accomodation: any;
  @Input() totalPax!: number;
  totalPaxCount: [number] = [0];
  @Output() submitData = new EventEmitter<any>();
  selectedRindex!: number;
  totalAccPax: [number] = [0];
  selectedPaxCountDetails: any[] = [];
  paxCountDetails: any[] = [];
  isAccommodationValid = true;

  ngOnInit() {}

  checkRoomPax(i: number) {
    let isAccValid: boolean[] = [];
    this.accomodation?.accommodation.forEach((accomodate: any, j: number) => {
      let total_pax_selected = 0;
      let isValid: boolean[] = []; // Flag to track if any room or accomodation is invalid
      this.selectedPaxCountDetails[j] = {};

      for (let [key] of Object.entries(this.paxCountDetails[j])) {
        this.selectedPaxCountDetails[j][key] = 0;
      }
      let allValid = false;
      accomodate.list.forEach((room: any, k: number) => {
        isValid[k] = true;
        room.room_count = 0;
        room.price_details.forEach((price: any) => {
          room.room_count += price.room_count || 0;
          for (let [key] of Object.entries(this.paxCountDetails[j])) {
            if (price.title == key) {
              this.selectedPaxCountDetails[j][key] += price.room_count || 0;
            }
          }
        });
        if (room.room_count % room.beds_number) {
          room.isValid = false;
          isValid.push(false); // Set isValid to false if any room is invalid
        } else {
          room.isValid = true;
          isValid.push(true);
        }
        total_pax_selected += room.room_count;
      });

      const array: boolean[] = accomodate.list.map((room: any) => room.isValid);
      const anyFalse: boolean = array.some(
        (element: boolean) => element === false
      );
      const allTrue: boolean = array.every(
        (element: boolean) => element === true
      );

      allValid = anyFalse ? false : allTrue ? true : false;

      accomodate.isValid =
        total_pax_selected == this.totalAccPax[i] && allValid;
      if (!accomodate.isValid) {
        isAccValid.push(false); // Set isValid to false if any accomodation is invalid
      } else isAccValid.push(true);
    });

    this.isAccommodationValid = !isAccValid?.includes(false);

    this.submitData.emit({
      data: this.accomodation?.accommodation,
      isValid: !isAccValid?.includes(false),
    });
  }

  ngAfterViewInit() {
    if (this.accomodation && Array.isArray(this.accomodation.accommodation)) {
      this.accomodation?.accommodation.forEach(
        (accomodate: any, index: number) => {
          accomodate.list.forEach((pax: any) => {
            pax.isValid = false;
          });
          this.totalAccPax[index] = accomodate.total_pax_number || 0;
          this.paxCountDetails[index] = {};
          accomodate?.pax_details.forEach((pax: any) => {
            this.paxCountDetails[index][pax.title] = pax?.total_pax;
          });
        }
      );
      this.submitData.emit({
        data: this.accomodation?.accommodation,
        isValid: false,
        isInit: true,
      });
    }
  }

  disableOption(
    index: number,
    ind: number,
    rindex: number,
    pindex: number,
    title: string
  ) {
    if (!this.paxCountDetails[index]) return false;
    let selectedPaxCountDetails: any = {};
    if (this.paxCountDetails[index]) {
      for (let [key] of Object.entries(this.paxCountDetails[index])) {
        selectedPaxCountDetails[key] = 0;
      }
    }

    this.accomodation?.accommodation[index]?.list.forEach(
      (pax: any, j: number) => {
        pax.room_count = 0;
        pax.price_details.forEach((price: any, px: number) => {
          pax.room_count += price.room_count || 0;
          if (px == pindex && rindex != j)
            selectedPaxCountDetails[title] += price.room_count || 0;
        });
      }
    );
    return (
      selectedPaxCountDetails[title] + ind > this.paxCountDetails[index][title]
    );
  }
}
