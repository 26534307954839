import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BlogService } from 'src/app/service/blog.service';
import { HomeService } from 'src/app/service/home.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'trpx-blog-news-tab',
  templateUrl: './blog-news-tab.component.html',
  styleUrls: ['./blog-news-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlogNewsTabComponent implements OnInit {
  @Input() componentName: string = '';
  @Input() componentData: any;

  usefulmediaData: any;
  numberOfDataFetch: number = 9;
  showReadMore: boolean = true;

  countryId: any;

  constructor(
    private apollo: Apollo,
    public homeService: HomeService,
    public blogService: BlogService,
    private cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  ngOnInit(): void {
    if (this.componentData?.length > 0) {
      this.countryId = this.componentData[0].id;
      if (this.componentName == 'BlogComponent') {
        this.getUsefulMedia(this.numberOfDataFetch, 0, '1', '');
      }
      if (this.componentName == 'NewsComponent') {
        this.getUsefulMedia(this.numberOfDataFetch, 0, '2', '');
      }
    }
  }

  tabClickHandler(clickedTabId: string) {
    this.countryId = clickedTabId;
    this.cdr.detectChanges();
    if (this.componentName == 'BlogComponent') {
      this.getUsefulMedia(this.numberOfDataFetch, 0, '1', this.countryId);
    }
    if (this.componentName == 'NewsComponent') {
      this.getUsefulMedia(this.numberOfDataFetch, 0, '2', this.countryId);
    }
  }

  getUsefulMedia(
    limit: number,
    offset: number,
    mediatype: string,
    country?: string
  ) {
    this.apollo
      .watchQuery({
        query: this.blogService.usefulMediasCountry,
        variables: {
          variable1: limit,
          variable2: offset,
          variable3: mediatype,
          variable4: country ?? '',
        },
      })
      .valueChanges.subscribe(({ data, error }: any) => {
        this.usefulmediaData = data?.usefulMedias;

        if (
          this.usefulmediaData &&
          this.usefulmediaData?.length >= this.numberOfDataFetch
        ) {
          this.showReadMore = true;
        } else {
          this.showReadMore = false;
        }

        this.cdr.detectChanges();
      });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    if (isPlatformBrowser(this.platformId)) {
      // Check if the user has scrolled to the bottom of the page
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 700
      ) {
        // Trigger loading more data only if not already loading
        this.readMore();
      }
    }
  }

  readMore() {
    let dataRead = this.usefulmediaData;
    if (dataRead?.length >= 9) {
      this.numberOfDataFetch = this.numberOfDataFetch + 9;
      if (this.componentName == 'BlogComponent') {
        this.getUsefulMedia(this.numberOfDataFetch, 0, '1', this.countryId);
      }
      if (this.componentName == 'NewsComponent') {
        this.getUsefulMedia(this.numberOfDataFetch, 0, '2', this.countryId);
      }
    }
  }
}
