import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  TemplateRef,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { WalletService } from 'src/app/service/wallet.service';
import { walletData } from '../model/wallet.model';
import { HomeService } from 'src/app/service/home.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'trpx-my-wallet',
  templateUrl: './my-wallet.component.html',
  styleUrls: ['./my-wallet.component.scss'],
})
export class MyWalletComponent implements OnInit {
  modalRef!: BsModalRef;
  wallet!: walletData;
  currency!: string;
  isWallet = false;
  itemsToDisplay = 10;
  transactions: any[] = [];
  sortDate: string = 'all_days';
  selectedOption: string = 'all_days';
  isAffiliate = false;

  private dataSubscription: Subscription = new Subscription();
  constructor(
    private modalService: BsModalService,
    private walletService: WalletService,
    private homeService: HomeService,
    private cdr: ChangeDetectorRef,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.dataSubscription = this.homeService.currency$.subscribe((data) => {
      if (data) {
        this.currency = data;
        if (this.currency) this.getWalletData(this.sortDate);
      }
    });
  }
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const data = localStorage.getItem('token');
      if (data) {
      } else {
        this.router.navigate([`/`]);
      }
    }
    this.getCurrency();
    this.getWalletData(this.sortDate);
  }

  getCurrency() {
    this.homeService.currency$.subscribe((data) => {
      if (data) {
        this.currency = data;
      }
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered',
      animated: true,
    });
  }

  getWalletData(sort_date: string) {
    this.isWallet = false;
    this.walletService.getWalletData(this.currency, sort_date).subscribe(
      (res: walletData) => {
        this.wallet = res;
        this.transactions = res?.wallet_transaction.reverse();
        this.isWallet = true;
        this.isAffiliate = res?.wallet?.is_affiliate ?? false;
        this.cdr.detectChanges();
      },
      () => {
        this.isWallet = true;
      }
    );
  }

  onOptionChange() {
    this.getWalletData(this.selectedOption);
  }

  loadMore() {
    this.itemsToDisplay += 5;
  }

  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
  }
}
