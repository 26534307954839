import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'trpx-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.scss'],
})
export class BlogCardComponent implements OnInit {
  @Input() componentData: any;
  @Input() componentName: any;
  hostUrl: any;

  constructor(
    public router: Router,
    private locationStrategy: LocationStrategy,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  ngOnInit(): void {}

  goToSpecificBlog(details: any) {
    let path: string;
    if (details.mediaType === 'blog') {
      path = `/blog/specific-blog`;
    } else if (details.mediaType === 'news') {
      path = `/news/specific-news`;
    } else {
      path = '/';
    }

    let urlTree: UrlTree = this.router.createUrlTree([path], {
      queryParams: { data: details.usefulMediaUrl },
    });
    let url: string = this.router.serializeUrl(urlTree);

    if (this.locationStrategy instanceof HashLocationStrategy) {
      const baseHref = this.locationStrategy.getBaseHref();
      url = `${baseHref}#${url}`;
    }

    if (isPlatformBrowser(this.platformId)) {
      this.hostUrl = window.location.origin;
    }

    // window.open(url, '_blank');
    return `${this.hostUrl}${url}`;
  }
}
