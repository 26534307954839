<div class="destination-single-slider">
    <div *ngFor="let slide of slides" class="slide">
        <div class="slides">
            <div class="slide-image"
                [ngStyle]="{background: 'url('+ slide?.image + ')' + 'no-repeat center / cover,#cccccc'}">
            </div>
            <div class="slider-content">
                <div class="heading_40 text-white mb-3">
                    {{slide?.heading}}
                </div>
                <div class="description_18">
                    {{slide?.subHeading}}
                </div>
                <div>
                    <button type="submit" class="btn btn-primary btn-orange"
                        (click)="openModal(disneyEnquiryBox)">Inquire Now</button>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #disneyEnquiryBox>
    <trpx-group-booking [isDisneyEnquiry]="true" [ticketData]="selectedData"
        (closeModal)="closeModal()"></trpx-group-booking>
</ng-template>