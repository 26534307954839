<ngx-slick-carousel class="destination-single-slider carousel" #slickModal="slick-carousel" [config]="slideConfig">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
        <div class="slides" (click)="sliderOutClick(slide)">
            <div class="slide-image"
                [ngStyle]="{background: 'url('+ slide?.image + ')' + 'no-repeat center / cover,#cccccc'}">
            </div>
            <div class="slider-content">
                <div class="heading_64">
                    {{slide?.heading}}
                </div>
                <div class="description_18">
                    {{slide?.subHeading}}
                </div>
            </div>
        </div>
    </div>
</ngx-slick-carousel>