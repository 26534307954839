import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
})
export class StarRatingComponent {
  @Input() rating: number = 0;
  @Output() ratingChange: EventEmitter<number> = new EventEmitter<number>();

  hoveredIndex: number = -1;

  rateStar(starIndex: number): void {
    this.rating = starIndex + 1;
    this.ratingChange.emit(this.rating);
  }

  onStarHover(starIndex: number): void {
    this.hoveredIndex = starIndex;
  }

  onStarLeave(): void {
    this.hoveredIndex = -1;
  }
}
