import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class LandingService {
  constructor() {}

  landingPage = gql`
    query landingPage($landingSlug: String!, $fields:[String]!) {
      landingPage(landingSlug: $landingSlug, fields: $fields) 
    }
  `;
}
