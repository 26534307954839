import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from 'src/app/service/home.service';
import { PaymentService } from 'src/app/service/payment.service';
import { ToastrService } from 'ngx-toastr';
import { attractionService } from 'src/app/service/attractionPass.service';
import { ProductService } from 'src/app/service/product.service';
import { TransferService } from 'src/app/service/transfer.service';
import { SharedService } from 'src/app/service/shared.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
declare var window: any; // Declare window as a global variable

@Component({
  selector: 'trpx-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.scss'],
})
export class BillingDetailsComponent implements OnInit {
  isLoading: boolean = false;
  travellerDetails: any;
  selectedCountry: any;
  selectedCurrency: any;
  dataSubscription: any;
  selectedPayMethod!: string;
  walletAmount: number = 0;
  walletDeduction: number = 0;
  totalPayableAmount: number = 0;
  removeOfferAmount: number = 0;
  totalPayableValueAmount: number = 0;
  promoCode!: string;
  appliedCode!: string;
  originalValue!: number;
  couponResponse: any;
  message!: string;
  originalPrice: any;
  bookingId: any;
  transfersBookingId: any;
  attractionPassId: any;
  paymentData: any = '';
  attractionPrice: any;
  isTransfer = false;
  @ViewChild('couponBox') couponBox: ElementRef | undefined;
  paymentDisable: boolean = false;
  couponList: any = [];
  showCouponList: boolean = false;
  isCoupon: boolean = false;
  firstLoading: boolean = true;
  customerProfile: any;
  loaderGif: boolean = false;
  indigoLabel: boolean = false;
  yesrewardLabel: boolean = false;
  whiteLabelIndiGo: boolean = false;

  appTitle: string = 'tripXOXO - Activities, tours and transfers';
  appDescription: string = 'Tripxoxo is a digital platform for travelers to book their activities, experiences, tickets, sightseeing tours, and everything that they would need once they reach the destination.';
  appImageUrl: string = 'https://tripxoxo.com/assets/icons/icon-192x192.png';

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public homeService: HomeService,
    public paymentService: PaymentService,
    private productService: ProductService,
    private toastr: ToastrService,
    private attractionPass: attractionService,
    private transferApi: TransferService,
    private sharedService: SharedService,
    private meta: Meta,
    private titleService: Title,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.customerProfile = JSON.parse(
        localStorage.getItem('customer_profile') || '{}'
      );
    }
  }
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      let baseUrl = window.location.origin;
      if (baseUrl?.includes('indigo')) {
        this.loaderGif = true;
        this.indigoLabel = true;
      } else if (baseUrl?.includes('yesrewards')) {
        this.loaderGif = true;
        this.yesrewardLabel = true;
      }
      let whiteLabelCalled = localStorage.getItem('whiteLabelCalled');
      if (whiteLabelCalled) {
        this.whiteLabelIndiGo = true;
      }
    }

    this.route.queryParams.subscribe((params) => {
      this.bookingId = params['booking_id'];
      this.transfersBookingId = params['transfers_details_id'];
      this.promoCode = params['coupon'];
      this.attractionPassId = params['attractionPass_Id'];
      if (this.attractionPassId) {
        this.getAttractionPassPrice(this.attractionPassId);
      }
    });

    this.dataSubscription = this.homeService.country$.subscribe((data) => {
      this.selectedCountry = data;
    });

    this.dataSubscription = this.homeService.country$.subscribe((data) => {
      if (data) {
        this.selectedCountry = data;
      }
    });

    this.dataSubscription = this.homeService.currency$.subscribe((data) => {
      if (data) {
        this.selectedCurrency = data;
      }
    });

    if (this.selectedCountry && this.selectedCurrency) {
      if (this.attractionPassId) {
        this.getAttractionPassPrice(this.attractionPassId);
      }
      if (this.bookingId) {
        this.getTravellerDetailsData(this.bookingId, this.selectedCountry);
      }
      if (this.transfersBookingId) {
        this.getTravellerDetailsData(
          this.transfersBookingId,
          this.selectedCountry
        );
      }
    }
    this.sharedService.setShowFooter(false);
    this.updateMetaTags(this.appTitle, this.appDescription, this.appImageUrl);
  }

  getCouponList() {
    this.attractionPass.getCouponSuggestion().subscribe((result) => {
      this.couponList = result?.coupons;
      this.showCouponList = true;
    });
  }
  couponSelect(item: any) {
    this.promoCode = item;
    this.showCouponList = false;
  }
  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    if (isPlatformBrowser(this.platformId)) {
      if (
        this.couponBox &&
        !this.couponBox.nativeElement.contains(event.target)
      ) {
        this.showCouponList = false;
      }
    }
  }
  getAttractionPassPrice(id: any) {
    this.attractionPass.getAttractionPassPrice(id).subscribe((result) => {
      if (result?.total_payable_price == 0 && result.is_redirect) {
        this.gotoSuccessPage({
          status: 'success',
          message: 'Paid from wallet',
        });
      } else {
        this.attractionPrice = result;
        this.firstLoading = false;
      }
    });
  }

  attractionPayment(paymenttype: string) {
    this.paymentDisable = true;
    let dataToSend = {
      pass_id: this.attractionPassId,
      payment_type: paymenttype,
      is_wallet: this.selectedPayMethod == 'wallet' ? true : false,
    };
    this.attractionPass.postAttractionPass(dataToSend).subscribe(
      (res: any) => {
        if (res?.is_redirect) {
          this.triggerPaymentGateWay(res);
        } else {
          this.gotoSuccessPage({
            status: 'success',
            message: 'Paid from wallet',
          });
        }
        this.trackBeginCheckout();
      },
      (err) => {
        this.paymentDisable = false;
      }
    );
  }

  paymentOption(paymenttype: string) {
    this.paymentDisable = true;
    let dataToSend = {
      booking_id: this.bookingId,
      transfers_details_id: this.transfersBookingId,
      payment_type: paymenttype,
      is_wallet: this.selectedPayMethod == 'wallet' ? true : false,
      is_coupon: this.isCoupon,
      applied_card: this.valueCardBalance,
    };
    this.paymentService.postBillingDetails(dataToSend).subscribe(
      (res: any) => {
        if (res?.is_redirect) {
          this.triggerPaymentGateWay(res);
        } else {
          this.gotoSuccessPage({
            status: 'success',
            message: 'Paid from wallet',
          });
        }
      },
      (err) => {
        this.toastr.error(err?.error?.message ?? 'Error. Try Again!');
      }
    );
    this.trackBeginCheckout();
    this.getAffilliatesAssign();
  }

  getAffilliatesAssign() {
    this.productService.getAffiliateAssign().subscribe((res) => {
      let data = res;
    });
  }

  getTravellerDetailsData(id: any, country?: any) {
    this.paymentService
      .getTravellerDetails(id, false, country)
      .subscribe((res) => {
        if (res?.booking_status == 'paid') {
          this.router.navigate([`/home`]);
        }
        if (res?.status == 'completed') {
          this.router.navigate([`/home`]);
        }
        this.firstLoading = false;
        this.travellerDetails = res;
        this.totalPayableAmount = res?.total_amount;
        this.totalPayableValueAmount = res?.total_amount;
        this.originalPrice = res?.total_amount;
        this.removeOfferAmount = res?.total_amount;
        this.isTransfer = res?.is_transfer ?? false;
        if (this.bookingId && this.promoCode) {
          this.applyCoupon();
        } else if (this.transfersBookingId && this.promoCode) {
          this.applyCoupon();
        }
      });
  }

  // #region nimbbl call
  triggerPaymentGateWay(data: any) {
    let dataToSend = JSON.stringify(data);
    if (this.bookingId) {
      this.router.navigate([`/billing-details`], {
        queryParams: { booking_id: this.bookingId },
      });
    } else if (this.attractionPassId) {
      this.router.navigate([`/billing-details`], {
        queryParams: { attractionPass_Id: this.attractionPassId },
      });
    } else if (this.transfersBookingId) {
      this.router.navigate([`/billing-details`], {
        queryParams: { transfers_details_id: this.transfersBookingId },
      });
    }

    let cllUrl = {
      callback_handler: (response: any) => {
        this.gotoSuccessPage(response);
      },
    };
    var options = { ...data, ...cllUrl };
    if (window) {
      window.checkout = new window.NimbblCheckout(options);
      window.checkout.open(data?.order?.order_id);
    }
  }
  // #endregion nimbbl call

  gotoSuccessPage(response: any) {
    if (response?.status == 'success') {
      // this.toastr.success(response?.message);
      if (this.bookingId) {
        this.router.navigate([`/purchase-success`], {
          queryParams: { booking_id: this.bookingId },
        });
      } else if (this.attractionPassId) {
        this.router.navigate([`/purchase-success`], {
          queryParams: { attractionPass_Id: this.attractionPassId },
        });
      } else if (this.transfersBookingId) {
        this.router.navigate([`/purchase-success`], {
          queryParams: { transfers_details_id: this.transfersBookingId },
        });
      }
    } else if (response?.status == 'failed') {
      // this.toastr.warning(response?.message);
      this.router.navigate([`/payment-failed`]);
    }
  }

  getWalletAmount() {
    this.paymentService.getWalletDetails().subscribe((response: any) => {
      this.walletAmount = response?.wallet?.amount ?? 0;
      if (this.selectedPayMethod == 'wallet') {
        if (this.totalPayableAmount > this.walletAmount) {
          this.walletDeduction = this.walletAmount;
        } else {
          this.walletDeduction = 0;
        }
      }
    });
  }

  setWalletPay() {
    if (this.selectedPayMethod == 'wallet') {
      this.getWalletAmount();
    } else {
      this.totalPayableAmount = this.travellerDetails?.total_amount;
    }
  }
  valueCardBalance: any[] = [];
  errorMessage: string | null = null;
  isButtonDisabled: boolean = false;
  errorTimeout: any;
  isValueLoading: boolean = false;
  negativeBalance: number = 0;

  getValueAmount(data: any) {
    this.isValueLoading = true;
    // Disable the button for 10 seconds after a click
    this.isButtonDisabled = true;
    setTimeout(() => {
      this.isButtonDisabled = false; // Re-enable the button after 10 seconds
    }, 10000); // 10 seconds

    // Clear any existing error message and cancel previous timeout
    this.errorMessage = null;
    if (this.errorTimeout) {
      clearTimeout(this.errorTimeout);
    }

    // Check if the card limit (15) is reached
    if (this.valueCardBalance.length >= 15) {
      this.errorMessage = 'You can use a maximum of 15 cards per transaction.';
      // Optionally, show a message to the user in the UI
      this.hideErrorAfterTimeout();
      this.isValueLoading = false;
      return;
    } else {
      this.paymentService.getValueDetails(data).subscribe(
        (response: any) => {
          if (response) {
            let newCardBalance = parseFloat(response.balance_amount);
            // Check if adding the new card will exceed the total payable amount
            if (this.totalSumBalance >= this.totalPayableValueAmount) {
              this.totalPayableAmount = 0;
            } else {
              this.totalPayableAmount = this.totalPayableAmount -=
                newCardBalance;
            }
            if (this.totalSumBalance > this.totalPayableValueAmount) {
              this.errorMessage = `Cannot add card. Total balance would exceed the payable amount of ${this.travellerDetails?.currency_symbol} ${this.totalPayableValueAmount}.`;
              // Optionally, display an error message to the user in the UI
              this.voucherForm.reset();
              this.hideErrorAfterTimeout();
              this.isValueLoading = false;
              return;
            } else if (newCardBalance === 0) {
              this.errorMessage =
                'Gift card has no remaining balance. Contact us for help.';
              this.voucherForm.reset();
              this.hideErrorAfterTimeout();
              this.isValueLoading = false;
              return; // Stop further processing
            } else if (newCardBalance) {
              this.valueCardBalance.push(response);
              this.isValueLoading = false;
              this.toastr.success('Gift Voucher Added Successfully');
            } else if (response?.error) {
              this.errorMessage = response?.error;
              this.isValueLoading = false;
            }
            // If the voucher is valid, add it to the list

            this.calculateTotalBalance();
            this.voucherForm.reset();
            this.isValueLoading = false;
          } else {
            // Handle invalid voucher (e.g., show error message)
            console.log('Invalid voucher');
            this.isValueLoading = false;
          }
        },
        (error: any) => {
          // Handle error from the API (e.g., network issue, server error)
          this.errorMessage = error?.error?.message;
          this.isValueLoading = false;
        }
      );
    }
  }

  // Method to hide the error message after 30 seconds
  hideErrorAfterTimeout() {
    this.errorTimeout = setTimeout(() => {
      this.errorMessage = null;
    }, 30000); // 30 seconds
  }

  removeCard(index: number) {
    // Get the balance amount of the card being removed
    let cardToRemove = this.valueCardBalance[index];
    let balanceToRemove = parseFloat(
      cardToRemove.balance_amount.replace(/,/g, '')
    );
    // Remove the card at the specified index
    this.valueCardBalance.splice(index, 1);
    this.totalPayableAmount += balanceToRemove;
    if (this.negativeBalance > 0) {
      this.totalPayableAmount -= this.negativeBalance;
      this.negativeBalance = 0; // Reset negative balance to zero
    }
    (this.errorMessage = ''), this.calculateTotalBalance();
  }

  calculateTotalBalance() {
    this.totalSumBalance = this.valueCardBalance?.reduce(
      (total, card) =>
        total + parseFloat(card?.balance_amount.replace(/,/g, '')),
      0
    );
    // Ensure that totalPayableAmount is reduced by totalSumBalance, but not below 0
    if (this.totalSumBalance >= this.totalPayableValueAmount) {
      this.negativeBalance =
        this.totalSumBalance - this.totalPayableValueAmount;
      this.totalPayableAmount = 0;
    }
  }

  isValueCard: boolean = false;
  totalSumBalance: number = 0;

  setValuePay() {
    this.isValueCard = true;
    if (this.selectedPayMethod == 'value_design') {
    } else {
      this.totalPayableAmount = this.travellerDetails?.total_amount;
    }
  }

  voucherForm = new FormGroup({
    voucherNumber: new FormControl('', [
      Validators.required,
      Validators.pattern(/^\d{16}$/), // 16 digit number only
    ]),
    voucherPin: new FormControl('', [
      Validators.required,
      Validators.pattern(/^\d{6}$/), // 6 digit alphanumeric
    ]),
  });

  getBalanceCard(form: any) {
    // console.log('form: any', form);
    if (this.voucherForm.valid) {
      let voucherNumber = this.voucherForm.get('voucherNumber')?.value;
      let voucherPin = this.voucherForm.get('voucherPin')?.value;

      // console.log('Voucher Number:', voucherNumber);
      // console.log('Voucher Pin:', voucherPin);
      let data = {
        card_no: voucherNumber,
        pin: voucherPin,
      };
      // Check if the card number already exists in the valueCardBalance array
      let existingCard = this.valueCardBalance.find(
        (card) => card?.card_no === voucherNumber
      );

      if (existingCard) {
        console.log('This card has already been added.');
        this.errorMessage = 'This card has already been added.';
      } else {
        this.getValueAmount(data);
      }

      // Perform your logic to get the balance
    } else {
      console.error('Form is invalid');
    }
  }

  applyCoupon() {
    if (!this.promoCode) return;
    const data = {
      booking_id: this.bookingId ?? this.transfersBookingId,
      code: this.promoCode,
    };
    this.originalValue = this.totalPayableAmount;
    this.paymentService.postCouponData(data).subscribe(
      (res: any) => {
        if (res?.is_verified) {
          this.message = '';
          this.couponResponse = res;
          this.totalPayableAmount = res?.discounted_price;
          this.appliedCode = data.code;
          this.isCoupon = true;
        } else {
          this.totalPayableAmount = this.originalPrice;
          this.message = res?.msg ?? 'Invalid coupon code';
          this.couponResponse = null;
          this.appliedCode = '';
          this.isCoupon = false;
        }
      },
      (error) => {
        console.log(error);
        if (error.error && error.error.has_error && error.error.message) {
          this.toastr.error(error.error.message);
        }
      }
    );
  }

  removeCoupen() {
    this.appliedCode = '';
    this.totalPayableAmount = this.removeOfferAmount;
    this.couponResponse.offer_price = '';
  }

  setToOriginalAmount(event: any) {
    if (!event.target.value) this.totalPayableAmount = this.originalValue;
  }

  trackBeginCheckout(): void {
    if (isPlatformBrowser(this.platformId)) {
      console.log('Attempting to push begin_checkout event');
      if (window && window.gtag) {
        let items: any = [];
        if (this.bookingId) {
          this.travellerDetails?.tickets?.forEach((ticket: any, index: any) => {
            items.push({
              item_id: this.travellerDetails.prices[index]?.product_code,
              item_name: this.travellerDetails.prices[index]?.product_name,
              coupon: this.travellerDetails.prices[index]?.coupon,
              index: index,
              item_brand: this.travellerDetails.prices[index]?.vendor_name,
              item_variant: ticket.ticket_title,
              location_id: this.travellerDetails.prices[index]?.location,
              price: ticket.total / ticket.total_pax,
              quantity: ticket.total_pax,
            });
          });
        } else if (this.transfersBookingId) {
          items.push({
            item_id: this.travellerDetails?.id,
            item_name: this.travellerDetails?.vehicle_name,
            location_id: this.travellerDetails?.start_location,
            price: this.travellerDetails?.total_price,
            quantity: 1,
          });
        } else if (this.attractionPassId) {
          items.push({
            item_id: this.attractionPrice?.id,
            item_name: this.attractionPrice?.description,
            item_brand: 'attraction_pass',
            item_variant: this.attractionPrice?.slug,
            index: 0,
            quantity: 1,
            price: parseFloat(
              this.attractionPrice?.price.replace(/[^0-9.]/g, '')
            ),
          });
        }
        if (this.bookingId || this.transfersBookingId) {
          window.gtag('event', 'begin_checkout', {
            currency: this.selectedCurrency,
            value: this.travellerDetails?.total_amount,
            items: items,
          });
        } else if (this.attractionPassId) {
          window.gtag('event', 'begin_checkout', {
            currency: this.selectedCurrency,
            value: parseFloat(
              this.attractionPrice?.price.replace(/[^0-9.]/g, '')
            ),
            items: items,
          });
        }

        console.log('begin_checkout', {
          currency: this.selectedCurrency,
          value:
            this.travellerDetails?.total_amount ||
            parseFloat(this.attractionPrice?.price.replace(/[^0-9.]/g, '')),
          items: items,
        });
      } else {
        console.error('gtag function not found');
      }
    }
  }

  isOpenPriceDetails: boolean = false;

  showPriceDetails() {
    this.isOpenPriceDetails = true;
  }

  hidePriceDetails() {
    this.isOpenPriceDetails = false;
  }

  updateMetaTags(title: string, description: string, imageUrl: string): void {
    if (isPlatformBrowser(this.platformId)) {
      this.titleService.setTitle('tripXOXO - Activities, tours and transfers');
      this.meta.removeTag('property="og:title"');
      this.meta.removeTag('property="og:description"');
      this.meta.removeTag('property="og:image"');
      this.meta.removeTag('name="title"');
      this.meta.removeTag('name="description"');

      // Add new Open Graph title, description, and image tags
      this.meta.addTag({ property: 'og:title', content: title });
      this.meta.addTag({ property: 'og:description', content: description });
      this.meta.addTag({ property: 'og:image', content: imageUrl });
      this.meta.addTag({ name: 'title', content: title });
      this.meta.addTag({ name: 'description', content: description });
    }
  }
}
