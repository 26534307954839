import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(private http: HttpClient) { }

  getBookingDetails(currency: any, status: any): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/customerbooking?currency=${currency}&status=${status}`);
  }

  getPromocode(): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/my_offer`);
  }

  checkClubPassBalance(): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/check-club-pass-balance`);
  }

}
