<div class="row m-1">
    <div class="col-md-12 p-0" *ngFor="let rooms of accomodation.accommodation; let i=index;">
        <h6 class="text-uppercase pb-2"> {{rooms?.question}} </h6>
        <div class="accomodations">
            <div class="accomodation_description">Please select <span
                    [ngClass]="{'text-success': isAccommodationValid, 'text-danger': !isAccommodationValid}">{{rooms?.total_pax_number}}
                    Traveller{{rooms?.total_pax_number>1 ?
                    's':''}}</span> to a room.
            </div>
            <ul class="accommodation__room-item" *ngFor="let room of rooms.list; let rindex=index;">
                <li class="d-md-flex">
                    <div class="accommodation__room-type mb-3 mb-md-0"><b>{{room.label}}</b><br>
                        <small *ngIf="room.beds_number>1">Per person in a room for {{room.beds_number}} people.</small>
                        <small *ngIf="room.beds_number==1">Enjoy the comfort and privacy of your own room.</small>
                    </div>
                    <div class="accommodation__room-price-wrapper">
                        <div class="accommodation__room-price d-md-flex flex-center"
                            *ngFor="let details of room.price_details; let pindex=index">
                            <div class="price  mb-3 mb-md-0">
                                {{details.currency_symbol}} {{details.converted_price}}<br><small>per
                                    Traveller</small>
                            </div>
                            <div class="pax mt-4 mt-md-0 row">
                                <div class="col-12">
                                    <mat-form-field class="mat_field_width mat_date_field_fullwidth"
                                        appearance="outline">
                                        <mat-label>Select {{details.title}}</mat-label>
                                        <mat-select [(ngModel)]="details.room_count"
                                            (selectionChange)="checkRoomPax(i, rindex, pindex)">
                                            <mat-option [value]="ind"
                                                [disabled]="disableOption(i, ind, rindex, pindex, details.title)"
                                                *ngFor="let _ of [].constructor(details.pax_count ? details.pax_count+1 : 1); let ind = index">
                                                {{ind}}
                                                {{details.title}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <small class="text-danger" *ngIf="room.room_count&&room.room_count%room.beds_number">This
                    accommodation needs {{room.beds_number}}
                    travellers. Please add {{room.beds_number}} travellers or choose another
                    accommodation type.</small>
            </ul>
        </div>
    </div>
</div>