import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'trpx-destination-card',
  templateUrl: './destination-card.component.html',
  styleUrls: ['./destination-card.component.scss'],
})
export class DestinationCardComponent implements OnInit {
  @Input() slides: any;
  hostUrl: any;

  constructor(
    private router: Router,
    private locationStrategy: LocationStrategy,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  ngOnInit(): void {}

  slideConfig = {
    slidesToShow: 6,
    slidesToScroll: 1,
    infinite: false,
    loop: true,
    dots: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  searchDestination(destinationName: any) {
    let urlTree: UrlTree = this.router.createUrlTree(['/search-results'], {
      queryParams: { type: 'destination', destination: destinationName },
    });
    let url: string = this.router.serializeUrl(urlTree);

    if (this.locationStrategy instanceof HashLocationStrategy) {
      const baseHref = this.locationStrategy.getBaseHref();
      url = `${baseHref}#${url}`;
    }

    if (isPlatformBrowser(this.platformId)) {
      this.hostUrl = window.location.origin;
    }

    return `${this.hostUrl}${url}`;
  }
}
