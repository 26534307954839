<div #errorModal>
    <div class="trpx-group-modal-header modal-header">
        <h4 class="modal-title pull-left heading_18"> Error Message</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="close()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="trpx-group-modal-body modal-body">
        <div class="container">
            <div class="row">
                <div class="col-md-12 px-0">
                    <div class="position-relative">
                        <div class="group_form_outer_cont">
                            {{errorData}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="trpx-group-modal-footer modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="close()">Close</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="close()">Confirm</button>
    </div>
</div>