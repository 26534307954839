import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { walletData } from '../components/profile-component/model/wallet.model';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  constructor(private http: HttpClient) { }

  postWalletData(data: any) {
    return this.http.post(`${environment.bookingApiUrl}/wallet-view`, data)
  }

  getWalletData(currency: string, sort_date: string) {
    return this.http.get<walletData>(`${environment.bookingApiUrl}/wallet-view?sort_date=${sort_date}`)
  }

  submitWithdrawal(data: any) {
    return this.http.post(`${environment.bookingApiUrl}/affiliate-with`, data)
  }

}
