<div id="loading-wrapper" *ngIf="firstLoading">
    <div class="loader-img">
        <img *ngIf="!loaderGif" src="./assets/images/loader-1.gif" alt="">
        <img *ngIf="loaderGif && indigoLabel" src="./assets/images/loader-2.gif" alt="">
        <img *ngIf="loaderGif && yesrewardLabel" src="./assets/images/loader-3.gif" alt="">
    </div>
</div>

<div class="payment-progress" style="background: #F2FAF6;">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-10">
                <ul class="progress-bar">
                    <li class="progress-item heading_16 mb-0 text-center text-md-start">
                        Choose <br class="d-block d-md-none"> Booking
                    </li>
                    <li class="progress-item heading_16 mb-0 text-center">
                        Traveller <br class="d-block d-md-none"> Details
                    </li>
                    <li class="progress-item description_16 mb-0 text-center text-md-end">
                        Billing <br class="d-block d-md-none"> Details
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="trpx-payment-section">
    <div class="container">
        <form [formGroup]="paymentForm" (ngSubmit)="paymentFormSubmit(paymentForm)">
            <div class="row">
                <div class="col-lg-7 col-xxl-8 mobile_padding">
                    <div class="trpx-traveller-details">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="heading_18 my-4 mt-md-0 mb-md-4">Contact Info</div>
                            </div>
                            <div class="col-md-6" *ngIf="!checkIfUserLogin() && checkIfCustomerExists()==null">
                                <trpx-sign-in [fromPayment]="true" (setCustomerForm)="setForm($event)"></trpx-sign-in>
                            </div>
                            <div class="col-12 col-md-6 position-relative"
                                *ngIf="checkIfUserLogin() && checkUserDetails('country_code') && checkUserDetails('id') && !setNewMobile">

                                <div class="custom_selet_component">
                                    <trpx-custom-select-box
                                        (countryCodeEvent)="receiveCountryCode($event)"></trpx-custom-select-box>
                                </div>

                                <mat-form-field class="mat_field_width" appearance="outline">
                                    <mat-label class="mat_field_mobile_label">WhatsApp Number</mat-label>
                                    <input type="tel" matInput appNumeric placeholder="123-456-7890"
                                        class="mat_field_mobile_number" required formControlName="mobile_number"
                                        [readonly]="!isTravelAgent">
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-md-6 position-relative" *ngIf="setNewMobile">
                                <div class="custom_selet_country">
                                    <span>{{paymentForm.value.country_code}}</span>
                                </div>

                                <mat-form-field class="mat_field_width" appearance="outline">
                                    <mat-label class="mat_field_mobile_label">WhatsApp Number</mat-label>
                                    <input type="tel" matInput appNumeric placeholder="123-456-7890"
                                        class="mat_field_mobile_number" required formControlName="mobile_number"
                                        readonly>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-md-6">
                                <mat-form-field class="mat_field_width" appearance="outline">
                                    <mat-label> First Name</mat-label>
                                    <input matInput appAlphabetic placeholder="Enter Your First Name" required
                                        formControlName="first_name" maxlength="20" (keyup)="customerDetailsBackup()">
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-md-6">
                                <mat-form-field class="mat_field_width" appearance="outline">
                                    <mat-label> Last Name</mat-label>
                                    <input matInput appAlphabetic placeholder="Enter Your Last Name" required
                                        formControlName="last_name" maxlength="20" (keyup)="customerDetailsBackup()">
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-md-6">
                                <mat-form-field class="mat_field_width email_capital" appearance="outline">
                                    <mat-label>Email</mat-label>
                                    <input matInput placeholder="Enter your email ID" required formControlName="email"
                                        (keyup)="customerDetailsBackup()">
                                    <mat-error style="margin-top: -10px;margin-bottom: 16px;"
                                        *ngIf="paymentForm.get('email')?.hasError('required')">
                                        Email is required
                                    </mat-error>
                                    <mat-error style="margin-top: -10px;margin-bottom: 16px;"
                                        *ngIf="paymentForm.get('email')?.hasError('email')">
                                        Please enter a valid email address
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="trpx-traveller-details"
                        *ngFor="let formData of travellerDetails?.dynamic_form; let i=index;">
                        <h6 class="text-uppercase pb-2">{{formData?.label}}
                            <div *ngIf="formData?.form_valid" class="float-end"> <svg class="animated-check"
                                    viewBox="0 0 24 24">
                                    <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
                                </svg>
                            </div>
                        </h6>
                        <app-dynamic-form [index]="i" [formData]="formData?.form_questions"
                            *ngIf="formData?.form_questions.length > 0" (submitData)="setFormData($event,i)" />
                    </div>
                    <div class="accomodation-details" *ngIf="travellerDetails?.accommodation">
                        <app-accomodation-form [accomodation]="travellerDetails?.accommodation" [totalPax]="totalPax"
                            (submitData)="setAccommodateData($event)"></app-accomodation-form>
                    </div>
                    <div class="accomodation-details" *ngIf="travellerDetails?.accommodation?.optional_extras">
                        <app-addon-form [optional_extras]="travellerDetails?.accommodation?.optional_extras"
                            [totalPax]="totalPax" (submitData)="setAddonData($event)"></app-addon-form>
                    </div>
                </div>
                <div class="col-lg-5 col-xxl-4 px-0 px-md-3">
                    <div class="trpx-price-details" [ngClass]="{ 'price_show': isOpenPriceDetails }">
                        <div class="spacing">
                            <div class="price_heading d-flex align-items-center justify-content-between">
                                <div class="heading_18">Price Details</div>
                                <div class="close_svg d-block d-md-none" (click)="hidePriceDetails()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none">
                                        <path d="M19.0024 5L7.00244 17" stroke="black" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M7.00244 5L19.0024 17" stroke="black" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div>
                            <div *ngFor="let ticket of travellerDetails?.tickets" class="mb-md-3">
                                <div class="heading_20 mb-3">{{ticket?.product_name}}</div>
                                <div class="description_16 color_151515BD">{{ticket?.ticket_title}}</div>
                                <div class="row">
                                    <div class="col-5 d-flex align-items-center">
                                        <span class="d-block">
                                            <img src="./assets/icons/calendar.svg" alt="">
                                        </span>
                                        <span class="d-block mt-1 ps-2 color_15">{{ticket?.date | date:
                                            "dd-MM-yy"}}</span>
                                    </div>
                                    <div class="col-3 ps-0 d-flex align-items-center">
                                        <span class="d-block"><img src="./assets/icons/user.svg" alt=""></span>
                                        <span class="d-block mt-1 ps-2 color_15">{{ticket?.total_pax}}</span>
                                    </div>
                                    <div class="col-4 ps-0 d-flex align-items-center" *ngIf="ticket?.time_slot">
                                        <span class="d-block"><img src="./assets/icons/clock.svg" alt=""></span>
                                        <span class="d-block mt-1 ps-2 color_15">{{ticket?.time_slot}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="promo_code position-relative" *ngIf="!whiteLabelIndiGo && checkIfNotAgent">
                            <div *ngIf="!appliedCode">
                                <div class="d-flex align-items-center position-relative">
                                    <input (click)="getCouponList()" (keyup)="setToOriginalAmount($event); message ='';"
                                        [(ngModel)]="promoCode" [ngModelOptions]="{standalone: true}" type="text"
                                        placeholder="Enter your promo code"
                                        class="form-control rounded-pill promo_input" />
                                    <div class="btn btn-primary button-green button-promo" (click)="applyCoupon()">
                                        Apply
                                    </div>
                                </div>
                                <small *ngIf="message" class="text-danger d-block text-center mt-2">
                                    {{ message }}
                                </small>
                                <div *ngIf="showCouponList && couponList.length">
                                    <div class="coupon-box" #couponBox>
                                        <div class="coupon-results">
                                            <div class="coupon-scroll">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <ul>
                                                            <li class="description_16 mb-2"
                                                                *ngFor="let item of couponList">
                                                                <a class="pointer" (click)="couponSelect(item?.code)">
                                                                    <div class="d-flex align-items-center pointer">
                                                                        <div class="text-uppercase heading_16 me-3">
                                                                            {{item?.code}}
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="appliedCode">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="heading_16">
                                        Promo Code <span class="color_00A651">{{appliedCode}}</span> Applied
                                    </div>
                                    <small class="text-danger" (click)="removeCoupen()">Remove</small>
                                </div>

                            </div>
                        </div>
                        <div class="line-round">
                            <div class="border-dashed">
                                <div class="d-flex w-100 justify-content-between">
                                    <div class="half-circle vertical right">
                                        <span class="rotate"></span>
                                    </div>
                                    <div class="half-circle vertical left">
                                        <span class="rotate"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="spacing">
                            <div class="heading_18">Price Breakdown</div>
                            <div class="row mb-3" *ngFor="let prices of travellerDetails?.prices">
                                <div class="col-12 heading_16 mb-1 color_13" *ngIf="pricePayload?.addons?.length">
                                    {{prices.ticket}}</div>
                                <div class="col-12 description_14 mt-2 mb-0">
                                    <div class="row" *ngFor="let break of prices?.price_breakdown">
                                        <div class="col-7 description_16 color_13 mb-sm-1">
                                            {{break?.display_name}}
                                        </div>
                                        <div class="col-5 description_16 color_13 mb-1 text-end"
                                            [ngClass]="{'color_00A651' : break?.is_discount || break?.is_product_discount}">
                                            {{break?.is_discount || break?.is_product_discount ? '-' : ''}}
                                            {{travellerDetails?.currency_symbol}}
                                            {{break?.price}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3" *ngIf="couponResponse?.offer_price">
                                <div class="col-12 description_16 mb-0">
                                    <div class="row">
                                        <div class="col-7 heading_14 mb-0 color_13">Coupon Discount</div>
                                        <div class="col-5 heading_14 mb-0 color_00A651 text-end">
                                            - {{ travellerDetails?.currency_symbol }}
                                            {{ couponResponse?.offer_price }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3 pb-5 pb-md-0">
                                <div class="col-7 heading_16 color_13">
                                    Total Payable
                                </div>
                                <div class="col-5 heading_16 text-end color_13">
                                    {{travellerDetails?.currency_symbol}} {{totalPayableAmount ? totalPayableAmount
                                    :
                                    0}}
                                </div>
                            </div>
                            <div class="proceed_fixed">
                                <button class="btn btn-primary button-green w-100" type="submit">
                                    Proceed
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="w-100 d-block d-md-none">
    <div class="w-100 fixed_bottom">
        <div class="heading_30 mb-3">
            {{travellerDetails?.currency_symbol}} &nbsp;{{totalPayableAmount ? totalPayableAmount : 0}}
        </div>
        <div class="d-flex align-items-center d-md-block flex-row-reverse flex-md-row">
            <div class="w-100">
                <button class="btn btn-primary button-green w-100" (click)="showPriceDetails()">
                    Proceed
                </button>
            </div>
        </div>
    </div>
</div>