<div class="row m-1">
    <div class="col-md-12 p-0" *ngFor="let rooms of optional_extras; let i=index;">
        <h6 class="text-uppercase pb-2"> {{rooms?.question}} </h6>
        <div class="optional_extrass">
            <ul class="accommodation__room-item" *ngFor="let room of rooms.list; let rindex=index;">
                <li class="d-md-flex">
                    <div class="accommodation__room-type mb-3 mb-md-0"><b>{{room.label}}</b><br>
                        <small *ngIf="room.beds_number>1">Per person in a room for {{room.beds_number}} people.</small>
                        <small *ngIf="room.beds_number==1">Enjoy the comfort and privacy of your own room.</small>
                    </div>
                    <div class="accommodation__room-price-wrapper">
                        <div class="accommodation__room-price d-md-flex flex-center">
                            <div class="price mb-3 mb-md-0">
                                {{room.currency}} {{room.price}}<br><small>per
                                    Traveller</small>
                            </div>
                            <div class="pax mt-4 mt-md-0 row">
                                <div class="col-12">
                                    <mat-form-field class="mat_field_width mat_date_field_fullwidth"
                                        appearance="outline">
                                        <mat-label>Select Traveller</mat-label>
                                        <mat-select [(ngModel)]="room.room_count"
                                            (openedChange)="selectedRindex=rindex;" (selectionChange)="checkRoomPax()">
                                            <mat-option [value]="ind"
                                                *ngFor="let _ of [].constructor(totalAccPax[i] ? totalAccPax[i]+1 : 1); let ind = index">
                                                {{ind}}
                                                Traveller
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <small class="text-danger"
                    *ngIf="room.room_count%room.beds_number && room.room_count/room.beds_number>1">This
                    accommodation needs {{room.beds_number}}
                    travellers. Please add {{room.beds_number}} travellers or choose another
                    accommodation type.</small>
            </ul>
        </div>
    </div>
</div>