import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/service/auth.service';
import { ContactUsService } from 'src/app/service/contact-us.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'trpx-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @ViewChild('signup', { static: true })
  signup!: ElementRef;

  modalRef?: BsModalRef;
  conatctData: any = [];
  isAffiliate: boolean = false;
  isAgent: boolean = false;

  isFooterLink: boolean = false;

  constructor(
    private authService: AuthService,
    private modalService: BsModalService,
    private apollo: Apollo,
    public contatUsService: ContactUsService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    this.getConatctData();
    if (isPlatformBrowser(this.platformId)) {
      let baseUrl = window.location.origin;
      if (baseUrl?.includes('indigo')) {
        this.isFooterLink = true;
      } else if (baseUrl?.includes('yesrewards')) {
        this.isFooterLink = true;
      }
    }
  }

  openModal(template: any) {
    const modalConfig = {
      ignoreBackdropClick: true,
    };

    const combinedConfig = {
      ...modalConfig,
      class: 'modal-dialog-centered',
      animated: true,
    };

    this.modalRef = this.modalService.show(template, combinedConfig);
  }

  closeModal() {
    this.modalRef?.hide();
  }
  getConatctData() {
    this.apollo
      .watchQuery({
        query: this.contatUsService.contactUs,
      })
      .valueChanges.subscribe(({ data, error }: any) => {
        this.conatctData = data?.contactUs[0];
      });
  }

  logout() {
    this.authService.updateCustomer(null);
    this.authService.logout();
  }
}
