<div class="accortion-component sort_select"
    *ngIf="!aminityList && !['AboutUsComponent','ProductComponent', 'AttractionPassComponent'].includes(componentName)">
    <accordion [isAnimated]="true" class="sortSelect mb-md-4" [class.disabled]="disabled">
        <accordion-group [isOpen]="true" [heading]="'Sort By'">
            <div class="sort-by-select" *ngIf="['SearchResultsComponent'].includes(componentName)">
                <mat-form-field class="mat_field_width_select">
                    <mat-select [(ngModel)]="selectedSort">
                        <mat-option hidden value="" disabled selected>
                            Select Sort Option
                        </mat-option>
                        <mat-option *ngFor="let item of sortItems" [value]="item.value"
                            (click)="selectOption(item.value)">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </accordion-group>
    </accordion>
</div>

<div class="accortion-component sort_select"
    *ngIf="aminityList && aminityList?.length > 0 && !['AboutUsComponent','ProductComponent', 'AttractionPassComponent'].includes(componentName)">
    <accordion [isAnimated]="true" class="sortSelect mb-md-4" [class.disabled]="disabled">
        <accordion-group class="country_group" *ngIf="aminityList" [isOpen]="true" [heading]="'Amenities'">
            <div *ngIf="['SearchResultsComponent'].includes(componentName)">
                <div class="description_16" *ngFor="let amenity of aminityList;let i=index">
                    <input type="checkbox" [(ngModel)]="amenity.selected" (change)="getFilter()"> {{
                    amenity?.name |
                    capitaliseAndReplaceUnderscore}}
                </div>
            </div>
        </accordion-group>
    </accordion>
</div>

<div class="accortion-component sort_select"
    *ngIf="categoryData?.length > 0 && !['AboutUsComponent','ProductComponent', 'AttractionPassComponent'].includes(componentName)">
    <accordion [isAnimated]="true" class="sortSelect mb-md-4">
        <accordion-group class="accordion_category" *ngIf="categoryData?.length > 0" [isOpen]="true"
            [heading]="'Category'">
            <div *ngIf="['SearchResultsComponent'].includes(componentName)">

                <section class="example-section" *ngFor="let category of nestedCategory; let i =index;">
                    <ng-container *ngIf="category.subcategory && category.subcategory.length > 0">
                        <mat-accordion>
                            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span class="example-list-section">
                                            <mat-checkbox class="example-margin" [(ngModel)]="category.selected"
                                                [indeterminate]="someComplete(category, i)"
                                                (change)="setAll($event.checked, category,i)">
                                                {{category.name}}
                                            </mat-checkbox>
                                        </span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <span class="example-list-section">
                                    <ul>
                                        <li *ngFor="let subcat of category.subcategory">
                                            <mat-checkbox (change)="getFilter()" [(ngModel)]="subcat.selected"
                                                [checked]="subcat.selected"
                                                (ngModelChange)="updateAllComplete(category,i)">
                                                {{subcat.category_name}}
                                            </mat-checkbox>
                                        </li>
                                    </ul>
                                </span>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </ng-container>
                </section>
            </div>
        </accordion-group>
        <!-- <div (click)="resetFilter()" *ngIf="['SearchResultsComponent'].includes(componentName)" class="reset-click">
            Reset
        </div> -->
    </accordion>
</div>

<div class="accortion-component sort_select"
    *ngIf="!aminityList && !categoryData && !['AboutUsComponent','ProductComponent', 'AttractionPassComponent'].includes(componentName)">
    <accordion [isAnimated]="true" class="sortSelect mb-md-4" [class.disabled]="disabled">
        <accordion-group class="accordion_category" [isOpen]="true" [heading]="'Category'">
            <div *ngIf="['SearchResultsComponent'].includes(componentName)">

                <section class="example-section" *ngFor="let category of nestedDummyCategory; let i =index;">
                    <ng-container *ngIf="category.subcategory && category.subcategory.length > 0">
                        <mat-accordion>
                            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span class="example-list-section">
                                            <mat-checkbox class="example-margin" [(ngModel)]="category.selected"
                                                [indeterminate]="someComplete(category, i)"
                                                (change)="setAll($event.checked, category,i)">
                                                {{category.name}}
                                            </mat-checkbox>
                                        </span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <span class="example-list-section">
                                    <ul>
                                        <li *ngFor="let subcat of category.subcategory">
                                            <mat-checkbox (change)="getFilter()" [(ngModel)]="subcat.selected"
                                                [checked]="subcat.selected"
                                                (ngModelChange)="updateAllComplete(category,i)">
                                                {{subcat.categoryName}}
                                            </mat-checkbox>
                                        </li>
                                    </ul>
                                </span>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </ng-container>
                </section>
            </div>
        </accordion-group>
        <!-- <div (click)="resetFilter()" *ngIf="['SearchResultsComponent'].includes(componentName)" class="reset-click">
            Reset
        </div> -->
    </accordion>
</div>

<div class="accortion-component sort_select"
    *ngIf="!aminityList && !['AboutUsComponent','ProductComponent', 'AttractionPassComponent'].includes(componentName)">
    <accordion [isAnimated]="true" class="sortSelect mb-md-4" [class.disabled]="disabled">
        <accordion-group *ngIf="!aminityList" class="accordion_rating" [isOpen]="true" [heading]="'Rating'">
            <div *ngIf="['SearchResultsComponent'].includes(componentName)">
                <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedRating"
                    (change)="onRadioChange($event)">
                    <mat-radio-button value="5">
                        <div class="d-flex align-items-center margin_top_minus">
                            <div class="star_width">
                                <svg width="24" height="22" viewBox="0 0 24 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        fill="#FFA51F" />
                                </svg>
                            </div>
                            <div class="star_width">
                                <svg width="24" height="22" viewBox="0 0 24 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        fill="#FFA51F" />
                                </svg>
                            </div>
                            <div class="star_width">
                                <svg width="24" height="22" viewBox="0 0 24 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        fill="#FFA51F" />
                                </svg>
                            </div>
                            <div class="star_width">
                                <svg width="24" height="22" viewBox="0 0 24 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        fill="#FFA51F" />
                                </svg>
                            </div>
                            <div class="star_width">
                                <svg width="24" height="22" viewBox="0 0 24 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        fill="#FFA51F" />
                                </svg>
                            </div>
                        </div>
                    </mat-radio-button>
                    <mat-radio-button value="4">
                        <div class="d-flex align-items-center margin_top_minus">
                            <div class="star_width">
                                <svg width="24" height="22" viewBox="0 0 24 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        fill="#FFA51F" />
                                </svg>
                            </div>
                            <div class="star_width">
                                <svg width="24" height="22" viewBox="0 0 24 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        fill="#FFA51F" />
                                </svg>
                            </div>
                            <div class="star_width">
                                <svg width="24" height="22" viewBox="0 0 24 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        fill="#FFA51F" />
                                </svg>
                            </div>
                            <div class="star_width">
                                <svg width="24" height="22" viewBox="0 0 24 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        fill="#FFA51F" />
                                </svg>
                            </div>
                            <div class="star_width">
                                <svg class="stroke_inside" width="24" height="22" viewBox="0 0 24 22"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        stroke="#FFA51F" stroke-width="2" fill="white" />
                                </svg>

                            </div>
                            <div class="ms-2 mt-1"> & up</div>
                        </div>
                    </mat-radio-button>
                    <mat-radio-button value="3">
                        <div class="d-flex align-items-center margin_top_minus">
                            <div class="star_width">
                                <svg width="24" height="22" viewBox="0 0 24 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        fill="#FFA51F" />
                                </svg>
                            </div>
                            <div class="star_width">
                                <svg width="24" height="22" viewBox="0 0 24 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        fill="#FFA51F" />
                                </svg>
                            </div>
                            <div class="star_width">
                                <svg width="24" height="22" viewBox="0 0 24 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        fill="#FFA51F" />
                                </svg>
                            </div>
                            <div class="star_width">
                                <svg class="stroke_inside" width="24" height="22" viewBox="0 0 24 22"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        stroke="#FFA51F" stroke-width="2" fill="white" />
                                </svg>

                            </div>
                            <div class="star_width">
                                <svg class="stroke_inside" width="24" height="22" viewBox="0 0 24 22"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        stroke="#FFA51F" stroke-width="2" fill="white" />
                                </svg>

                            </div>
                            <div class="ms-2 mt-1"> & up</div>
                        </div>
                    </mat-radio-button>
                    <mat-radio-button value="2">
                        <div class="d-flex align-items-center margin_top_minus">
                            <div class="star_width">
                                <svg width="24" height="22" viewBox="0 0 24 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        fill="#FFA51F" />
                                </svg>
                            </div>
                            <div class="star_width">
                                <svg width="24" height="22" viewBox="0 0 24 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        fill="#FFA51F" />
                                </svg>
                            </div>
                            <div class="star_width">
                                <svg class="stroke_inside" width="24" height="22" viewBox="0 0 24 22"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        stroke="#FFA51F" stroke-width="2" fill="white" />
                                </svg>

                            </div>
                            <div class="star_width">
                                <svg class="stroke_inside" width="24" height="22" viewBox="0 0 24 22"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        stroke="#FFA51F" stroke-width="2" fill="white" />
                                </svg>

                            </div>
                            <div class="star_width">
                                <svg class="stroke_inside" width="24" height="22" viewBox="0 0 24 22"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        stroke="#FFA51F" stroke-width="2" fill="white" />
                                </svg>

                            </div>
                            <div class="ms-2 mt-1"> & up</div>
                        </div>
                    </mat-radio-button>
                    <mat-radio-button value="1">
                        <div class="d-flex align-items-center margin_top_minus">
                            <div class="star_width">
                                <svg width="24" height="22" viewBox="0 0 24 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        fill="#FFA51F" />
                                </svg>
                            </div>
                            <div class="star_width">
                                <svg class="stroke_inside" width="24" height="22" viewBox="0 0 24 22"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        stroke="#FFA51F" stroke-width="2" fill="white" />
                                </svg>

                            </div>
                            <div class="star_width">
                                <svg class="stroke_inside" width="24" height="22" viewBox="0 0 24 22"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        stroke="#FFA51F" stroke-width="2" fill="white" />
                                </svg>

                            </div>
                            <div class="star_width">
                                <svg class="stroke_inside" width="24" height="22" viewBox="0 0 24 22"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        stroke="#FFA51F" stroke-width="2" fill="white" />
                                </svg>

                            </div>
                            <div class="star_width">
                                <svg class="stroke_inside" width="24" height="22" viewBox="0 0 24 22"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5245 0.463528C11.6741 0.00287247 12.3259 0.00286996 12.4755 0.463525L14.8064 7.63729C14.8734 7.8433 15.0653 7.98278 15.282 7.98278H22.8249C23.3093 7.98278 23.5106 8.60259 23.1188 8.88729L17.0164 13.3209C16.8412 13.4482 16.7678 13.6739 16.8348 13.8799L19.1657 21.0537C19.3154 21.5143 18.7881 21.8974 18.3963 21.6127L12.2939 17.1791C12.1186 17.0518 11.8814 17.0518 11.7061 17.1791L5.60374 21.6127C5.21189 21.8974 4.68464 21.5143 4.83432 21.0537L7.16522 13.8799C7.23215 13.6739 7.15882 13.4482 6.98358 13.3209L0.881216 8.88729C0.489359 8.60259 0.690746 7.98278 1.17511 7.98278H8.71805C8.93466 7.98278 9.12664 7.8433 9.19357 7.63729L11.5245 0.463528Z"
                                        stroke="#FFA51F" stroke-width="2" fill="white" />
                                </svg>

                            </div>
                            <div class="ms-2 mt-1"> & up</div>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </accordion-group>
        <!-- <div (click)="clearSelection()" *ngIf="['SearchResultsComponent'].includes(componentName)" class="reset-click">
            Reset
        </div> -->
    </accordion>
</div>

<div class="accortion-component sort_select"
    *ngIf="!['AboutUsComponent','ProductComponent', 'AttractionPassComponent'].includes(componentName)">
    <accordion [isAnimated]="true" class="sortSelect mb-md-4" [class.disabled]="disabled">
        <accordion-group [isOpen]="true" [heading]="'Price'">
            <div *ngIf="aminityList" class="sort-by-select">
                <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="transferOptions"
                    (userChangeEnd)="getFilter()"></ngx-slider>
            </div>
            <div *ngIf="!aminityList" class="sort-by-select">
                <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="productOptions"
                    (userChangeEnd)="getFilter()"></ngx-slider>
            </div>
        </accordion-group>
    </accordion>
</div>

<div class="accortion-component" *ngIf="['AboutUsComponent'].includes(componentName)">
    <accordion [isAnimated]="true">
        <accordion-group *ngFor="let group of componentData" [isOpen]="group.isFirstOpen" [heading]="group.question">
            <div [innerHTML]="group?.answer">
            </div>
        </accordion-group>
    </accordion>
</div>

<div class="accortion-component product-accordion"
    *ngIf="['ProductComponent', 'AttractionPassComponent'].includes(componentName)">
    <accordion [isAnimated]="true">
        <accordion-group *ngFor="let group of componentData" [isOpen]="true" [heading]="group.heading">
            <div [innerHTML]="group?.description">
            </div>
        </accordion-group>
    </accordion>
</div>