import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'trpx-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent implements OnInit {
  errorModalRef?: BsModalRef;

  @Input() errorData: any;
  @Input() autoClose: boolean = false; 
  @Input() autoCloseTime: number = 3000;

  constructor(public modalRef: BsModalRef) {}

  ngOnInit(): void {
    if (this.autoClose) {
      setTimeout(() => {
        this.close();
      }, this.autoCloseTime);
    }
    throw new Error('Method not implemented.');
  }

  close() {
    this.modalRef.hide();
  }
}
