import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
  TemplateRef,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { HomeService } from 'src/app/service/home.service';
import { SharedService } from 'src/app/service/shared.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'trpx-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent {
  signUpModalRef?: BsModalRef;
  otpGenerationForm: any = FormGroup;
  otpVariForm: any = FormGroup;
  signUpForms: any = FormGroup;
  agentForms: any = FormGroup;

  otpVerification: boolean = false;
  signUpDetails: boolean = false;
  timeToShow: any;
  timeIntervalSet: any;
  showPrefix = false;
  countrySuggest = '';
  countryList: any[] = [];
  minAge = new Date(
    new Date().getFullYear() - 18,
    new Date().getMonth(),
    new Date().getDate()
  ).toISOString();

  passedPhone: any;
  isRegistered: boolean = false;
  isLoading: boolean = false;
  countryData: any;
  @Input() showForm: any;
  @Input() mobileNumber: any;
  @Input() countryCode: any;
  @Input() isAffiliate: any;
  @Input() fromPayment: any;
  @Input() isAgent: any;
  @Input() isNew: boolean = false;

  countryDetailsData: any;

  genders: any[] = [];
  genderSubscription!: Subscription;

  @Output('signInTab') signInTab: EventEmitter<any> = new EventEmitter();
  @Output('closeModal') closeModal: EventEmitter<any> = new EventEmitter();

  selectedDial: string = '+91';
  minDate: Date;
  formSubmit: boolean = false;

  constructor(
    public router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public authService: AuthService,
    public cdr: ChangeDetectorRef,
    private modalService: BsModalService,
    private http: HttpClient,
    private apollo: Apollo,
    public homeService: HomeService,
    private sharedService: SharedService,
    private datePipe: DatePipe,
    private dateAdapter: DateAdapter<Date>,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.passedPhone = this.router
        ?.getCurrentNavigation()
        ?.extras?.state?.['mobile_number'].toString();
    }

    this.otpGenerationForm = this.fb.group({
      country_code: ['+91', Validators.required],
      mobile_number: [
        this.passedPhone ? this.passedPhone : null,
        Validators.required,
      ],
      email: [''],
    });

    this.otpVariForm = this.fb.group({
      otpFormArray: this.fb.array([
        this.fb.control(''),
        this.fb.control(''),
        this.fb.control(''),
        this.fb.control(''),
      ]),
    });

    this.getData().subscribe((response: any) => {
      this.countryData = response;
      this.cdr.detectChanges();
    });

    this.minDate = new Date();
    this.minDate.setFullYear(this.minDate.getFullYear() - 18);
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit(): void {
    this.genderSubscription = this.sharedService.genders$.subscribe(
      (updatedGenders) => {
        this.genders = updatedGenders;
      }
    );
    this.genders = this.sharedService.getGenders();

    this.signUpForms = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      dob: [null, [this.minimumAgeValidator.bind(this)]],
      country_code: [
        this.countryCode ? this.countryCode : null,
        // Validators.required,
      ],
      mobile_number: [
        this.mobileNumber ? this.mobileNumber : null,
        // Validators.required,
      ],
      gender: [null],
    });

    this.agentForms = this.fb.group({
      company_name: ['', Validators.required],
      address: ['', Validators.required],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      zip_code: ['', Validators.required],
      pancard_no: ['', Validators.required],
      gst_no: ['', Validators.required],
    });

    this.getCountryDetails();
  }

  minimumAgeValidator(control: FormControl): { [key: string]: boolean } | null {
    if (control.value) {
      const birthDate = new Date(control.value);
      const today = new Date();
      const minDate = new Date();
      minDate.setFullYear(minDate.getFullYear() - 18); // Calculate the minimum date

      if (birthDate > today || birthDate > minDate) {
        return { invalidAge: true }; // Invalid age
      }
    }
    return null; // Age is valid
  }

  getCountryDetails() {
    this.apollo
      .watchQuery({
        query: this.homeService.country,
      })
      .valueChanges.subscribe(({ data, error }: any) => {
        this.countryDetailsData = data?.country;
      });
  }

  disableArrowKeys(event: KeyboardEvent) {
    if (isPlatformBrowser(this.platformId)) {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault();
      }
    }
  }

  getData() {
    return this.http.get('./assets/json/countrycodes.json');
  }

  openModal(signUpModal: TemplateRef<any>) {
    this.signUpModalRef = this.modalService.show(signUpModal);
  }

  get otpInputs(): FormArray {
    return this.otpVariForm.get('otpFormArray') as FormArray;
  }

  resetForm() {
    this.otpGenerationForm.reset();
  }

  receiveCountryCode(code: string) {
    this.signUpForms.controls['country_code'].setValue(code);
    this.otpGenerationForm.controls['country_code'].setValue(code);
  }

  generateOtp() {
    this.isLoading = true;

    if (!this.isRegistered) {
      let dataTosend = this.otpGenerationForm.value;
      dataTosend.mobile_number = dataTosend.mobile_number
        .toString()
        .replace(/\s/g, '');
      this.authService.loginOtpGeneration(dataTosend).subscribe(
        (res: any) => {
          if (this.timeToShow == null || this.timeToShow == undefined) {
            this.timeIntervalSet = this.timer(res.interval_time);
          } else {
            clearInterval(this.timeIntervalSet);
            this.timeIntervalSet = this.timer(res.interval_time);
          }

          this.otpVerification = true;
          this.isLoading = false;
          setTimeout(() => {
            const proceedButton = document.querySelector('#otpNum0');
            (proceedButton as HTMLElement).focus();
          }, 500);
        },
        () => {
          this.isLoading = false;
          this.toastr.show('Please try again');
        }
      );
    } else {
      this.toastr.warning(
        'WhatsApp Number already registered. Login to continue!'
      );
    }
  }

  resendOtp() {
    clearInterval(this.timeIntervalSet);
    this.generateOtp();
  }

  otpVerify() {
    this.isLoading = true;

    let country_code = this.otpGenerationForm.value.country_code;
    let mobile_number = this.otpGenerationForm.value.mobile_number;
    let otp: any = this.otpInputs.value.join('');

    let dataTosend = {
      country_code: country_code,
      mobile_number: mobile_number,
      otp: otp,
    };

    this.authService.loginOtpForVerify(dataTosend).subscribe(
      (res: any) => {
        this.isLoading = false;

        this.toastr.success('OTP Verified Successfully');
        this.signUpDetails = true;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  nextElem(event: any, i: number) {
    if (isPlatformBrowser(this.platformId)) {
      let next = event.target.nextElementSibling;
      let val = event.target.value;

      if (next != null && val.length == 1) {
        if (val) next.focus();
      } else {
        const proceedButton = document.querySelector('#proceed-button');
        (proceedButton as HTMLElement).focus();
      }
    }
  }

  signIn() {
    this.signInTab.emit('');
  }

  signUpFormSubmit(is_agent?: any) {
    this.formSubmit = true;
    if (this.isAgent) this.signUpForms.controls['gender'].setValue('Male');
    if (this.signUpForms.valid) {
      let dataTosend = this.signUpForms.value;
      if (is_agent) {
        if (this.agentForms.valid) {
          dataTosend = { ...dataTosend, ...this.agentForms.value };
        } else {
          this.toastr.warning('Please fill all fields!');
          return;
        }
      }

      if (!this.showForm) {
        let country_code = this.otpGenerationForm.value.country_code;
        let mobile_number = this.otpGenerationForm.value.mobile_number;
        this.signUpForms.controls['country_code'].setValue(country_code);
        this.signUpForms.controls['mobile_number'].setValue(mobile_number);
      }
      if (this.isAffiliate) dataTosend.is_affiliate = this.isAffiliate;
      dataTosend.dob = this.datePipe.transform(
        this.signUpForms.value.dob,
        'yyyy-MM-dd'
      );
      this.isLoading = true;
      if (!this.isAgent) {
        this.authService.createUser(dataTosend).subscribe(
          (res: any) => {
            if (isPlatformBrowser(this.platformId)) {
              this.isLoading = false;
              this.authService.setToken(res?.token?.access);
              this.authService.setCustomer(res?.customer_profile);
              this.authService.updateCustomer(res?.customer_profile);
              this.toastr.success('Registerd Successfully.');
              let currentURL = this.router.url.substr(1);
              let url_lists = ['product/', '/payment'];
              if (!url_lists.some((url) => currentURL?.includes(url))) {
                const urlTree = this.router.parseUrl(currentURL);
                const queryParams = urlTree.queryParams;
                this.router
                  .navigateByUrl('/', { skipLocationChange: true })
                  .then(() => {
                    this.router.navigate(
                      [urlTree.root.children['primary'].segments.join('/')],
                      { queryParams }
                    );
                  });
              }

              this.close();
              if (this.showForm) {
                this.close();
              } else {
                this.close();
              }
            }
          },
          (err) => {
            this.isLoading = false;
          }
        );
      } else {
        dataTosend.country = this.findIdByCountryName(dataTosend.country);
        delete dataTosend['gender'];
        this.authService.createAgent(dataTosend).subscribe(
          (res: any) => {
            this.isLoading = false;
            this.toastr.success(
              'Registerd Successfully. You can login after admin approval.'
            );
            this.close();
          },
          () => {
            this.isLoading = false;
          }
        );
      }
    } else {
      this.toastr.warning('Please fill all fields!');
    }
  }

  findIdByCountryName(countryName: string): string | undefined {
    const country = this.countryList.find(
      (c) => c.country_name === countryName
    );
    return country?.id;
  }

  timer(minute: any) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.timeToShow = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        clearInterval(timer);
      }
    }, 1000);

    return timer;
  }

  close() {
    this.closeModal.emit('');
  }

  getCountryByType(text: string) {
    this.authService.getCountries(text).subscribe((result) => {
      this.countryList = result;
    });
  }

  ngOnDestroy() {
    this.genderSubscription.unsubscribe();
  }
}
