<div class="my-wallet">
  <div class="row">
    <div class="col-md-12">
      <div class="heading_32 mb-4">Payment Methods</div>
    </div>
    <div class="col-md-12">
      <div class="amount_balance">
        <div class="select_payment_option d-flex align-items-center">
          <div class="paymnt_option pointer" (click)="selectedPayMethod = 'phonepe'"
            [ngClass]="{ 'pay-selected': selectedPayMethod == 'phonepe' }">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path
                  d="M30.1554 9.30094C30.2172 8.79947 30.2482 8.29469 30.2482 7.78943C30.2482 3.4875 26.7608 0 22.4588 0H9.441C8.62214 0 7.92303 0.591328 7.78718 1.3988L2.99046 29.9088C2.81834 30.9318 3.60696 31.8641 4.64428 31.8641H9.40521C10.2241 31.8641 10.9363 31.2732 11.0721 30.4656L11.0944 30.3331H11.0945L10.431 34.2766C10.2794 35.1783 10.9744 36 11.8887 36H16.0538C16.7756 36 17.3918 35.4788 17.5115 34.7671L18.6948 27.7344C18.8987 26.5221 19.9483 25.6344 21.1776 25.6344H22.2718C28.2153 25.6344 33.0334 20.8162 33.0334 14.8728C33.0334 12.5729 31.8966 10.54 30.1554 9.30094Z"
                  fill="#002987" />
                <path
                  d="M30.1553 9.30103C29.4105 15.3605 24.246 20.0525 17.9852 20.0525H14.3525C13.5326 20.0525 12.8245 20.5986 12.6024 21.3708L10.431 34.2766C10.2793 35.1783 10.9744 36.0001 11.8887 36.0001H16.0538C16.7755 36.0001 17.3917 35.4789 17.5114 34.7672L18.6947 27.7345C18.8987 26.5222 19.9482 25.6345 21.1775 25.6345H22.2717C28.2152 25.6345 33.0333 20.8163 33.0333 14.8729C33.0333 12.573 31.8965 10.5401 30.1553 9.30103Z"
                  fill="#0085CC" />
                <path
                  d="M14.3528 20.0524H17.9855C24.2463 20.0524 29.4107 15.3603 30.1556 9.30094C29.0384 8.50599 27.6733 8.03687 26.1977 8.03687H16.716C15.6365 8.03687 14.7148 8.81642 14.5356 9.88095L12.6025 21.3706C12.8247 20.5984 13.5328 20.0524 14.3528 20.0524Z"
                  fill="#00186A" />
              </svg>
              <div class="description_16 color_13">PhonePe</div>
            </div>
          </div>
          <div class="paymnt_option pointer" (click)="selectedPayMethod = 'upi'"
            [ngClass]="{ 'pay-selected': selectedPayMethod == 'upi' }">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path
                  d="M30.1554 9.30094C30.2172 8.79947 30.2482 8.29469 30.2482 7.78943C30.2482 3.4875 26.7608 0 22.4588 0H9.441C8.62214 0 7.92303 0.591328 7.78718 1.3988L2.99046 29.9088C2.81834 30.9318 3.60696 31.8641 4.64428 31.8641H9.40521C10.2241 31.8641 10.9363 31.2732 11.0721 30.4656L11.0944 30.3331H11.0945L10.431 34.2766C10.2794 35.1783 10.9744 36 11.8887 36H16.0538C16.7756 36 17.3918 35.4788 17.5115 34.7671L18.6948 27.7344C18.8987 26.5221 19.9483 25.6344 21.1776 25.6344H22.2718C28.2153 25.6344 33.0334 20.8162 33.0334 14.8728C33.0334 12.5729 31.8966 10.54 30.1554 9.30094Z"
                  fill="#002987" />
                <path
                  d="M30.1553 9.30103C29.4105 15.3605 24.246 20.0525 17.9852 20.0525H14.3525C13.5326 20.0525 12.8245 20.5986 12.6024 21.3708L10.431 34.2766C10.2793 35.1783 10.9744 36.0001 11.8887 36.0001H16.0538C16.7755 36.0001 17.3917 35.4789 17.5114 34.7672L18.6947 27.7345C18.8987 26.5222 19.9482 25.6345 21.1775 25.6345H22.2717C28.2152 25.6345 33.0333 20.8163 33.0333 14.8729C33.0333 12.573 31.8965 10.5401 30.1553 9.30103Z"
                  fill="#0085CC" />
                <path
                  d="M14.3528 20.0524H17.9855C24.2463 20.0524 29.4107 15.3603 30.1556 9.30094C29.0384 8.50599 27.6733 8.03687 26.1977 8.03687H16.716C15.6365 8.03687 14.7148 8.81642 14.5356 9.88095L12.6025 21.3706C12.8247 20.5984 13.5328 20.0524 14.3528 20.0524Z"
                  fill="#00186A" />
              </svg>
              <div class="description_16 color_13">UPI</div>
            </div>
          </div>
          <div class="paymnt_option pointer" (click)="selectedPayMethod = 'gpay'"
            [ngClass]="{ 'pay-selected': selectedPayMethod == 'gpay' }">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="43" height="36" viewBox="0 0 43 36" fill="none">
                <g clip-path="url(#clip0_635_1927)">
                  <path
                    d="M38.3948 6.96367C34.2251 4.55667 28.8931 5.98695 26.4835 10.1567L20.4094 20.6789C18.6518 23.7173 20.9144 24.7791 23.4374 26.2918L29.2822 29.6652C31.2614 30.8069 33.7896 30.1291 34.9312 28.1524L41.1755 17.339C43.2732 13.7053 42.0285 9.06146 38.3948 6.96367Z"
                    fill="#EA4335" />
                  <path
                    d="M31.5223 11.3188L25.6776 7.94545C22.4511 6.15438 20.6239 6.03583 19.2992 8.14131L10.679 23.0704C8.27192 27.2375 9.70479 32.567 13.8719 34.9688C17.5056 37.0665 22.1496 35.8218 24.2473 32.1881L33.0326 16.9704C34.1793 14.9912 33.5016 12.4605 31.5223 11.3188Z"
                    fill="#FBBC04" />
                  <path
                    d="M32.6475 3.64437L28.5422 1.27346C24.0015 -1.34744 18.1953 0.206539 15.5744 4.74736L7.7581 18.2848C6.60357 20.282 7.28907 22.8385 9.28635 23.9904L13.8839 26.6448C16.1542 27.9566 19.056 27.1783 20.3678 24.9079L29.2974 9.44284C31.1477 6.23949 35.2427 5.14166 38.446 6.992L32.6475 3.64437Z"
                    fill="#34A853" />
                  <path
                    d="M16.6844 8.62069L12.2467 6.06422C10.2674 4.92516 7.73936 5.60035 6.59771 7.5744L1.27346 16.7746C-1.34744 21.3025 0.20654 27.0958 4.74736 29.709L8.12592 31.6546L12.2235 34.0152L14.0017 35.0383C10.8447 32.9252 9.84225 28.691 11.7725 25.3563L13.1512 22.975L18.1997 14.2516C19.3388 12.2828 18.661 9.75976 16.6844 8.62069Z"
                    fill="#4285F4" />
                </g>
                <defs>
                  <clipPath id="clip0_635_1927">
                    <rect width="42.2752" height="36" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <div class="description_16 color_13">Google Pay/ UPI</div>
            </div>
          </div>
          <div class="paymnt_option pointer" (click)="selectedPayMethod = 'wallet'; setWalletPay()"
            [ngClass]="{ 'pay-selected': selectedPayMethod == 'wallet' }">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="34" height="36" viewBox="0 0 34 36" fill="none">
                <path
                  d="M22.4137 21.9914C22.4148 23.1057 22.8579 24.174 23.6458 24.9619C24.4337 25.7498 25.502 26.1929 26.6163 26.194H33.6205V17.7889H26.6163C25.502 17.79 24.4337 18.2331 23.6458 19.021C22.8579 19.8089 22.4148 20.8772 22.4137 21.9914ZM28.0171 21.9914C28.0171 22.2685 27.935 22.5393 27.781 22.7697C27.6271 23.0001 27.4083 23.1796 27.1523 23.2857C26.8964 23.3917 26.6147 23.4194 26.343 23.3654C26.0712 23.3113 25.8216 23.1779 25.6257 22.982C25.4298 22.7861 25.2964 22.5365 25.2423 22.2647C25.1883 21.993 25.216 21.7113 25.322 21.4554C25.4281 21.1994 25.6076 20.9806 25.838 20.8267C26.0684 20.6727 26.3392 20.5906 26.6163 20.5906C26.9878 20.5906 27.3441 20.7382 27.6068 21.0009C27.8695 21.2636 28.0171 21.6199 28.0171 21.9914ZM8.21742 5.37869L21.8772 0.346813C22.7056 0.0406295 23.5947 -0.0649896 24.4718 0.0386035C25.3489 0.142197 26.189 0.452056 26.9233 0.94281C27.6576 1.43356 28.2652 2.09126 28.6964 2.86203C29.1275 3.63281 29.37 4.49475 29.404 5.37729L8.21742 5.37869ZM19.612 21.9914C19.6142 23.8484 20.3529 25.6287 21.6659 26.9418C22.979 28.2548 24.7593 28.9935 26.6163 28.9957H33.6205V30.3966C33.6191 31.8822 33.0282 33.3066 31.9777 34.3572C30.9272 35.4077 29.5028 35.9985 28.0171 36H5.60342C4.11776 35.9985 2.69337 35.4077 1.64284 34.3572C0.592319 33.3066 0.00148344 31.8822 0 30.3966V12.3143C0.00405569 11.5348 0.170591 10.7647 0.488977 10.0532C0.807362 9.34161 1.2706 8.70427 1.84913 8.1818H29.418C30.6178 8.49059 31.6813 9.18868 32.4418 10.1667C33.2023 11.1447 33.6169 12.3474 33.6205 13.5863V14.9872H26.6163C24.7593 14.9894 22.979 15.728 21.6659 17.0411C20.3529 18.3542 19.6142 20.1345 19.612 21.9914Z"
                  fill="url(#paint0_linear_635_1936)" />
                <defs>
                  <linearGradient id="paint0_linear_635_1936" x1="16.8103" y1="36" x2="16.8103" y2="0.00220142"
                    gradientUnits="userSpaceOnUse">
                    <stop offset="0.011" stop-color="#00A76A" />
                    <stop offset="1" stop-color="#5DCA93" />
                  </linearGradient>
                </defs>
              </svg>
              <div class="description_16 color_13">tripXOXO Wallet</div>
            </div>
          </div>
          <div class="paymnt_option pointer" (click)="selectedPayMethod = 'net_banking'"
            [ngClass]="{ 'pay-selected': selectedPayMethod == 'net_banking' }">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path
                  d="M33.6753 23.7732L8.9163 28.1389C7.38658 28.4086 5.92788 27.3872 5.65816 25.8575L2.93284 10.4017C2.66312 8.87194 3.68455 7.41324 5.2142 7.14352L29.9732 2.77781C31.5029 2.5081 32.9616 3.52953 33.2313 5.05917L35.9567 20.5151C36.2264 22.0447 35.205 23.5035 33.6753 23.7732Z"
                  fill="#3555A2" />
                <path
                  d="M29.9732 2.77781L17.5938 4.96067L21.2958 25.9561L33.6753 23.7733C35.205 23.5035 36.2264 22.0448 35.9566 20.5151L33.2313 5.05924C32.9617 3.52953 31.503 2.5081 29.9732 2.77781Z"
                  fill="#1B3555" />
                <path
                  d="M27.4219 33.2656H2.8125C1.25923 33.2656 0 32.0063 0 30.4531V15.1448C0 13.5915 1.25923 12.3323 2.8125 12.3323H27.4219C28.9751 12.3323 30.2344 13.5915 30.2344 15.1448V30.4531C30.2344 32.0063 28.9751 33.2656 27.4219 33.2656Z"
                  fill="#53D8E3" />
                <path
                  d="M27.4219 12.3323H15.1172V33.2656H27.4219C28.9751 33.2656 30.2344 32.0063 30.2344 30.4531V15.1448C30.2344 13.5915 28.9751 12.3323 27.4219 12.3323Z"
                  fill="#18A8C9" />
                <path d="M0 17.1169H30.2344V21.9017H0V17.1169Z" fill="#ECF6FD" />
                <path
                  d="M8.37363 28.6221H3.58887C3.0064 28.6221 2.53418 29.0943 2.53418 29.6768C2.53418 30.2592 3.0064 30.7314 3.58887 30.7314H8.37363C8.9561 30.7314 9.42832 30.2592 9.42832 29.6768C9.42832 29.0943 8.9561 28.6221 8.37363 28.6221Z"
                  fill="#EAF6FF" />
                <path d="M15.1172 17.1169H30.2344V21.9017H15.1172V17.1169Z" fill="#D6E8FE" />
              </svg>
              <div class="description_16 color_13">Net Banking</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>