import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { Apollo } from 'apollo-angular';
import { LandingService } from 'src/app/service/landing.service';
import { SharedService } from 'src/app/service/shared.service';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'trpx-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  headerData: any;
  componentName: string = 'LandingComponent';
  landingCardsList: any;
  landingCardsGrid: any;
  pageName: any;
  firstLoading: boolean = true;
  dataNull: any;
  pageNameHeading: any;
  loaderGif: boolean = false;
  indigoLabel: boolean = false;
  yesrewardLabel: boolean = false;
  hostUrl: any;
  baseUrl: any;
  appTitle: string = 'tripXOXO - Activities, tours and transfers';
  appDescription: string =
    'Tripxoxo is a digital platform for travelers to book their activities, experiences, tickets, sightseeing tours, and everything that they would need once they reach the destination.';
  appImageUrl: string = 'https://tripxoxo.com/assets/icons/icon-192x192.png';
  isWhiteLabelActive: boolean = false;

  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    public landService: LandingService,
    private route: ActivatedRoute,
    public router: Router,
    private sharedService: SharedService,
    private locationStrategy: LocationStrategy,
    private meta: Meta,
    private titleService: Title,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      let baseUrl = window.location.origin;
      if (baseUrl?.includes('indigo')) {
        this.loaderGif = true;
        this.indigoLabel = true;
      } else if (baseUrl?.includes('yesrewards')) {
        this.loaderGif = true;
        this.yesrewardLabel = true;
      }
    }

    // Combine local storage and subscription logic
    const whiteLabelFromStorage = !!localStorage.getItem('white_label_source');

    this.sharedService.whiteLabelStatus$.subscribe((status) => {
      this.isWhiteLabelActive = status || whiteLabelFromStorage; // Use the value from storage if not set in subscription
      if (this.pageName && this.isWhiteLabelActive) {
        this.getLandingDetails();
      }
    });

    // Initial call to get details if applicable
    if (this.pageName && this.isWhiteLabelActive) {
      this.getLandingDetails();
    }

    this.sharedService.setShowHeader(false);
    this.updateMetaTags(this.appTitle, this.appDescription, this.appImageUrl);
  }

  getLandingDetails() {
    this.apollo
      .watchQuery({
        query: this.landService.landingPage,
        variables: {
          landingSlug: this.pageName,
          fields: [
            'id',
            'name',
            'price',
            'tag',
            'product_slug',
            'product_description_set',
            'product_banners',
            'product_icons',
            'vendor__slug',
            'slashed_price',
          ],
        },
      })
      .valueChanges.subscribe(({ data, error }: any) => {
        this.dataNull = data?.landingPage;
        if (this.dataNull && this.dataNull.length === 0) {
          this.router.navigate(['/']);
        }
        let result = data?.landingPage?.page_type;
        this.headerData = data?.landingPage?.HEADER;
        if (result == 'GRID-LANDING-PAGE') {
          this.landingCardsGrid = data?.landingPage?.PRODUCTS;
          this.firstLoading = false;
        }
        if (result == 'LIST-LANDING-PAGE') {
          this.landingCardsList = data?.landingPage?.PRODUCTS;
          this.firstLoading = false;
        }
        this.pageNameHeading = this.headerData?.subheading2;
      });
  }

  openLink(productSlug: string) {
    if (isPlatformBrowser(this.platformId)) {
      let urlTree: UrlTree = this.router.createUrlTree([
        `/product/${productSlug}`,
      ]);
      this.baseUrl = this.router.serializeUrl(urlTree);

      if (this.locationStrategy instanceof HashLocationStrategy) {
        const baseHref = this.locationStrategy.getBaseHref();
        this.baseUrl = `${baseHref}#${this.baseUrl}`;
      }

      this.hostUrl = window.location.origin;
    }
    return `${this.hostUrl}${this.baseUrl}`;
  }

  updateMetaTags(title: string, description: string, imageUrl: string): void {
    if (isPlatformBrowser(this.platformId)) {
      this.titleService.setTitle('tripXOXO - Activities, tours and transfers');
      this.meta.removeTag('property="og:title"');
      this.meta.removeTag('property="og:description"');
      this.meta.removeTag('property="og:image"');
      this.meta.removeTag('name="title"');
      this.meta.removeTag('name="description"');

      // Add new Open Graph title, description, and image tags
      this.meta.addTag({ property: 'og:title', content: title });
      this.meta.addTag({ property: 'og:description', content: description });
      this.meta.addTag({ property: 'og:image', content: imageUrl });
      this.meta.addTag({ name: 'title', content: title });
      this.meta.addTag({ name: 'description', content: description });
    }
  }
}
