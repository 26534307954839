<div class="trpx-activity-cards">
    <div class="row">
        <div class="col-6 col-md-3" *ngFor="let card of activityCards">

            <div class="trpx-activity-card" (mouseover)="showInfoText[card.id] = true"
                (mouseleave)="showInfoText[card.id] = false">
                <a href="{{openLink(card.productSlug)}}" target="_blank">
                    <div *ngIf="card?.imageUrl[0]?.length" class="trpx-activity-image"
                        [ngStyle]="{background: 'url('+ card?.imageUrl[0] + ')' + 'no-repeat center / cover,#cccccc'}">
                        <div class="starred-div heading_16" *ngIf="card?.tag">{{card?.tag}}</div>
                    </div>
                    <div *ngIf="!card?.imageUrl[0]?.length" class="trpx-activity-image"
                        [ngStyle]="{background: 'url('+ 'https://tripxoxo-prod.s3.ap-south-1.amazonaws.com/tripxoxo_prod/assets/images/empty.webp' + ')' + 'no-repeat center / cover,#cccccc'}">
                        <div class="starred-div heading_16" *ngIf="card?.tag">{{card?.tag}}</div>
                    </div>
                    <div class="trpx-activity-content pointer">
                        <div class="heading_20 height_50 color_15">
                            {{card?.name}}
                        </div>
                        <div class="trpx-activity-star-rating">
                            <span>
                                <img src="./assets/icons/Star.svg" class="img-fluid" alt="">
                            </span>
                            <span class="heading_18 color_15">{{card?.rating}}</span>
                        </div>
                        <div class="trpx-activity-labels" *ngIf="card?.attributes?.length">
                            <span *ngFor="let subCards of card?.attributes">
                                {{subCards}}
                            </span>
                        </div>
                        <div class="position_bottom_price">
                            <div class="mb-1 description_14">Starting From</div>
                            <div class="prices heading_20 color_15">{{card?.price}}
                                <span class="description_14">{{card?.slashedPrice}}</span>
                                <span class="attraction_label" *ngIf="card?.attractionLabel">
                                    {{card?.attractionLabel}}
                                </span>
                            </div>
                        </div>
                    </div>
                </a>
                <!-- <div *ngIf="card?.productInfo && showInfoText[card.id]" class="info-text" [style.left.px]="2" [style.right.px]="2" [style.top.px]="10">
                   {{card?.productInfo}}
                </div> -->
            </div>
        </div>
        <div class="col-6 col-md-3" *ngIf="!activityCards?.length">
            <span>
                No data to show
            </span>
        </div>
    </div>
</div>