<div class="custom_select_box" #selectBox>
    <div class="selected_item pointer" (click)="showCountryListData=!showCountryListData;">
        {{selectedCountryCode}}
    </div>
    <div class="select_box_data" *ngIf="showCountryListData">
        <input type="search" placeholder="Select country/region" [(ngModel)]="searchText">
        <ul class="custom_country_list">
            <li class="custom_country_name heading_18" *ngFor="let item of filterCountryData()">
                <div class="d-flex align-items-center justify-content-between"
                    (click)="changeCountryCode(item.dial_code);">
                    <div class="name">
                        {{ item.name }}
                    </div>
                    <div class="code">
                        {{ item.dial_code }}
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>