<div class="trpx-activity-cards d-none">
    <div class="row">
        <div class="col-6 col-md-3" *ngFor="let card of disneyCards">

            <div class="trpx-activity-card">
                <div class="trpx-activity-image"
                    [ngStyle]="{background: 'url('+ card?.image + ')' + 'no-repeat center / cover,#cccccc'}">
                </div>
                <div class="trpx-activity-content pointer">
                    <div class="heading_20 color_15">
                        {{card?.name}}
                    </div>
                    <div class="description_16">
                        {{card?.description}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="d-block">
    <ngx-slick-carousel class="trpx-activity-cards carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let card of disneyCards" class="slide">
            <div class="trpx-activity-card">
                <div class="trpx-activity-image"
                    [ngStyle]="{background: 'url('+ card?.image + ')' + 'no-repeat center / cover,#cccccc'}">
                </div>
                <div class="trpx-activity-content pointer">
                    <div class="heading_20 color_15">
                        {{card?.name}}
                    </div>
                    <div class="description_16">
                        {{card?.description}}
                    </div>
                </div>
            </div>
        </div>
    </ngx-slick-carousel>
</div>