import {
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SharedService } from './service/shared.service';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { BillingDetailsComponent } from './pages/billing-details/billing-details.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { environment } from 'src/environments/environment';
import { RobotsComponent } from './pages/robots/robots.component';
import { SitemapComponent } from './pages/sitemap/sitemap.component';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from './service/auth.service';

@Component({
  selector: 'trpx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // title =
  //   'Book Experiences, Activities, Sightseeing Tours & Things To Do - tripXOXO';
  @Input() componentName: string = '';
  @Input() componentData: any;
  showHeader$ = this.sharedService.showHeader$;
  showFooter$ = this.sharedService.showFooter$;

  hideHeader: boolean = false;
  hideFooter: boolean = false;
  baseUrl: any;
  tripLogo: string = '/assets/icons/logo.webp';
  logoSrc!: string;
  checkingWhiteLabel: boolean = false;
  isReloaded = false;
  homeUrl: string = '/';

  constructor(
    private titleService: Title,
    private router: Router,
    private sharedService: SharedService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    public authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      if (!localStorage.getItem('Trip_Country'))
        localStorage.setItem('Trip_Country', '');
      if (!localStorage.getItem('Trip_CountryCode'))
        localStorage.setItem('Trip_CountryCode', '');
      if (!localStorage.getItem('Trip_Currency'))
        localStorage.setItem('Trip_Currency', '');
      if (!localStorage.getItem('Trip_CountryCode'))
        localStorage.setItem('Trip_CountryCode', '');

      this.baseUrl = this.getHostURL();

      if (
        this.baseUrl != environment.websiteOrigin &&
        !this.baseUrl?.includes('clubmahindra')
      ) {
        const whiteLabelCalled = localStorage.getItem('whiteLabelCalled');
        if (!whiteLabelCalled) {
          this.getWhiteLabelDetails();
        }
      } else {
        localStorage.removeItem('whiteLabelCalled');
        localStorage.removeItem('white_label_source');
        localStorage.removeItem('logoSrc');
      }
    }
  }

  ngOnInit(): void {
    this.getQueryParameters();
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     const url = event.urlAfterRedirects;

    //     if (url == '/') {
    //       this.titleService.setTitle(
    //         'tripXOXO - Activities, tours and transfers'
    //       );
    //     } else {
    //       this.titleService.setTitle(
    //         'Book Experiences, Activities, Sightseeing Tours & Things To Do - tripXOXO'
    //       );
    //     }
    //   }
    // });
    this.loadGA4();
    this.loadGTM();

    if (this.baseUrl?.includes('indigo')) {
      this.renderer.addClass(document.body, 'indigo');
    } else if (this.baseUrl?.includes('yesrewards')) {
      this.renderer.addClass(document.body, 'yesrewards');
    }

    this.sharedService.hideHeader$.subscribe((value) => {
      this.hideHeader = value;
    });
    this.sharedService.hideFooter$.subscribe((value) => {
      this.hideFooter = value;
    });
  }

  getQueryParameters() {
    if (isPlatformBrowser(this.platformId)) {
      let tokenId;
      this.route.queryParams.subscribe((params: any) => {
        tokenId = params?.token;
        if (tokenId && !this.baseUrl?.includes('yesrewards')) {
          let token = {
            token: tokenId,
          };
          // this.logoSrc = '';
          this.authService
            .clubmahindraValidation(token)
            .subscribe((response) => {
              if (response.status == 'fail') {
                this.authService.logout();
                return;
              }
              // this.customerProfile = response?.customer_profile;
              this.authService.setToken(response?.token?.access);
              this.authService.setRefreshToken(response?.token?.refresh);
              this.authService.setCustomer(response?.customer_profile);
              this.logoSrc = response?.logo || this.tripLogo;
              this.setLogo();
              // localStorage.setItem('logoSrc', this.logoSrc);
              this.authService.setLogo(response?.logo);
              if (!this.isReloaded) {
                this.reloadCurrentRoute();
                this.isReloaded = true;
              }
            });
        } else {
          const details = this.authService.checkIfCMCustomerExists();
          if (details) {
            this.logoSrc = this.authService.getLogo() || this.tripLogo;

            // localStorage.setItem('logoSrc', this.logoSrc);
            this.setLogo();
          } else {
            this.logoSrc = !this.checkingWhiteLabel ? this.tripLogo : '';

            // localStorage.setItem('logoSrc', this.logoSrc);
            this.setLogo();
          }
        }
      });
    }
  }
  setLogo() {
    const data = {
      logoSrc: this.logoSrc,
      homeUrl: this.homeUrl,
    };
    this.sharedService.setHeaderLogo(data);
  }
  getWhiteLabelDetails() {
    this.checkingWhiteLabel = true;
    this.logoSrc = this.tripLogo;
    this.authService.whiteLabelValidation().subscribe((response) => {
      this.logoSrc = response?.logo || this.tripLogo;
      this.homeUrl = response?.logo_href || this.homeUrl;
      // const whiteLabelCalled = localStorage.getItem('whiteLabelCalled');
      // if (!whiteLabelCalled) {
      //   if (this.homeUrl != this.router.url) this.router.navigate([this.homeUrl]);
      // }
      this.authService.setLogo(response?.logo);
      this.authService.setCustomer(response?.customer_profile);
      this.authService.setSource(response?.source);
      if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem('whiteLabelCalled', 'true');
      }

      // localStorage.setItem('logoSrc', this.logoSrc);
      // localStorage.setItem('homeUrl', this.homeUrl);
      this.setLogo();
    });
  }

  getHostURL(): string {
    if (isPlatformBrowser(this.platformId)) {
      return window.location.href; // Safe to use window
    }
    return ''; // Fallback for server
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(currentUrl);
    });
  }

  loadGA4() {
    if (isPlatformBrowser(this.platformId)) {
      const ga4Code = environment.ga4Code;
      const script1 = document.createElement('script');
      script1.async = true;
      script1.src = `https://www.googletagmanager.com/gtag/js?id=${ga4Code}`;
      document.head.appendChild(script1);

      const script2 = document.createElement('script');
      script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${ga4Code}');
    `;
      document.head.appendChild(script2);
    }
  }

  loadGTM() {
    if (isPlatformBrowser(this.platformId)) {
      const gtmId = environment.gtmId;
      const script1 = document.createElement('script');
      script1.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
      document.head.appendChild(script1);

      const noscript = document.createElement('noscript');
      noscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
      document.body.appendChild(noscript);
    }
  }

  onActivate(event: any): void {
    if (isPlatformBrowser(this.platformId)) {
      this.sharedService.setShowHeader(
        !(event instanceof LandingPageComponent)
      );
      this.sharedService.setShowFooter(
        !(
          event instanceof BillingDetailsComponent ||
          event instanceof PaymentComponent
        )
      );
      this.sharedService.setHideHeader(
        !(event instanceof RobotsComponent || event instanceof SitemapComponent)
      );
      this.sharedService.setHideFooter(
        !(event instanceof RobotsComponent || event instanceof SitemapComponent)
      );
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }
}
