import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HomeService } from 'src/app/service/home.service';
import { Subscription, combineLatest, takeWhile } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchResultService } from 'src/app/service/search-results.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'trpx-experiance-tab',
  templateUrl: './experiance-tab.component.html',
  styleUrls: ['./experiance-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExperianceTabComponent implements OnInit {
  @Input() componentName: string = '';
  @Input() componentData: any;

  productDetailsData: any;

  numberOfProductDataFetch: number = 4;
  productShowReadMore: boolean = true;

  destinationId: any;
  selectedCountry!: string | null;
  selectedCurrency!: string | null;
  searchQuery = '';
  isLoading: boolean = false;
  defaultMaxValue: number = 1000000;
  defaultMinValue: number = 0;

  private dataSubscription: Subscription = new Subscription();

  constructor(
    private apollo: Apollo,
    public homeService: HomeService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    public searchService: SearchResultService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.selectedCountry = localStorage.getItem('Trip_Country') ?? '';
      this.selectedCurrency = localStorage.getItem('Trip_Currency') ?? '';
    }
    this.dataSubscription = combineLatest([
      this.homeService.country$,
      this.homeService.currency$,
    ])
      .pipe()
      .subscribe(([country, currency]) => {
        if (country && currency) {
          this.selectedCountry = country;
          this.selectedCurrency = currency;
          this.countryRelatedAPIs();
        }
      });
  }
  ngOnInit(): void {
    this.countryRelatedAPIs();
  }

  getProductDetails(destinationId: any) {
    this.productDetailsData = [];
    this.apollo
      .watchQuery({
        query: this.searchService.productDetailsInitial,
        variables: {
          limit: this.numberOfProductDataFetch,
          offset: 0,
          parms: JSON.stringify({
            type: 'destination',
            destination: destinationId,
          }),
          currency: this.selectedCurrency,
          category: [],
          rating: 0,
          sortBy: '',
          priceLte: this.defaultMaxValue,
          priceGte: this.defaultMinValue,
        },
      })
      .valueChanges.subscribe(({ data, error }: any) => {
        this.productDetailsData = data?.productSearch;
        this.isLoading = false;
        if (
          this.productDetailsData &&
          this.productDetailsData?.length >= this.numberOfProductDataFetch
        ) {
          this.productShowReadMore = true;
        } else {
          this.productShowReadMore = false;
        }
        this.cdr.detectChanges();
      });
  }

  experianceSearchParam() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([`/search-results`], {
        queryParams: { type: 'experiences', experiance: this.searchQuery },
      })
    );
  }

  tabClickHandler(clickedTabId: number, clickedTabName: string) {
    this.isLoading = true;
    this.cdr.detectChanges();
    this.getProductDetails(clickedTabName);
    this.searchQuery = clickedTabName;
  }

  countryRelatedAPIs() {
    this.productDetailsData = [];
    if (this.componentData?.length > 0) {
      this.destinationId = this.componentData[0].id;
      this.searchQuery = this.componentData[0].destinationName;
      this.getProductDetails(this.searchQuery);
    }
  }

  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
  }
}
