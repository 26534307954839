import {
  Component,
  EventEmitter,
  Inject,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { WalletService } from 'src/app/service/wallet.service';
import { environment } from 'src/environments/environment';
import { HomeService } from 'src/app/service/home.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

declare var window: any;
@Component({
  selector: 'app-add-wallet-modal',
  templateUrl: './add-wallet-modal.component.html',
  styleUrls: ['./add-wallet-modal.component.scss'],
})
export class AddWalletModalComponent {
  userData: any;
  selectedCurrency!: string | null;
  amount!: number;
  @Output() getWalletData = new EventEmitter<any>();

  private dataSubscription: Subscription = new Subscription();

  constructor(
    private modalService: BsModalService,
    private walletService: WalletService,
    public homeService: HomeService,
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.selectedCurrency = localStorage.getItem('Trip_Currency') ?? '';
    }
    this.dataSubscription = this.homeService.currency$.subscribe((data) => {
      if (data) {
        this.selectedCurrency = data;
      }
    });
  }

  close() {
    this.modalService.hide();
  }

  submitData() {
    if (!this.amount) {
      return;
    }
    const data = {
      currency: this.selectedCurrency,
      amount: this.amount,
    };
    this.walletService.postWalletData(data).subscribe((res) => {
      if (res) {
        this.triggerPaymentGateWay(res);
      }
    });
  }

  triggerPaymentGateWay(data: any) {
    var options = {
      access_key: data?.access_key,
      order_id: data?.order.order_id,
      callback_url:
        environment.siteUrl + 'purchase-success?booking_id=' + data?.booking_id,
      callback_handler: (response: any) => {
        this.getWalletData.emit();
        this.gotoSuccessPage(response, data?.booking_id);
        this.close();
      },
      redirect: true,
      custom: {
        key_1: 'val_1',
        key_2: 'val_2',
      },
    };
    if (window) {
      window.checkout = new window.NimbblCheckout(options);
      window.checkout.open(data?.order.order_id);
    }
  }

  gotoSuccessPage(response: any, id: any) {
    if (response?.status == 'success') {
      this.toastr.success(response?.message);
      this.router.navigate([`/purchase-success`], {
        queryParams: { wallet_id: id },
      });
    } else if (response?.status == 'failed') {
      this.toastr.warning(response?.message);
      this.router.navigate([`/payment-failed`]);
    }
  }

  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
  }
}
