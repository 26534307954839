import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import { Subscription, combineLatest } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { HomeService } from 'src/app/service/home.service';
import { PaymentService } from 'src/app/service/payment.service';
import { SharedService } from 'src/app/service/shared.service';
import * as moment from 'moment';
import { DateAdapter } from '@angular/material/core';
import { attractionService } from 'src/app/service/attractionPass.service';
import { DynamicFormComponent } from 'src/app/components/dynamic-form/dynamic-form.component';
import { SignInComponent } from 'src/app/components/sign-in/sign-in.component';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'trpx-payment',
  templateUrl: './payment.component.html',
  styleUrls: [
    './payment.component.scss',
    '../../components/dynamic-form/dynamic-form.component.scss',
  ],
})
export class PaymentComponent implements OnInit {
  paymentForm: any = FormGroup;
  isLoading: boolean = false;
  countryData: any;
  countryDetailsData: any;
  formData: any;
  @ViewChildren(DynamicFormComponent)
  dynamicForms!: QueryList<DynamicFormComponent>;

  booking_idPass: any;
  travellerDetails: any;
  private dataSubscription: Subscription = new Subscription();
  selectedCountry!: string;
  selectedCurrency!: string;

  genders: any[] = [];
  genderSubscription!: Subscription;
  selectedDial: string = '+91';
  isCustomerExist: boolean = true;

  couponResponse: any;
  message!: string;
  originalPrice: any;
  originalValue!: number;
  totalPayableAmount: number = 0;
  promoCode!: string;
  appliedCode!: string;
  totalPax: number = 0;
  totalPayableBe4Acco = 0;
  today = new Date().toISOString();
  firstLoading: boolean = true;
  addonAmount: number = 0;
  isAccomodateValid = false;
  setNewMobile = false;
  minAge = new Date(
    new Date().getFullYear() - 18,
    new Date().getMonth(),
    new Date().getDate()
  ).toISOString();
  isTravelAgent: boolean = false;
  loaderGif: boolean = false;
  indigoLabel: boolean = false;
  yesrewardLabel: boolean = false;
  whiteLabelIndiGo: boolean = false;

  @ViewChild('couponBox') couponBox: ElementRef | undefined;
  @ViewChild('pricebox') pricebox!: ElementRef;
  @ViewChild(SignInComponent)
  signInCmpnt!: SignInComponent;
  pricePayload = {
    booking_id: null,
    accommodations: [],
    addons: [],
    coupon: '',
  };

  appTitle: string = 'tripXOXO - Activities, tours and transfers';
  appDescription: string = 'Tripxoxo is a digital platform for travelers to book their activities, experiences, tickets, sightseeing tours, and everything that they would need once they reach the destination.';
  appImageUrl: string = 'https://tripxoxo.com/assets/icons/icon-192x192.png';

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public authService: AuthService,
    public cdr: ChangeDetectorRef,
    private http: HttpClient,
    private apollo: Apollo,
    public homeService: HomeService,
    public paymentService: PaymentService,
    private sharedService: SharedService,
    private dateAdapter: DateAdapter<Date>,
    private attractionPass: attractionService,
    private meta: Meta,
    private titleService: Title,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.getData().subscribe((response: any) => {
      this.countryData = response;
      this.cdr.detectChanges();
    });
    this.route.queryParams.subscribe((params) => {
      this.booking_idPass = params['booking_id'];
    });

    this.dataSubscription = this.authService.mobile$.subscribe((data) => {
      if (data) {
        this.setMobNumber(data);
        this.setNewMobile = true;
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      this.selectedCountry = localStorage.getItem('Trip_Country') ?? '';
      this.selectedCurrency = localStorage.getItem('Trip_Currency') ?? '';
    }
    this.dataSubscription = combineLatest([
      this.homeService.country$,
      this.homeService.currency$,
    ])
      .pipe()
      .subscribe(([country, currency]) => {
        if (country && currency) {
          this.selectedCountry = country;
          this.selectedCurrency = currency;
          this.countryRelatedAPIs();
        }
      });
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      let baseUrl = window.location.origin;
      if (baseUrl?.includes('indigo')) {
        this.loaderGif = true;
        this.indigoLabel = true;
      } else if (baseUrl?.includes('yesrewards')) {
        this.loaderGif = true;
        this.yesrewardLabel = true;
      }
      let whiteLabelCalled = localStorage.getItem('whiteLabelCalled');
      if (whiteLabelCalled) {
        this.whiteLabelIndiGo = true;
      }
    }
    this.genderSubscription = this.sharedService.genders$.subscribe(
      (updatedGenders) => {
        this.genders = updatedGenders;
      }
    );

    this.genders = this.sharedService.getGenders();
    this.setForm();

    this.getCountryDetails();
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('is_customer_exists');
      localStorage.removeItem('customer_mobile');
    }
    this.sharedService.setShowFooter(false);
    this.receiveCountryCode();
    this.updateMetaTags(this.appTitle, this.appDescription, this.appImageUrl);
  }

  setMobNumber(data: any) {
    if (
      !this.authService.checkIfCustomerExists() &&
      this.authService.getItemFromLS('customer_mobile')
    ) {
      this.paymentForm.controls['country_code'].setValue(data.country_code);
      this.paymentForm.controls['mobile_number'].setValue(data.mobile_number);
    }
  }

  setForm(event?: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.isCustomerExist = !!event;
      const prefillData = sessionStorage.getItem('preFilledCustomerData');
      const customerData = localStorage.getItem('customer_profile');
      this.isTravelAgent = !!localStorage.getItem('Trip_Travel_Agent');

      if (!this.isTravelAgent) {
        const formData = prefillData
          ? JSON.parse(prefillData)
          : customerData
          ? JSON.parse(customerData)
          : null;

        if (formData) {
          this.formData = formData;
          this.resetForm(this.formData);

          if (customerData) {
            const passData = JSON.parse(customerData);
            this.paymentForm.controls['country_code'].setValue(
              passData?.country_code
            );
            this.paymentForm.controls['mobile_number'].setValue(
              passData?.mobile_number
            );
          }
        } else {
          this.resetForm();
        }
      } else {
        this.resetForm();
      }

      setTimeout(() => {
        sessionStorage.removeItem('preFilledCustomerData');
      }, 2500);
    }
  }

  receiveCountryCode() {
    if (isPlatformBrowser(this.platformId)) {
      const customerProfileLS = localStorage.getItem('customer_profile')
        ? localStorage.getItem('customer_profile')
        : null;
      const customerProfile = customerProfileLS
        ? JSON.parse(customerProfileLS)
        : null;
      this.paymentForm.controls['country_code'].setValue(
        customerProfile?.country_code ?? '+91'
      );
    }
  }

  resetForm(data?: any) {
    this.paymentForm = this.fb.group({
      booking_id: [this.booking_idPass, Validators.required],
      first_name: [data ? data.first_name : '', Validators.required],
      last_name: [data ? data.last_name : '', Validators.required],
      email: [data ? data.email : '', [Validators.email, Validators.required]],
      dob: [null],
      country_code: [data ? data.country_code : '', Validators.required],
      mobile_number: [data ? data.mobile_number : '', Validators.required],
      gender: [null],
    });
  }

  getData() {
    return this.http.get('./assets/json/countrycodes.json');
  }

  paymentFormSubmit(form: any) {
    if (!this.checkIfUserLogin() && this.checkIfCustomerExists() == null) {
      this.toastr.warning('Please verify mobile number');
      this.signInCmpnt.generateOtp();
      return;
    }
    if (!this.checkIfDynFormValid()) {
      this.makeErrorVisible();
      this.toastr.warning('Please fill all details!');
      return;
    }
    if (form.valid) {
      // form.value.dob = moment(form.value.dob).format('YYYY-MM-DD');

      const data = {
        ...form.value,
      };

      data['other_details'] = {
        dynamic_form: [],
        accommodation: [],
        addons: [],
      };

      if (this.travellerDetails?.dynamic_form?.length) {
        this.travellerDetails.dynamic_form.forEach((form: any) => {
          data['other_details'].dynamic_form.push(form.form_response);
        });
      }

      if (this.travellerDetails?.accommodation) {
        if (
          this.travellerDetails?.accommodation &&
          Array.isArray(this.travellerDetails?.accommodation.accommodation) &&
          this.travellerDetails?.accommodation.accommodation.length > 0
        ) {
          this.travellerDetails?.accommodation?.accommodation.forEach(
            (accommodation: any) => {
              data['other_details'].accommodation.push(accommodation);
            }
          );
        }

        if (
          this.travellerDetails?.accommodation &&
          Array.isArray(this.travellerDetails?.accommodation.optional_extras) &&
          this.travellerDetails?.accommodation.optional_extras.length > 0
        ) {
          this.travellerDetails?.accommodation?.optional_extras.forEach(
            (addons: any) => {
              data['other_details'].addons.push(addons);
            }
          );
        }
      }

      this.paymentService.setTravellerForm(data).subscribe((result: any) => {
        if (result?.customer_profile_data)
          this.authService.setCustomer(result?.customer_profile_data);
        this.authService.updateCustomer(result?.customer_profile_data);

        if (result?.travelagent_profile_data) {
          this.authService.setCustomer(result?.travelagent_profile_data);
          this.authService.updateCustomer(result?.travelagent_profile_data);
        }
        if (result?.token?.access) {
          if (isPlatformBrowser(this.platformId)) {
            this.authService.setToken(result?.token?.access);
            localStorage.removeItem('is_customer_exists');
            localStorage.removeItem('customer_mobile');
          }
        }
        this.triggerPaymentGateWay(result);
      });
      this.trackShippingInfo();
    } else {
      this.toastr.warning('Please Fill And Submit!');
    }
  }

  trackShippingInfo(): void {
    if (isPlatformBrowser(this.platformId)) {
      // console.log('Attempting to push add_shipping_info event');
      if (window && window.gtag) {
        let items: any = [];
        this.travellerDetails?.tickets?.forEach((ticket: any, index: any) => {
          items.push({
            item_id: this.travellerDetails.prices[index]?.product_code,
            item_name: this.travellerDetails.prices[index]?.product_name,
            coupon: this.travellerDetails.prices[index]?.coupon,
            index: index,
            item_brand: this.travellerDetails.prices[index]?.vendor_name,
            item_variant: ticket.ticket_title,
            location_id: this.travellerDetails.prices[index]?.location,
            price: ticket.total / ticket.total_pax,
            quantity: ticket.total_pax,
          });
        });
        window.gtag('event', 'add_shipping_info', {
          currency: this.selectedCurrency,
          value: this.travellerDetails.total_amount,
          items: items,
        });
        console.log('add_shipping_info', {
          currency: this.selectedCurrency,
          value: this.travellerDetails.total_amount,
          items: items,
        });
      } else {
        console.error('gtag function not found');
      }
    }
  }

  // #region nimbbl call
  triggerPaymentGateWay(data: any) {
    let dataToSend = JSON.stringify(data);
    this.router.navigate([`/billing-details`], {
      queryParams: {
        booking_id: this.booking_idPass,
        coupon: this.appliedCode,
      },
    });
  }
  // #endregion nimbbl call

  getCountryDetails() {
    this.apollo
      .watchQuery({
        query: this.homeService.country,
      })
      .valueChanges.subscribe(({ data, error }: any) => {
        this.countryDetailsData = data?.country;
      });
  }

  getTravellerDetailsData(id: any, country?: any, currency?: any) {
    this.paymentService
      .getTravellerDetails(id, true, country, currency)
      .subscribe((res) => {
        this.travellerDetails = res;
        this.totalPayableAmount = res?.total_amount;
        this.originalValue = res?.total_amount;
        this.originalPrice = res?.total_amount;
        this.removeOfferAmount = res?.total_amount;
        this.totalPax = res?.total_pax;
        if (!this.whiteLabelIndiGo) {
          this.getCoupen(id);
        }
        this.firstLoading = false;
        this.isAccomodateValid = !(
          this.travellerDetails?.accommodation &&
          this.travellerDetails?.accommodation?.accommodation.length === 0
        );
      });
  }

  getCoupen(id: any) {
    this.originalValue = this.totalPayableAmount;
    this.paymentService.getCoupenCode(id).subscribe((res) => {
      if (res?.is_verified) {
        this.couponResponse = res;
        this.totalPayableAmount = this.originalPrice - res?.offer_price;
        this.totalPayableAmount =
          this.totalPayableAmount < 0 ? 0 : this.totalPayableAmount;
        this.appliedCode = res?.code;
      } else {
        this.totalPayableAmount = this.originalPrice;
        this.couponResponse = null;
        setTimeout(() => {
          this.message = '';
        }, 5000);
      }
    });
  }
  removeOfferAmount: number = 0;
  applyCoupon() {
    if (!this.promoCode) return;
    const data = {
      booking_id: this.booking_idPass,
      code: this.promoCode,
      mobile_number: this.formData ? this.formData?.mobile_number : null,
    };
    this.originalValue = this.totalPayableAmount;
    this.paymentService.postCouponData(data).subscribe(
      (res: any) => {
        if (res?.is_verified) {
          this.message = '';
          this.couponResponse = res;
          // this.totalPayableAmount = res?.discounted_price;
          this.totalPayableAmount = this.originalPrice - res?.offer_price;
          this.totalPayableAmount =
            this.totalPayableAmount < 0 ? 0 : this.totalPayableAmount;
          this.appliedCode = data.code;
        } else {
          this.totalPayableAmount = this.originalPrice;
          this.message = res?.msg ?? 'Invalid coupon code';
          this.couponResponse = null;
          this.appliedCode = '';
          // setTimeout(() => {
          //   this.message = '';
          // }, 5000);
        }
      },
      (error) => {
        // console.log(error);
        if (error.error && error.error.has_error && error.error.message) {
          this.toastr.error(error.error.message);
        }
      }
    );
  }

  removeCoupen() {
    this.appliedCode = '';
    this.totalPayableAmount = this.removeOfferAmount;
    this.couponResponse.offer_price = '';
  }

  setToOriginalAmount(event: any) {
    if (!event.target.value) this.totalPayableAmount = this.originalValue;
  }

  couponList: any = [];
  showCouponList: boolean = false;

  couponSelect(item: any) {
    this.promoCode = item;
    this.showCouponList = false;
  }

  getCouponList() {
    this.attractionPass.getCouponSuggestion().subscribe((result) => {
      this.couponList = result?.coupons;
      this.showCouponList = true;
    });
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    if (
      this.couponBox &&
      !this.couponBox.nativeElement.contains(event.target)
    ) {
      this.showCouponList = false;
    }
  }

  countryRelatedAPIs() {
    this.getTravellerDetailsData(
      this.booking_idPass,
      this.selectedCountry,
      this.selectedCurrency
    );
  }

  setFormData(event: any, i: number) {
    for (const key in event.form.value) {
      if (
        event.form.value[key] &&
        event.form.value[key].countryCode &&
        event.form.value[key].phoneNumber
      ) {
        event.form.value[key] =
          event.form.value[key].countryCode + event.form.value[key].phoneNumber;
      }
    }
    if (event.ignore_keys.length)
      event.form.value.ignore_keys = event.ignore_keys;
    this.travellerDetails.dynamic_form[i].form_response = event.form.value;
    this.travellerDetails.dynamic_form[i].form_valid = event.form.valid;
  }

  setAccommodateData(event: any) {
    this.pricePayload.booking_id = this.booking_idPass;
    this.pricePayload['accommodations'] = [];
    this.pricePayload['coupon'] = this.appliedCode || '';
    event.data.forEach((acco: never) => {
      this.pricePayload['accommodations'].push(acco);
    });
    if (event.data.length && !event.isInit)
      this.getUpdatedPrice(this.pricePayload);
    this.isAccomodateValid = event.isValid;
  }

  setAddonData(event: any) {
    this.travellerDetails.optional_extras = event;
    this.addonAmount = 0;
    let total_pax_amount = 0;
    event.data.forEach((acco: any) => {
      acco.list.forEach((list: any) => {
        total_pax_amount += list.room_count * list.price;
      });
    });
    this.pricePayload.booking_id = this.booking_idPass;
    this.pricePayload['addons'] = [];
    this.pricePayload['coupon'] = this.appliedCode || '';
    event.data.forEach((addon: never) => {
      this.pricePayload['addons'].push(addon);
    });
    if (event.data.length && !event.isInit)
      this.getUpdatedPrice(this.pricePayload);
  }

  getUpdatedPrice(data: any) {
    this.paymentService.getPriceSuggestion(data).subscribe((res) => {
      this.travellerDetails.prices = res.prices;
      this.travellerDetails.tickets = res.tickets;

      this.originalPrice = res?.total_amount;
      this.originalValue = res?.total_amount;
      this.removeOfferAmount = res?.total_amount;
      this.couponResponse = res?.coupon?.is_verified ? res?.coupon : null;
      if (this.couponResponse?.offer_price)
        this.totalPayableAmount =
          res?.total_amount - this.couponResponse?.offer_price;
      else this.totalPayableAmount = res?.total_amount;
      if (!res?.coupon?.is_verified) this.appliedCode = '';
    });
  }

  checkIfDynFormValid() {
    if (
      this.travellerDetails &&
      this.travellerDetails?.accommodation?.accommodation &&
      Array.isArray(this.travellerDetails?.accommodation?.accommodation) &&
      this.travellerDetails?.accommodation?.accommodation?.length
    ) {
      return (
        this.isAccomodateValid &&
        this.travellerDetails?.dynamic_form.every(
          (form: any) => form.form_valid
        ) &&
        this.paymentForm.valid
      );
    } else if (
      this.travellerDetails &&
      this.travellerDetails.dynamic_form?.length
    ) {
      return (
        this.travellerDetails.dynamic_form.every(
          (form: any) => form.form_valid
        ) && this.paymentForm.valid
      );
    } else {
      return this.paymentForm.valid;
    }
  }

  checkIfUserLogin() {
    return this.authService.getCustomer() ? true : false;
    // return localStorage.getItem('customer_profile') ? true : false;
  }

  // checkUserDetails(tag: any) {
  //   const customer = localStorage.getItem('customer_profile');
  //   let details: any;
  //   if (customer) details = JSON.parse(customer);
  //   if (details?.[tag]) return true;
  //   return false;
  // }

  checkUserDetails(tag: any): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const customer = localStorage.getItem('customer_profile');
      if (customer) {
        const details = JSON.parse(customer);
        return !!details?.[tag];
      }
    }
    return false;
  }

  checkIfTokenExists() {
    return this.authService.getToken() ? true : false;
  }

  checkIfCustomerExists() {
    const customerExists = this.authService.checkIfCustomerExists();
    return customerExists !== undefined ? customerExists : null;
  }

  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
    this.genderSubscription.unsubscribe();
  }

  makeErrorVisible() {
    this.dynamicForms.forEach((form) => {
      form.markAllAsTouched();
    });
  }

  get checkIfNotAgent() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('Trip_Travel_Agent') ? false : true;
    }
    return true; // Default return value in case it's being accessed on the server
  }

  customerDetailsBackup() {
    // console.log(this.paymentForm.value)
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.setItem(
        'preFilledCustomerData',
        JSON.stringify(this.paymentForm.value)
      );
    }
  }

  isOpenPriceDetails: boolean = false;

  showPriceDetails() {
    this.isOpenPriceDetails = true;
  }

  hidePriceDetails() {
    this.isOpenPriceDetails = false;
  }

  updateMetaTags(title: string, description: string, imageUrl: string): void {
    if (isPlatformBrowser(this.platformId)) {
      this.titleService.setTitle('tripXOXO - Activities, tours and transfers');
      this.meta.removeTag('property="og:title"');
      this.meta.removeTag('property="og:description"');
      this.meta.removeTag('property="og:image"');
      this.meta.removeTag('name="title"');
      this.meta.removeTag('name="description"');

      // Add new Open Graph title, description, and image tags
      this.meta.addTag({ property: 'og:title', content: title });
      this.meta.addTag({ property: 'og:description', content: description });
      this.meta.addTag({ property: 'og:image', content: imageUrl });
      this.meta.addTag({ name: 'title', content: title });
      this.meta.addTag({ name: 'description', content: description });
    }
  }
}
