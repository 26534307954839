<div class="trpx-experiance-tab">
  <tabset>
    <tab *ngFor="let tabz of componentData; let i=index;" [heading]="tabz?.country_name" [active]="i==0 ? true : false"
      [customClass]="tabz.customClass" (selectTab)="tabClickHandler(tabz.id)">
      <trpx-blog-card [componentData]="usefulmediaData" />
      <div class="row" *ngIf="showReadMore">
        <div class="col-md-12 text-center">
          <div class="readmore text-center pointer" (click)="readMore()">
            See More
          </div>
        </div>
      </div>
    </tab>
  </tabset>
</div>