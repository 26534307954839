<div class="my-wallet">
    <div class="row">
        <div class="col-md-12">
            <div class="heading_32">My Wallet</div>
        </div>
        <div class="col-md-12">
            <div class="amount_balance">
                <div class="d-flex align-items-center">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="54" viewBox="0 0 50 54" fill="none">
                            <path
                                d="M33.4593 33.0758C33.4609 34.7203 34.1149 36.297 35.2778 37.4599C36.4406 38.6227 38.0173 39.2767 39.6618 39.2784H49.9995V26.8732H39.6618C38.0173 26.8749 36.4406 27.5289 35.2778 28.6917C34.1149 29.8546 33.4609 31.4313 33.4593 33.0758ZM41.7294 33.0758C41.7294 33.4847 41.6081 33.8845 41.3809 34.2245C41.1537 34.5645 40.8308 34.8295 40.453 34.986C40.0753 35.1424 39.6595 35.1834 39.2585 35.1036C38.8574 35.0238 38.489 34.8269 38.1999 34.5378C37.9107 34.2486 37.7138 33.8802 37.634 33.4792C37.5543 33.0781 37.5952 32.6624 37.7517 32.2846C37.9082 31.9068 38.1732 31.5839 38.5132 31.3567C38.8532 31.1295 39.2529 31.0083 39.6618 31.0083C40.2102 31.0083 40.7361 31.2261 41.1238 31.6138C41.5115 32.0016 41.7294 32.5275 41.7294 33.0758ZM12.507 8.55705L32.6674 1.13051C33.8901 0.678617 35.2024 0.522734 36.4969 0.675627C37.7913 0.82852 39.0312 1.28584 40.115 2.01015C41.1987 2.73445 42.0955 3.70514 42.7318 4.84273C43.3682 5.98032 43.7261 7.25246 43.7762 8.55499L12.507 8.55705ZM29.3242 33.0758C29.3275 35.8165 30.4177 38.444 32.3557 40.382C34.2936 42.3199 36.9211 43.4101 39.6618 43.4134H49.9995V45.4809C49.9973 47.6736 49.1252 49.7759 47.5748 51.3264C46.0243 52.8768 43.9221 53.7488 41.7294 53.751H8.649C6.45631 53.7488 4.35405 52.8768 2.80358 51.3264C1.25311 49.7759 0.381096 47.6736 0.378906 45.4809V18.7934C0.384892 17.6429 0.630682 16.5063 1.10059 15.4561C1.57049 14.4059 2.25419 13.4653 3.10804 12.6942H43.7969C45.5677 13.1499 47.1373 14.1802 48.2597 15.6237C49.3821 17.0671 49.994 18.8422 49.9995 20.6707V22.7382H39.6618C36.9211 22.7415 34.2936 23.8317 32.3557 25.7696C30.4177 27.7076 29.3275 30.3351 29.3242 33.0758Z"
                                fill="url(#paint0_linear_646_4892)" />
                            <defs>
                                <linearGradient id="paint0_linear_646_4892" x1="25.1892" y1="53.751" x2="25.1892"
                                    y2="0.621901" gradientUnits="userSpaceOnUse">
                                    <stop offset="0.011" stop-color="#00A76A" />
                                    <stop offset="1" stop-color="#5DCA93" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div class="ms-2">
                        <div class="description_14 color_13">
                            Available Balance
                        </div>
                        <div class="heading_28 color_13" [ngClass]="{'animate-amount': isWallet}"
                            *ngIf="wallet?.wallet">
                            {{wallet.wallet.currency_symbol}} {{wallet.wallet.amount}}
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <button (click)="openModal(template)" class="btn button-green me-3">Add Amount</button>
                    <button *ngIf="isAffiliate" (click)="openModal(withdraw)" class="btn button-green">Withdraw</button>
                </div>
            </div>
            <div class="transaction_details">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="heading_20 w-100 color_15">Transactions</div>
                    <div class="d-flex align-items-center w-100">
                        <div class="w-100 text-end color_15">
                            Sort by :-
                        </div>
                        <select class="form-control border-0" [(ngModel)]="selectedOption"
                            (ngModelChange)="onOptionChange()">
                            <option value="all_days">All days</option>
                            <option value="last_5days">last 5 days</option>
                            <option value="last_10days">last 10 days</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="transaction_item d-flex justify-content-between align-items-center"
                *ngFor="let wallet of transactions.slice(0, itemsToDisplay)">
                <div *ngIf="wallet.transaction_type==='credit'">
                    <div class="d-flex align-content-center">
                        <div class="heading_16 color_151515CC mb-2 text-capitalize">{{wallet?.transaction_message}}
                        </div>
                    </div>
                    <div class="color_15151580">Transactions Id:- {{wallet?.id}}</div>
                    <div class="color_15151580">Date:- {{wallet?.created_at*1000 | date}}</div>
                </div>
                <div *ngIf="wallet.transaction_type==='debit'">
                    <div class="d-flex align-content-center">
                        <div class="heading_16 color_151515CC mb-2 text-capitalize">{{wallet?.transaction_message}}
                        </div>
                    </div>
                    <div class="color_15151580">Transactions Id:- {{wallet?.id}}</div>
                    <div class="color_15151580">Date:- {{wallet?.created_at*1000 | date}}</div>
                </div>
                <div *ngIf="wallet.transaction_type==='debit'"
                    class="heading_20 text-end color_FF5454 text-nowrap ps-1">
                    -{{wallet.amount}}
                </div>
                <div *ngIf="wallet.transaction_type==='credit'"
                    class="heading_20 text-end color_00A651 text-nowrap ps-1">
                    +{{wallet.amount}}
                </div>
            </div>
            <div class="readmore text-center pointer w-100 mt-3" *ngIf="itemsToDisplay < transactions.length"
                (click)="loadMore()">
                Load More
            </div>

        </div>
    </div>
</div>
<ng-template #template>
    <app-add-wallet-modal (getWalletData)="getWalletData(sortDate)"></app-add-wallet-modal>
</ng-template>

<ng-template #withdraw>
    <app-affiliate-withdraw-modal [walletDetails]="wallet"></app-affiliate-withdraw-modal>
</ng-template>