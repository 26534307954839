<div class="booking-profile-item">
    <div class="booking-items-group d-flex align-items-center">
        <div class="items pointer" [ngClass]="{ 'active': activeTab === 'upcoming' }" (click)="upComingTab()">
            Upcoming
        </div>
        <div class="items pointer" [ngClass]="{ 'active': activeTab === 'completed' }" (click)="bookedTab()">
            Completed
        </div>
    </div>
</div>

<div *ngIf="upcomingTab">
    <div class="col-md-12" *ngIf="!upcomingBookingData?.length">
        <div class="wish_list_content">
            <div class="mb-5">
                <img src="https://tripxoxo-prod.s3.ap-south-1.amazonaws.com/tripxoxo_prod/assets/images/wish_list.svg" class="img-fluid" alt="">
            </div>
            <div class="heading_20 mb-3">Your Booking Awaits</div>
            <div class="description_16">
                Add Items to your Booking as you browse and they will magically appear here.
            </div>

            <button class="btn button-green" type="button" [routerLink]="'/'">
                Start Exploring
            </button>
        </div>
    </div>
    <div class="d-flex align-items-center mt-5" *ngIf="isLoading">
        <img ngClass="loader_image" width="100" src="./assets/images/loading-gif.gif" class="img-fluid m-auto" alt="" />
    </div>
    <div class="my_booking_page" *ngFor="let items of upcomingBookingData.slice(0, upcomingItemsToDisplay)">

        <div class="accordion-heading" [ngClass]="{'active-heading': items.isOpen}" (click)="toggleAccordion(items)">
            <div class="d-flex align-items-center justify-content-between">
                <div class="heading_16">
                    {{ items?.booking?.purchased_at | date:'dd-MM-yyyy' }}
                </div>
                <div class="d-flex align-items-center">
                    <div class="description_16 me-md-3">
                        Amount: {{ items?.booking?.total_amount }}
                    </div>
                    <div class="icon_rotate ms-2 ms-md-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="none">
                            <path d="M0.857178 1.14258L8.00004 8.28544L15.1429 1.14258" stroke="#0F0F0F"
                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div accordion-body [hidden]="!items.isOpen">
            <div class="booking-profile mt-4" *ngFor="let item of items?.booking_items">
                <div class="d-md-flex">
                    <div class="background-image"
                        [ngStyle]="{background: 'url('+item?.ticket_image + ')' + 'no-repeat center / cover,#cccccc'}">
                    </div>
                    <div class="d-md-grid width_balance_320">
                        <div class="d-flex align-content-center justify-content-between">
                            <div class="heading_18 mb-3 mb-md-0">{{ item?.product_title }}</div>
                            <div class="single_download pointer" (click)="downloadTicket(item?.id)">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_2555_8597)">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M0.62375 2.99976C0.458403 3.00107 0.300228 3.06746 0.183482 3.18456C0.0667361 3.30166 0.000816618 3.46003 0 3.62538L0 8.00913C0.00125 8.35038 0.281875 8.63101 0.62375 8.63226C0.792162 8.63336 0.958692 8.66777 1.11375 8.73351C1.26375 8.79726 1.3975 8.88788 1.51125 9.00163C1.73813 9.22913 1.87562 9.53476 1.87562 9.88413C1.87741 10.1306 1.80594 10.372 1.6703 10.5777C1.53465 10.7835 1.34094 10.9443 1.11375 11.0398C1.03782 11.0723 0.958911 11.0974 0.878125 11.1148C0.796875 11.131 0.710625 11.1366 0.62375 11.1366C0.458511 11.1379 0.300429 11.2043 0.183701 11.3212C0.0669721 11.4382 0.000980353 11.5964 0 11.7616L0 16.1491C0 16.4916 0.28125 16.7741 0.62375 16.7748H19.4056C19.7494 16.7748 20.0312 16.4923 20.0312 16.1491V11.7623C20.0312 11.4185 19.7487 11.1366 19.4056 11.1366H19.4031C19.3156 11.1366 19.2344 11.1304 19.1531 11.1141C18.9139 11.0632 18.6943 10.9445 18.5206 10.7723C18.4037 10.6563 18.311 10.5184 18.2477 10.3665C18.1844 10.2145 18.1518 10.0516 18.1518 9.88694C18.1518 9.72233 18.1844 9.55935 18.2477 9.4074C18.311 9.25546 18.4037 9.11755 18.5206 9.00163C18.6937 8.82837 18.9139 8.70972 19.1537 8.66038C19.235 8.64413 19.3162 8.63226 19.4031 8.63226H19.4056C19.7481 8.63226 20.0306 8.35101 20.0312 8.00913V3.62538C20.0312 3.28163 19.7487 2.99976 19.4056 2.99976H19.4031H0.62375ZM1.25188 4.25163H18.7794V7.47226C18.6606 7.50288 18.545 7.53163 18.4338 7.57851C17.98 7.7695 17.5927 8.09028 17.3207 8.50059C17.0486 8.91089 16.9037 9.39243 16.9044 9.88476C16.9044 10.5723 17.1819 11.2073 17.6338 11.6598C17.86 11.886 18.1338 12.0679 18.4338 12.1948C18.545 12.2423 18.6606 12.2704 18.7794 12.301V15.5216H1.25188V12.3023C1.37063 12.271 1.4875 12.2429 1.59938 12.196C1.89778 12.0705 2.16919 11.8886 2.39875 11.6604C2.85125 11.2079 3.12875 10.5729 3.12875 9.88538C3.12946 9.39304 2.98467 8.91146 2.71257 8.50114C2.44047 8.09082 2.05319 7.77006 1.59938 7.57913C1.4875 7.53226 1.37063 7.50351 1.25188 7.47288V4.25163Z"
                                            fill="var(--primary)" />
                                        <path
                                            d="M11.7207 9.37188L11.0207 10.0719V7.625C11.0207 7.45924 10.9548 7.30027 10.8376 7.18306C10.7204 7.06585 10.5614 7 10.3957 7C10.2299 7 10.0709 7.06585 9.95372 7.18306C9.83651 7.30027 9.77066 7.45924 9.77066 7.625V10.0719L9.06754 9.37188C8.95026 9.2546 8.7912 9.18871 8.62535 9.18871C8.4595 9.18871 8.30044 9.2546 8.18316 9.37188C8.06588 9.48915 8 9.64821 8 9.81406C8 9.97992 8.06588 10.139 8.18316 10.2562L9.95191 12.0219C10.01 12.08 10.0789 12.1261 10.1548 12.1575C10.2306 12.189 10.312 12.2052 10.3941 12.2052C10.4762 12.2052 10.5576 12.189 10.6334 12.1575C10.7093 12.1261 10.7782 12.08 10.8363 12.0219L12.605 10.2562C12.7223 10.139 12.7882 9.97992 12.7882 9.81406C12.7882 9.64821 12.7223 9.48915 12.605 9.37188C12.4878 9.2546 12.3287 9.18871 12.1628 9.18871C11.997 9.18871 11.8379 9.2546 11.7207 9.37188Z"
                                            fill="var(--primary)" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_2555_8597">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>

                            </div>
                        </div>
                        <div class="description_14 color_4a4a4a mb-3 mb-md-0">
                            Travel Date: <span>{{ item?.activity_date }}</span>
                        </div>
                        <div class="d-md-flex align-items-center justify-content-between mb-3 mb-md-0">
                            <div class="d-flex align-items-center mb-3 mb-md-0">
                                <div class="me-4 me-md-4" *ngFor="let pax of item?.booking_pax">
                                    <div class="heading_14 mb-1">{{ pax?.profile_name }}</div>
                                    <div class="description_14 color_131313BF">{{ pax?.count }}</div>
                                </div>
                            </div>
                            <div>
                                <div class="description_14">
                                    Price
                                </div>
                                <div class="heading_18">{{ item?.amount }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <div class="book_ticket description_16" (click)="downloadAllTicket(items?.booking?.id)">
                    Download Tickets
                </div>
            </div>
        </div>
    </div>
    <div class="readmore text-center pointer w-100" *ngIf="upcomingItemsToDisplay < upcomingBookingData.length"
        (click)="loadMore('upcoming')">Load
        More</div>
</div>

<div *ngIf="completedTab">
    <div class="col-md-12" *ngIf="!completedBookingData?.length">
        <div class="wish_list_content">
            <div class="mb-5">
                <img src="https://tripxoxo-prod.s3.ap-south-1.amazonaws.com/tripxoxo_prod/assets/images/wish_list.svg" class="img-fluid" alt="">
            </div>
            <div class="heading_20 mb-3">Your Booking Awaits</div>
            <div class="description_16">
                Add Items to your Booking as you browse and they will magically appear here.
            </div>

            <button class="btn button-green" type="button" [routerLink]="'/'">
                Start Exploring
            </button>
        </div>
    </div>
    <div class="d-flex align-items-center mt-5" *ngIf="isLoading">
        <img ngClass="loader_image" width="100" src="./assets/images/loading-gif.gif" class="img-fluid m-auto" alt="" />
    </div>
    <div class="my_booking_page" *ngFor="let items of completedBookingData.slice(0, completedItemsToDisplay)">

        <div class="accordion-heading" [ngClass]="{'active-heading': items.isOpen}" (click)="toggleAccordion(items)">
            <div class="d-flex align-items-center justify-content-between">
                <div class="heading_16">
                    {{ items?.booking?.purchased_at | date:'dd-MM-yyyy' }}
                </div>
                <div class="d-flex align-items-center">
                    <div class="description_16 me-md-3">
                        Amount: {{ items?.booking?.total_amount }}
                    </div>
                    <div class="icon_rotate ms-2 ms-md-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="none">
                            <path d="M0.857178 1.14258L8.00004 8.28544L15.1429 1.14258" stroke="#0F0F0F"
                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div accordion-body [hidden]="!items.isOpen">
            <div class="booking-profile mt-4" *ngFor="let item of items?.booking_items">
                <div class="d-md-flex">
                    <div class="background-image"
                        [ngStyle]="{background: 'url('+item?.ticket_image + ')' + 'no-repeat center / cover,#cccccc'}">
                    </div>
                    <div class="d-md-grid width_balance_320">
                        <div class="d-flex align-content-center justify-content-between">
                            <div class="heading_18 mb-3 mb-md-0">{{ item?.product_title }}</div>
                            <div class="single_download pointer" (click)="downloadTicket(item?.id)">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_2555_8597)">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M0.62375 2.99976C0.458403 3.00107 0.300228 3.06746 0.183482 3.18456C0.0667361 3.30166 0.000816618 3.46003 0 3.62538L0 8.00913C0.00125 8.35038 0.281875 8.63101 0.62375 8.63226C0.792162 8.63336 0.958692 8.66777 1.11375 8.73351C1.26375 8.79726 1.3975 8.88788 1.51125 9.00163C1.73813 9.22913 1.87562 9.53476 1.87562 9.88413C1.87741 10.1306 1.80594 10.372 1.6703 10.5777C1.53465 10.7835 1.34094 10.9443 1.11375 11.0398C1.03782 11.0723 0.958911 11.0974 0.878125 11.1148C0.796875 11.131 0.710625 11.1366 0.62375 11.1366C0.458511 11.1379 0.300429 11.2043 0.183701 11.3212C0.0669721 11.4382 0.000980353 11.5964 0 11.7616L0 16.1491C0 16.4916 0.28125 16.7741 0.62375 16.7748H19.4056C19.7494 16.7748 20.0312 16.4923 20.0312 16.1491V11.7623C20.0312 11.4185 19.7487 11.1366 19.4056 11.1366H19.4031C19.3156 11.1366 19.2344 11.1304 19.1531 11.1141C18.9139 11.0632 18.6943 10.9445 18.5206 10.7723C18.4037 10.6563 18.311 10.5184 18.2477 10.3665C18.1844 10.2145 18.1518 10.0516 18.1518 9.88694C18.1518 9.72233 18.1844 9.55935 18.2477 9.4074C18.311 9.25546 18.4037 9.11755 18.5206 9.00163C18.6937 8.82837 18.9139 8.70972 19.1537 8.66038C19.235 8.64413 19.3162 8.63226 19.4031 8.63226H19.4056C19.7481 8.63226 20.0306 8.35101 20.0312 8.00913V3.62538C20.0312 3.28163 19.7487 2.99976 19.4056 2.99976H19.4031H0.62375ZM1.25188 4.25163H18.7794V7.47226C18.6606 7.50288 18.545 7.53163 18.4338 7.57851C17.98 7.7695 17.5927 8.09028 17.3207 8.50059C17.0486 8.91089 16.9037 9.39243 16.9044 9.88476C16.9044 10.5723 17.1819 11.2073 17.6338 11.6598C17.86 11.886 18.1338 12.0679 18.4338 12.1948C18.545 12.2423 18.6606 12.2704 18.7794 12.301V15.5216H1.25188V12.3023C1.37063 12.271 1.4875 12.2429 1.59938 12.196C1.89778 12.0705 2.16919 11.8886 2.39875 11.6604C2.85125 11.2079 3.12875 10.5729 3.12875 9.88538C3.12946 9.39304 2.98467 8.91146 2.71257 8.50114C2.44047 8.09082 2.05319 7.77006 1.59938 7.57913C1.4875 7.53226 1.37063 7.50351 1.25188 7.47288V4.25163Z"
                                            fill="var(--primary)" />
                                        <path
                                            d="M11.7207 9.37188L11.0207 10.0719V7.625C11.0207 7.45924 10.9548 7.30027 10.8376 7.18306C10.7204 7.06585 10.5614 7 10.3957 7C10.2299 7 10.0709 7.06585 9.95372 7.18306C9.83651 7.30027 9.77066 7.45924 9.77066 7.625V10.0719L9.06754 9.37188C8.95026 9.2546 8.7912 9.18871 8.62535 9.18871C8.4595 9.18871 8.30044 9.2546 8.18316 9.37188C8.06588 9.48915 8 9.64821 8 9.81406C8 9.97992 8.06588 10.139 8.18316 10.2562L9.95191 12.0219C10.01 12.08 10.0789 12.1261 10.1548 12.1575C10.2306 12.189 10.312 12.2052 10.3941 12.2052C10.4762 12.2052 10.5576 12.189 10.6334 12.1575C10.7093 12.1261 10.7782 12.08 10.8363 12.0219L12.605 10.2562C12.7223 10.139 12.7882 9.97992 12.7882 9.81406C12.7882 9.64821 12.7223 9.48915 12.605 9.37188C12.4878 9.2546 12.3287 9.18871 12.1628 9.18871C11.997 9.18871 11.8379 9.2546 11.7207 9.37188Z"
                                            fill="var(--primary)" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_2555_8597">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>

                            </div>
                        </div>
                        <div class="description_14 color_4a4a4a mb-3 mb-md-0">
                            Travel Date: <span>{{ item?.activity_date }}</span>
                        </div>
                        <div class="d-md-flex align-items-center justify-content-between mb-3 mb-md-0">
                            <div class="d-flex align-items-center mb-3 mb-md-0">
                                <div class="me-4 me-md-4" *ngFor="let pax of item?.booking_pax">
                                    <div class="heading_14 mb-1">{{ pax?.profile_name }}</div>
                                    <div class="description_14 color_131313BF">{{ pax?.count }}</div>
                                </div>
                            </div>
                            <div>
                                <div class="description_14">
                                    Price
                                </div>
                                <div class="heading_18">{{ item?.amount }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <div class="book_ticket description_16" (click)="downloadAllTicket(items?.booking?.id)">
                    Download Tickets
                </div>
            </div>
        </div>
    </div>
    <div class="readmore text-center pointer w-100" *ngIf="completedItemsToDisplay < completedBookingData.length"
        (click)="loadMore('completed')">Load
        More</div>
</div>