import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TransferService } from 'src/app/service/transfer.service';

@Component({
  selector: 'trpx-flight-selection',
  templateUrl: './flight-selection.component.html',
  styleUrls: ['./flight-selection.component.scss'],
})
export class FlightSelectionComponent implements OnInit {
  isLoading: boolean = false;
  flightFinderModalRef?: BsModalRef;

  flightFinderModalForms: any = FormGroup;
  formData: any;

  @Input() dateSelected: any;
  @Input() flyType: any;
  @Input() airportCode: any;
  @Output('closeModal') closeModal: EventEmitter<any> = new EventEmitter();
  @Output() submitData = new EventEmitter<any>();

  yesterday = new Date();
  airlineNames: any;
  selectedAirline: any;
  selectedAirlineName: any;
  airlineFlightNumber: any;

  filteredOptions!: Observable<any[]>;
  airlineControl = new FormControl();

  constructor(
    private http: HttpClient,
    public router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private transferApi: TransferService,
    private modalService: BsModalService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe
  ) {
    this.yesterday.setDate(this.yesterday.getDate() - 0);
  }

  ngOnInit(): void {
    this.airlineRetrive();
    this.resetForm();
    this.airlineControl.valueChanges.subscribe(() => {
      this.airlineFlightNumber = null;
      this.selectedAirline = this.selectedAirline;
    });
  }
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.airlineNames.filter(
      (option: { airline_name: string; airline_code: string }) =>
        option.airline_name.toLowerCase().includes(filterValue) ||
        option.airline_code.toLowerCase().includes(filterValue)
    );
  }

  selectAirlineClick(item: any) {
    this.selectedAirline = item.airline_code;
    this.selectedAirlineName =
      item.airline_name + ' (' + item.airline_code + ')';
    this.airlineControl.setValue(this.selectedAirlineName);
    this.flightFinderModalForms.controls['airline_code'].setValue(
      item.airline_code
    );
  }

  airlineRetrive() {
    this.transferApi.getAirlineRetrieve().subscribe((result) => {
      this.airlineNames = result?.airlines;
      this.filteredOptions = this.airlineControl.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      );
    });
  }

  openModal(flightFinderModal: TemplateRef<any>) {
    this.flightFinderModalRef = this.modalService.show(flightFinderModal, {
      class: 'modal-dialog-centered',
      animated: true,
    });
  }

  close() {
    this.closeModal.emit('');
  }

  resetForm() {
    this.flightFinderModalForms = this.fb.group({
      date: [this.dateSelected ? this.dateSelected : '', [Validators.required]],
      time: ['', [Validators.required]],
      airline_code: [
        this.selectedAirline ? this.selectedAirline : '',
        [Validators.required],
      ],
      airport_code: [this.airportCode, [Validators.required]],
      flight_direction: [this.flyType, [Validators.required]],
      airport_name: [''],
      flight_number: [''],
    });
  }

  flightFinderModalSubmit(form: any) {
    if (form.valid) {
      (form.value.date = this.datePipe.transform(
        form.value.date,
        'yyyy-MM-dd'
      )),
        (form.value.time = this.formatTimeTo24Hour(form.value.time)),
        this.transferApi
          .postAirlineRetrieveFlight(form.value)
          .subscribe((res) => {
            this.airlineFlightNumber = res?.flights;
          });
    } else {
      this.toastr.warning('Please Fill And Submit!');
    }
  }

  formatTimeTo24Hour(time12Hour: string): string {
    const [time, period] = time12Hour.split(' ');

    let [hours, minutes] = time.split(':');
    hours =
      period === 'PM' ? String(Number(hours) + 12) : hours.padStart(2, '0');

    return `${hours}:${minutes}`;
  }

  selectFlightItem(item: any) {
    this.submitData.emit({ data: item });
    this.close();
  }
}
