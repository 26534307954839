import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription, combineLatest, takeWhile } from 'rxjs';
import { CartWishListService } from 'src/app/service/cart-wishlist-service';
import { HomeService } from 'src/app/service/home.service';
import { PaymentService } from 'src/app/service/payment.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'trpx-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss'],
})
export class WishlistComponent implements OnInit {
  wishListData: any = [];

  selectedCountry!: string;
  selectedCurrency!: string;

  private dataSubscription: Subscription = new Subscription();
  constructor(
    public homeService: HomeService,
    public paymentService: PaymentService,
    public cartWishService: CartWishListService,
    private toastr: ToastrService,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.selectedCountry = localStorage.getItem('Trip_Country') ?? '';
      this.selectedCurrency = localStorage.getItem('Trip_Currency') ?? '';
    }

    this.dataSubscription = combineLatest([
      this.homeService.country$,
      this.homeService.currency$,
    ])
      .pipe()
      .subscribe(([country, currency]) => {
        if (country && currency) {
          this.selectedCountry = country;
          this.selectedCurrency = currency;
          this.countryRelatedAPIs();
        }
      });
  }
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const data = localStorage.getItem('token');
      if (data) {
      } else {
        this.router.navigate([`/`]);
      }
    }
    this.countryRelatedAPIs();
  }

  getWishListDataDetails(country: any, currency: any) {
    this.cartWishService
      .getWishListDetails(country, currency)
      .subscribe((result) => {
        this.wishListData = result;
      });
  }
  removewishListItem(customer: any, product: any) {
    this.cartWishService
      .removeWishList({ user_id: customer, product_id: product })
      .subscribe((result: any) => {
        this.toastr.success('Remove From Wish List.');
        this.countryRelatedAPIs();
      });
  }
  countryRelatedAPIs() {
    this.getWishListDataDetails(this.selectedCountry, this.selectedCurrency);
  }
  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
  }
}
