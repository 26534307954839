import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription, combineLatest, takeWhile } from 'rxjs';
import { BookingService } from 'src/app/service/booking.service';
import { HomeService } from 'src/app/service/home.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'trpx-promo-codes',
  templateUrl: './promo-codes.component.html',
  styleUrls: ['./promo-codes.component.scss'],
})
export class PromoCodesComponent implements OnInit {
  promoCodeData: any;

  selectedCountry!: string | null;
  selectedCurrency!: string | null;

  clubPass: any;

  private dataSubscription: Subscription = new Subscription();
  @ViewChild('contentToCopy', { static: false })
  contentToCopy!: ElementRef<HTMLDivElement>;
  @ViewChild('clubPasscontentToCopy', { static: false })
  clubPasscontentToCopy!: ElementRef<HTMLDivElement>;

  constructor(
    public router: Router,
    private bookingService: BookingService,
    public homeService: HomeService,
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.selectedCountry = localStorage.getItem('Trip_Country') ?? '';
      this.selectedCurrency = localStorage.getItem('Trip_Currency') ?? '';
    }

    this.dataSubscription = combineLatest([
      this.homeService.country$,
      this.homeService.currency$,
    ])
      .pipe()
      .subscribe(([country, currency]) => {
        if (country && currency) {
          this.selectedCountry = country;
          this.selectedCurrency = currency;
          this.countryRelatedAPIs();
        }
      });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const data = localStorage.getItem('token');
      if (data) {
      } else {
        this.router.navigate([`/`]);
      }
    }
    this.countryRelatedAPIs();
    this.getPromoCodes();
    this.getClubPassBalance();
  }

  getPromoCodes() {
    this.bookingService.getPromocode().subscribe((result) => {
      this.promoCodeData = result.results;
    });
  }
  goTolink(item: any) {
    if (item?.applicable_to_all_products == true) {
      this.router.navigate(['/']);
    } else {
      this.router.navigate(['/product/', item?.product_slug]);
    }
  }

  copyContent(code: string) {
    this.copyToClipboard(code);
  }

  clubCopyContent() {
    if (
      this.clubPasscontentToCopy &&
      this.clubPasscontentToCopy.nativeElement
    ) {
      const content = this.clubPasscontentToCopy.nativeElement.innerText;

      if (content) {
        this.copyToClipboard(content);
      }
    }
  }

  copyToClipboard(value: string) {
    if (isPlatformBrowser(this.platformId)) {
      const textField = document.createElement('textarea');
      textField.innerText = value;
      document.body.appendChild(textField);
      textField.select();
      try {
        document.execCommand('copy');
        this.toastr.success('Code Copied! Ready to Use!');
      } catch (err) {
        console.error('Unable to copy', err);
        this.toastr.error('Unable to copy to clipboard');
      }
      textField.remove();
    }
  }

  countryRelatedAPIs() {}

  getClubPassBalance() {
    this.bookingService.checkClubPassBalance().subscribe((result) => {
      if (result?.total_balance) {
        this.clubPass = result;
      }
    });
  }
}
