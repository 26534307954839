import { HttpEventType } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/service/shared.service';
import { NgxMatTimepickerComponent } from 'ngx-mat-timepicker';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent {
  @Input() formData: any[] = [];
  @Input() index: number = 0;
  @Output() submitData = new EventEmitter<any>();
  form!: FormGroup;
  selectedDial: string = '+91';
  inputTypes = [
    'hidden',
    'text',
    'email',
    'number',
    'address',
    'phone',
    'date',
    'datetime',
    'select',
    'list',
    'list multiple',
    'time',
    'boolean',
    'list_with_label',
    'file',
    'suggestion',
    'textarea',
    'number_unit',
    'string',
    'list_label_suggestion',
    'pnr',
  ];
  fileToUpload: File | null = null;
  progress!: number;
  isLoading: boolean = false;
  today = new Date().toISOString().slice(0, -8);
  showPNRHint = false;
  @ViewChild('Dpicker') Dpicker: NgxMatTimepickerComponent | undefined;
  myreg = /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/gi;

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private fb: FormBuilder,
    private api: SharedService,
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit() {
    const formGroupConfig: any = {};
    this.formData = this.formData.filter((field) =>
      this.inputTypes?.includes(field.type)
    );

    this.formData.forEach((field) => {
      if (field?.parent?.length) field.optional = false;
      if (this.inputTypes?.includes(field.type)) {
        let validators = field.required ? [Validators.required] : [];
        if (field.type === 'text') {
          validators.push(Validators.maxLength(100));
          formGroupConfig[field.key] = [field.value || '', validators];
        } else if (field.type === 'email') {
          validators.push(Validators.email);
          formGroupConfig[field.key] = ['', validators];
        } else if (field.type === 'phone') {
          formGroupConfig[field.key] = this.fb.group({
            countryCode: ['+91', Validators.required],
            phoneNumber: ['', Validators.required],
          });
        } else if (field.type === 'number_unit') {
          formGroupConfig[field.key] = this.fb.group({
            numeric: [null, Validators.required],
            unit: ['', Validators.required],
          });
        } else if (field.type === 'file') {
          validators.push(Validators.pattern(this.myreg));
          formGroupConfig[field.key] = ['', validators];
        } else if (field.type === 'pnr') {
          validators.push(Validators.pattern(/^[a-zA-Z0-9]{6}$/));
          formGroupConfig[field.key] = ['', validators];
        } else {
          let control = field.type === 'boolean' ? false : field.value || '';
          let controlConfig = { value: control, disabled: field.disable };
          formGroupConfig[field.key] = [controlConfig, validators];
        }
      }
    });

    this.form = this.fb.group(formGroupConfig);
    this.submitFormData(this.form);

    this.form.valueChanges.subscribe(() => {
      this.submitFormData(this.form);
    });
  }
  checkChildren() {
    this.formData.forEach((field: any) => {
      if (field.parent != undefined && field?.parent?.length) {
        if (field.condition != undefined && field.condition) {
          let terminateLoop = false;
          field.parent.forEach((parent: any) => {
            if (terminateLoop) return; // exit the loop if terminateLoop is true
            field.optional = field?.condition?.includes(this.form.value[parent]); //true or false
            if (field.optional) terminateLoop = true; // set terminateLoop to true if field.optional is true
          });
        }
      }
      this.setValidation(field);
    });
  }

  setValidation(field: any) {
    const myFieldControl = this.form.get(field.key);
    if (myFieldControl && field?.parent?.length) {
      myFieldControl.clearValidators();
      if (field.optional && field.required) {
        myFieldControl.setValidators(Validators.required);
      }
      myFieldControl.updateValueAndValidity({ emitEvent: false });
    }
    this.recheckForGrandChild();
    this.submitFormData(this.form);
  }

  submitFormData(data: any) {
    this.formData.forEach((field: any) => {
      if (field.type === 'date') {
        data.value[field.key] = moment(data.value[field.key]).format(
          'YYYY-MM-DD'
        );
      } else if (field.type == 'list_label_suggestion') {
        const matchingList = field?.list?.find(
          (list: any) => list.label === data.value[field.key]
        );
        if (matchingList) data.value[field.key] = matchingList.value;
      }
    });
    this.submitData.emit({
      form: data,
      ignore_keys: this.removeOptionalAnswers(this.formData),
    });
  }

  checkGChild: any;
  recheckForGrandChild() {
    clearTimeout(this.checkGChild);
    this.checkGChild = setTimeout(() => {
      const nonOptionalParents = this.formData.filter(
        (field) => !field.optional && field?.parent?.length
      );
      const parentKeys = nonOptionalParents.map((field) => field.key);

      this.formData.forEach((field) => {
        if (
          field?.parent?.some((parentKey: any) =>
            parentKeys?.includes(parentKey)
          )
        ) {
          field.optional = false;
          const myFieldControl = this.form.get(field.key);
          if (myFieldControl) myFieldControl.clearValidators();
        }
      });
    }, 100);
  }

  triggerClick(id: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.progress = 0;
      let element: HTMLElement = document.getElementById(id) as HTMLElement;
      element.click();
    }
  }

  getFile(event: any, key: any) {
    if (isPlatformBrowser(this.platformId)) {
      const files = event.target.files;
      const formData: FormData = new FormData();
      formData.append('document', files.item(0));

      this.api.uploadFile(formData).subscribe(
        (event: any) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              this.progress = Math.round((100 * event.loaded) / event.total);
              break;
            case HttpEventType.Response:
              this.progress = 0;
              this.form.controls[key].setValue(event.body.document);
              break;
          }
        },
        () => {
          this.progress = 0;
          this.toastr.error('Try Again!');
        }
      );
    }
  }

  filterComplete(value: string, list: any[]): void {
    list.sort((a, b) => {
      const aLower = a.toLowerCase();
      const bLower = b.toLowerCase();
      const valueLower = value.toLowerCase();
      const aIndex = aLower.indexOf(valueLower);
      const bIndex = bLower.indexOf(valueLower);

      if (aIndex !== -1 && bIndex === -1) {
        return -1;
      } else if (aIndex === -1 && bIndex !== -1) {
        return 1;
      } else {
        return a.localeCompare(b);
      }
    });
  }

  filterLabelComplete(value: string, list: any[]): void {
    list.sort((a, b) => {
      const aLower = a.label.toLowerCase();
      const bLower = b.label.toLowerCase();
      const valueLower = value.toLowerCase();
      const aIndex = aLower.indexOf(valueLower);
      const bIndex = bLower.indexOf(valueLower);

      if (aIndex !== -1 && bIndex === -1) {
        return -1;
      } else if (aIndex === -1 && bIndex !== -1) {
        return 1;
      } else {
        return a.label.localeCompare(b.label);
      }
    });
  }

  onDateChange(event: any, field: any) {
    this.form.controls[field.key].setValue(
      moment(event.value).format('YYYY-MM-DD HH:mm A')
    );
  }

  timeChanged(event: any, field: any) {
    const dateStr = this.form.controls[field.key].value;
    this.form.controls[field.key].setValue(
      dateStr.replace(/\d{2}:\d{2} [AP]M$/, event)
    );
  }

  receiveCountryCode(event: any, field: any) {
    const countryCode = event;
    const formGroup = this.form.get(field) as FormGroup;
    if (formGroup) {
      const countryCodeControl = formGroup.get('countryCode');
      if (countryCodeControl) {
        countryCodeControl.setValue(countryCode);
      }
    }
  }

  markAllAsTouched() {
    Object.values(this.form.controls).forEach((control) => {
      control.markAsTouched();
    });
  }

  removeOptionalAnswers(formData: any[]) {
    return formData
      .filter((item: any) => item?.parent?.length && !item.optional)
      .map((item: any) => item.key);
  }

  upperCaseFn(event: Event, field: any) {
    if (isPlatformBrowser(this.platformId)) {
      const input = event.target as HTMLInputElement;
      let value = input.value.toUpperCase();

      value = value.replace(/[^a-zA-Z0-9]/g, '');

      if (value.length > 6) {
        value = value.substring(0, 6);
      }

      this.form.controls[field.key].setValue(value);
    }
  }
}
