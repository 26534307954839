<div class="modal-header">
    <h4 class="modal-title w-100 heading_30 text-center">Withdraw Amount</h4>
</div>
<div class="trpx_modal_wallet modal-body">
    <form [formGroup]="withdrawForm" (ngSubmit)="withdrawRequest(withdrawForm)">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field class="mat_field_width mb-2" appearance="outline">
                    <mat-label> Account No. </mat-label>
                    <input numbersOnly matInput name="account_number" type="number" placeholder="Account Number"
                        required formControlName="account_number">
                    <mat-error class="mb-2" *ngIf="withdrawForm.controls['account_number']?.errors?.required">Account
                        number
                        required</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-12">
                <mat-form-field class="mat_field_width mb-2" appearance="outline">
                    <mat-label>Re-Type Account No. </mat-label>
                    <input numbersOnly matInput name="confirm_account_number" type="number"
                        placeholder="Re-Type Account Number" required formControlName="confirm_account_number">
                    <mat-error class="mb-2"
                        *ngIf="withdrawForm.controls['confirm_account_number']?.errors?.accMismatch">Account
                        number
                        mismatch</mat-error>
                    <mat-error class="mb-2"
                        *ngIf="withdrawForm.controls['confirm_account_number']?.errors?.required">Account
                        number
                        required</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="mat_field_width mb-2" appearance="outline">
                    <mat-label> IFSC Code </mat-label>
                    <input matInput name="ifsc_code" type="text" placeholder="Enter IFSC" required
                        formControlName="ifsc_code">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="mat_field_width mb-2" appearance="outline">
                    <mat-label> Enter Amount </mat-label>
                    <input numbersOnly matInput name="amount" type="number" placeholder="Enter Amount" required
                        formControlName="amount">
                </mat-form-field>
            </div>
        </div>
        <div class="text-center mb-2">
            <small>Total Amount : {{walletDetails?.wallet?.currency_symbol}}
                <span class="text-success fw-bold">{{walletDetails?.wallet?.amount ?? 0}}</span></small>
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <button class="btn btn-transparent rounded-pill" type="button" (click)="close()">
                Cancel
            </button>
            <button class="btn button-green rounded-pill" type="submit">
                Withdraw
            </button>
        </div>
    </form>
</div>