<footer>
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-3">
                <div class="trpx-footer-logo"><img src="./assets/icons/foot_logo.svg" alt=""></div>
                <div class="description">Thank you for visiting our website! Your privacy is important to us, and we are
                    committed to
                    protecting and respecting your privacy. </div>
                <div class="trpx-footer-social-icons d-none d-md-flex">
                    <a href="https://www.facebook.com/tripxoxo" target="_blank">
                        <div class="trpx-footer-social-ico">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
                                fill="none">
                                <path
                                    d="M21.0003 2.33337H17.5003C15.9532 2.33337 14.4695 2.94796 13.3755 4.04192C12.2816 5.13588 11.667 6.61961 11.667 8.16671V11.6667H8.16699V16.3334H11.667V25.6667H16.3337V16.3334H19.8337L21.0003 11.6667H16.3337V8.16671C16.3337 7.85729 16.4566 7.56054 16.6754 7.34175C16.8942 7.12296 17.1909 7.00004 17.5003 7.00004H21.0003V2.33337Z"
                                    fill="var(--primary)" />
                            </svg>
                        </div>
                    </a>
                    <a href="https://twitter.com/tripxoxoindia" target="_blank">
                        <div class="trpx-footer-social-ico">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
                                fill="none">
                                <path
                                    d="M25.6663 4.66661C25.6663 4.66661 24.8497 7.11661 23.333 8.63328C25.1997 20.2999 12.3663 28.8166 2.33301 22.1666C4.89967 22.2833 7.46634 21.4666 9.33301 19.8333C3.49967 18.0833 0.583008 11.1999 3.49967 5.83328C6.06634 8.86661 10.033 10.6166 13.9997 10.4999C12.9497 5.59995 18.6663 2.79995 22.1663 6.06661C23.4497 6.06661 25.6663 4.66661 25.6663 4.66661Z"
                                    fill="var(--primary)" />
                            </svg>
                        </div>
                    </a>
                    <a href="https://www.instagram.com/tripxoxo/" target="_blank">
                        <div class="trpx-footer-social-ico">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
                                fill="none">
                                <path
                                    d="M19.833 2.33337H8.16634C4.94468 2.33337 2.33301 4.94505 2.33301 8.16671V19.8334C2.33301 23.055 4.94468 25.6667 8.16634 25.6667H19.833C23.0547 25.6667 25.6663 23.055 25.6663 19.8334V8.16671C25.6663 4.94505 23.0547 2.33337 19.833 2.33337Z"
                                    fill="var(--primary)" />
                                <path
                                    d="M18.6667 13.265C18.8106 14.2359 18.6448 15.2276 18.1927 16.0988C17.7406 16.9701 17.0253 17.6766 16.1485 18.1179C15.2718 18.5592 14.2782 18.7128 13.3091 18.5569C12.34 18.401 11.4447 17.9434 10.7506 17.2493C10.0566 16.5553 9.599 15.66 9.44306 14.6909C9.28712 13.7218 9.44073 12.7282 9.88203 11.8514C10.3233 10.9746 11.0299 10.2593 11.9011 9.80727C12.7724 9.35518 13.764 9.18934 14.735 9.33332C15.7254 9.48018 16.6423 9.94169 17.3503 10.6497C18.0583 11.3577 18.5198 12.2746 18.6667 13.265Z"
                                    stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M20.417 7.58337H20.4287" stroke="white" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-12 col-md-9">
                <div class="row">
                    <div class="col-4 col-md-3 offset-md-1">
                        <h4 class="trpx-footer-h4 heading_20">About tripXOXO</h4>
                        <ul class="trpx-footer-ul-list-1">
                            <li><a href="/about-us" routerLink="/about-us">About us</a></li>
                            <li *ngIf="!isFooterLink"><a href="/blog" routerLink="/blog">Blog</a></li>
                            <li><a href="/contact-us" routerLink="/contact-us">Contact Us</a></li>
                            <!-- <li><a href="">FAQ</a></li> -->
                            <!-- <li class="pointer" (click)="openModal(signup)"><a>Register</a></li> -->


                            <!-- <li><a href="/news" routerLink="/news">News</a></li> -->
                        </ul>
                    </div>
                    <div class="col-8 col-md-4 padding_left_70" *ngIf="!isFooterLink">
                        <h4 class="trpx-footer-h4 heading_20">Partnerships</h4>
                        <ul class="trpx-footer-ul-list-2">

                            <li><a href="/partners" routerLink="/partners">Partners</a></li>
                            <li><a href="/distributor" routerLink="/distributor">Distributor</a></li>
                            <li (click)="isAffiliate=true;isAgent=false;openModal(signin)"
                                class="pointer txpro_transform">tXPro - Affiliate Partnership</li>
                            <li (click)="isAffiliate=false;isAgent=true;openModal(signin);logout();"
                                class="pointer txpro_transform">Agent Login</li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-4">
                        <h4 class="trpx-footer-h4 heading_20">Terms Of Use</h4>
                        <ul class="trpx-footer-ul-list-2">
                            <li><a href="/privacy-policy" routerLink="/privacy-policy">Privacy Policy</a></li>
                            <li><a href="/terms-and-condition" routerLink="/terms-and-condition">Terms and condition</a>
                            </li>
                            <li><a href="/disclaimer" routerLink="/disclaimer">Disclaimer</a></li>
                            <li><a href="/cancellation-and-refund-policy"
                                    routerLink="/cancellation-and-refund-policy">Cancellation & Refund Policy</a></li>
                            <!-- <li>{{conatctData?.address}}</li>
                            <li><a href="tel: {{conatctData?.phoneNo}}">{{conatctData?.phoneNo}}</a></li>
                            <li class="text-lowercase"><a
                                    href="mailto:{{conatctData?.email}}">{{conatctData?.email}}</a></li> -->
                        </ul>
                    </div>
                    <div class="col-12 d-md-none d-block">
                        <div class="trpx-footer-social-icons">
                            <a href="https://www.facebook.com/tripxoxo" target="_blank">
                                <div class="trpx-footer-social-ico">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
                                        fill="none">
                                        <path
                                            d="M21.0003 2.33337H17.5003C15.9532 2.33337 14.4695 2.94796 13.3755 4.04192C12.2816 5.13588 11.667 6.61961 11.667 8.16671V11.6667H8.16699V16.3334H11.667V25.6667H16.3337V16.3334H19.8337L21.0003 11.6667H16.3337V8.16671C16.3337 7.85729 16.4566 7.56054 16.6754 7.34175C16.8942 7.12296 17.1909 7.00004 17.5003 7.00004H21.0003V2.33337Z"
                                            fill="var(--primary)" />
                                    </svg>
                                </div>
                            </a>
                            <a href="https://twitter.com/tripxoxoindia" target="_blank">
                                <div class="trpx-footer-social-ico">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
                                        fill="none">
                                        <path
                                            d="M25.6663 4.66661C25.6663 4.66661 24.8497 7.11661 23.333 8.63328C25.1997 20.2999 12.3663 28.8166 2.33301 22.1666C4.89967 22.2833 7.46634 21.4666 9.33301 19.8333C3.49967 18.0833 0.583008 11.1999 3.49967 5.83328C6.06634 8.86661 10.033 10.6166 13.9997 10.4999C12.9497 5.59995 18.6663 2.79995 22.1663 6.06661C23.4497 6.06661 25.6663 4.66661 25.6663 4.66661Z"
                                            fill="var(--primary)" />
                                    </svg>
                                </div>
                            </a>
                            <a href="https://www.instagram.com/tripxoxo/" target="_blank">
                                <div class="trpx-footer-social-ico">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
                                        fill="none">
                                        <path
                                            d="M19.833 2.33337H8.16634C4.94468 2.33337 2.33301 4.94505 2.33301 8.16671V19.8334C2.33301 23.055 4.94468 25.6667 8.16634 25.6667H19.833C23.0547 25.6667 25.6663 23.055 25.6663 19.8334V8.16671C25.6663 4.94505 23.0547 2.33337 19.833 2.33337Z"
                                            fill="var(--primary)" />
                                        <path
                                            d="M18.6667 13.265C18.8106 14.2359 18.6448 15.2276 18.1927 16.0988C17.7406 16.9701 17.0253 17.6766 16.1485 18.1179C15.2718 18.5592 14.2782 18.7128 13.3091 18.5569C12.34 18.401 11.4447 17.9434 10.7506 17.2493C10.0566 16.5553 9.599 15.66 9.44306 14.6909C9.28712 13.7218 9.44073 12.7282 9.88203 11.8514C10.3233 10.9746 11.0299 10.2593 11.9011 9.80727C12.7724 9.35518 13.764 9.18934 14.735 9.33332C15.7254 9.48018 16.6423 9.94169 17.3503 10.6497C18.0583 11.3577 18.5198 12.2746 18.6667 13.265Z"
                                            stroke="white" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path d="M20.417 7.58337H20.4287" stroke="white" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <hr class="w-100">
            </div>
            <div class="col-md-6">
                <div class="trpx-footer-copy-right text-start">©Copyright 2024 by TravelPort. All
                    Rights Reserved.</div>
            </div>
            <div class="col-md-6 text-start text-md-end">
                <div class="d-md-flex justify-content-md-end gr-footer-copy-right">
                    <img src="https://tripxoxo-prod.s3.ap-south-1.amazonaws.com/tripxoxo_prod/assets/images/payments.svg" alt="">
                </div>
            </div>
        </div>
    </div>
</footer>

<ng-template #signin>
    <trpx-sign-in [isAffiliate]="isAffiliate" [isAgent]="isAgent" (closeModal)="closeModal()"></trpx-sign-in>
</ng-template>