import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, OnInit, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnInit {
  headers: any;

  private customerSubject = new BehaviorSubject<any>(null);
  customers$ = this.customerSubject.asObservable();

  private loginPopupSubject = new BehaviorSubject<any>(null);
  isLoginShown$ = this.loginPopupSubject.asObservable();

  private mobileSubject = new BehaviorSubject<any>(false);
  mobile$ = this.mobileSubject.asObservable();

  constructor(
    private http: HttpClient,
    public toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    // 'Authorization': 'Token f26b64798d5c9581d026c2c2d296861274784f41'
  }

  updateMobile(data: any) {
    this.mobileSubject.next(data);
  }

  updateCustomer(data: any) {
    this.customerSubject.next(data);
  }

  updateLoginPopShown(status: boolean) {
    this.loginPopupSubject.next(status);
  }

  getHeader() {
    if (isPlatformBrowser(this.platformId)) {
      let token = localStorage.getItem('token');
      if (token != null && token != undefined && token != '') {
        token = JSON.parse(token);

        this.headers = new HttpHeaders({
          'Content-Type': 'application/json',

          // Authorization: `Token ${token}`
          Authorization: `Token ${token}`,
        });
      }
    }
  }

  // isLoggedIn(): boolean {
  //   let token = localStorage.getItem('token');

  //   if (token != null && token != undefined && token != '') {

  //     return true;
  //   } else {

  //     return false;
  //   }
  // }

  isLoggedIn(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const token = localStorage.getItem('token');
      return !!token;
    }
    return false;
  }

  // isRefreshToken(): boolean {
  //   let refreshtime = localStorage.getItem('refreshtime');
  //   let refreshtoken = localStorage.getItem('refreshtoken');
  //   if (refreshtime && refreshtoken) return true;
  //   return false;
  // }

  isRefreshToken(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const refreshtime = localStorage.getItem('refreshtime');
      const refreshtoken = localStorage.getItem('refreshtoken');
      return !!refreshtime && !!refreshtoken;
    }
    return false;
  }

  createUser(data: any): Observable<any> {
    return this.http.post(
      `${environment.bookingApiUrl}/customer-registration`,
      data
    );
  }

  createAgent(data: any): Observable<any> {
    return this.http.post(
      `${environment.bookingApiUrl}/travel-agent-registration`,
      data
    );
  }

  loginOtpGeneration(data: any): Observable<any> {
    return this.http.post(`${environment.bookingApiUrl}/send-otp`, data);
  }

  loginOtpForVerify(data: any): Observable<any> {
    return this.http.post(`${environment.bookingApiUrl}/verify-otp`, data);
  }

  updateCustomerProfile(id: any, data: any): Observable<any> {
    return this.http.patch(`${environment.bookingApiUrl}/customer/${id}`, data);
  }

  updateAgentCustomerProfile(id: any, data: any): Observable<any> {
    return this.http.patch(
      `${environment.bookingApiUrl}/travel-agent/${id}`,
      data
    );
  }

  getTokenFromRefresh(data: any): Observable<any> {
    return this.http.post(`${environment.bookingApiUrl}/token-refresh`, data);
  }

  checkIfTokenValid(token: string | null) {
    return this.http.get(
      `${environment.bookingApiUrl}/token-validation?token=${token}`
    );
  }

  getCustomerProfile(): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/customer`);
  }

  getAgentCustomerProfile(): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/travel-agent`);
  }

  deleteCustomerProfile(data?: any): Observable<any> {
    return this.http.post(`${environment.bookingApiUrl}/customer`, data);
  }

  // getToken() {
  //   const token = localStorage.getItem('token');
  //   return token && token != 'undefined' ? JSON.parse(token) : null;
  // }

  getToken() {
    if (isPlatformBrowser(this.platformId)) {
      const token = localStorage.getItem('token');
      return token && token !== 'undefined' ? JSON.parse(token) : null;
    }
    return null;
  }

  // getCustomer() {
  //   const customer = localStorage.getItem('customer_profile') ?? null;
  //   if (
  //     customer &&
  //     JSON.parse(customer) &&
  //     Object.keys(JSON.parse(customer)).length === 0
  //   ) {
  //     return null;
  //   }
  //   return customer ? JSON.parse(customer) : null;
  // }

  getCustomer() {
    if (isPlatformBrowser(this.platformId)) {
      const customer = localStorage.getItem('customer_profile');
      if (customer) {
        const parsedCustomer = JSON.parse(customer);
        return Object.keys(parsedCustomer).length === 0 ? null : parsedCustomer;
      }
    }
    return null;
  }

  setToken(token: any) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('token', JSON.stringify(token));
    }
  }

  setLogo(logo: string) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('Logo', logo);
    }
  }

  // getLogo() {
  //   return localStorage.getItem('Logo') || null;
  // }

  getLogo() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('Logo') || null;
    }
    return null;
  }

  setRefreshToken(token: any) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('refreshtime', JSON.stringify(new Date().getTime()));
      localStorage.setItem('refreshtoken', token);
    }
  }

  setCustomer(customer_profile: any) {
    if (isPlatformBrowser(this.platformId)) {
      if (customer_profile)
        localStorage.setItem(
          'customer_profile',
          JSON.stringify(customer_profile)
        );
    }
  }

  setIfCustomerExists(exists: any) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('is_customer_exists', JSON.stringify(exists));
    }
  }

  setCustomerMobile(customer_mobile: any) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('customer_mobile', JSON.stringify(customer_mobile));
    }
  }

  // getItemFromLS(item: any) {
  //   const itemValue = localStorage.getItem(item);
  //   return itemValue && itemValue != 'undefined' ? JSON.parse(itemValue) : null;
  // }
  getItemFromLS(item: string) {
    if (isPlatformBrowser(this.platformId)) {
      const itemValue = localStorage.getItem(item);
      return itemValue && itemValue !== 'undefined'
        ? JSON.parse(itemValue)
        : null;
    }
    return null;
  }

  // checkIfCustomerExists() {
  //   const exists = localStorage.getItem('is_customer_exists');
  //   return exists != null && exists != 'undefined' ? JSON.parse(exists) : null;
  // }

  checkIfCustomerExists() {
    if (isPlatformBrowser(this.platformId)) {
      const exists = localStorage.getItem('is_customer_exists');
      return exists && exists !== 'undefined' ? JSON.parse(exists) : null;
    }
    return null;
  }

  // checkIfCMCustomerExists() {
  //   const exists = localStorage.getItem('customer_profile');
  //   if (!exists || exists === 'undefined') {
  //     return false;
  //   }

  //   const customerData = JSON.parse(exists);
  //   if (customerData?.source === 'CLUB-MAHINDRA') {
  //     return true;
  //   }

  //   return false;
  // }

  checkIfCMCustomerExists() {
    if (isPlatformBrowser(this.platformId)) {
      const exists = localStorage.getItem('customer_profile');
      if (!exists || exists === 'undefined') {
        return false;
      }

      try {
        const customerData = JSON.parse(exists);
        return customerData?.source === 'CLUB-MAHINDRA';
      } catch (e) {
        return false;
      }
    }
    return false;
  }

  public logout() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('token');
      localStorage.removeItem('customer_profile');
      localStorage.removeItem('Trip_Travel_Agent');
      // localStorage.removeItem('white_label_source');
    }
  }

  getCountries(name: string): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/country?name=${name}`);
  }

  clubmahindraValidation(requestBody: any): Observable<any> {
    return this.http.post(
      `${environment.bookingApiUrl}/cm-validation`,
      requestBody
    );
  }

  whiteLabelValidation(): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/white-labels`);
  }

  check404URL(url: string): Observable<any> {
    return this.http.get(
      `${environment.bookingApiUrl}/url-redirect?sourceurl=${url}`
    );
  }

  setSource(source: any) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('white_label_source', source);
    }
  }
}
