import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-addon-form',
  templateUrl: './addon-form.component.html',
  styleUrls: ['./addon-form.component.scss'],
})
export class AddonFormComponent {
  @Input() optional_extras: any;
  @Input() totalPax!: number;
  totalPaxCount: [number] = [0];
  @Output() submitData = new EventEmitter<any>();
  selectedRindex!: number;
  totalAccPax: [number] = [0];

  ngOnInit() {}

  checkRoomPax() {
    this.submitData.emit({ data: this.optional_extras });
  }

  ngAfterViewInit() {
    if (this.optional_extras && Array.isArray(this.optional_extras)) {
      this.optional_extras.forEach((accomodate: any, i: number) => {
        accomodate.list.forEach((pax: any) => {
          pax.isValid = false;
        });
        this.totalAccPax[i] = accomodate.total_pax_number || 0;
      });
      this.submitData.emit({
        data: this.optional_extras,
        isValid: false,
        isInit: true,
      });
    }
  }
}
