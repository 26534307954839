import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'trpx-experiance-content-card',
  templateUrl: './experiance-content-card.component.html',
  styleUrls: ['./experiance-content-card.component.scss'],
})
export class ExperianceContentCardComponent implements OnInit {
  @Input() activityCards: any;
  constructor() {}
  ngOnInit(): void {}

  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    dots: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  addSlide() {}

  removeSlide() {
    // this.activityCards.length = this.activityCards.length - 1;
  }

  slickInit(e: any) {
    // console.log('slick initialized');
  }

  breakpoint(e: any) {
    // console.log('breakpoint');
  }

  afterChange(e: any) {
    // console.log('afterChange');
  }

  beforeChange(e: any) {
    // console.log('beforeChange');
  }
}
