<div class="trpx-activity-cards">
    <div class="row">
        <div class="col-md-12">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                <div ngxSlickItem *ngFor="let card of activityCards" class="slide">
                    <div class="trpx-activity-card">
                        <div class="trpx-activity-image"
                            [ngStyle]="{background: 'url('+card.imageUrl + ')' + 'no-repeat center / cover,#cccccc'}">
                            <div class="starred-div heading_16" *ngIf="card.starred">{{card.starred}}</div>
                        </div>
                        <div class="trpx-activity-content">
                            <div class="heading_20">{{card.heading}}</div>
                            <div class="trpx-activity-star-rating">
                                <span>
                                    <img src="./assets/icons/Star.svg" class="img-star" alt=""></span>
                                <span class="heading_20">{{card.rating}}</span>
                                <span>{{card.totalReview}}</span>
                            </div>
                            <div class="trpx-activity-labels">
                                <span *ngFor="let subCards of card.label">
                                    {{subCards.labelName}}
                                </span>
                            </div>
                            <div class="mb-1">From</div>
                            <div class="prices heading_20">{{card.price}} <span
                                    class="description_16">{{card.actualPrice}}</span></div>
                        </div>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</div>