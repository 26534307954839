import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'trpx-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Input() componentName: string = '';
  @Input() componentData: any;
  @Input() breadCrumbData: any;
  timeoutSec: any;
  videoSource =
    'https://tripxoxo-prod.s3.ap-south-1.amazonaws.com/tripxoxo_prod/general/video/Experiance3.webm';
  videoMobileSource =
    'https://tripxoxo-prod.s3.ap-south-1.amazonaws.com/tripxoxo_prod/general/video/Experience-mobile.webm';
  @ViewChild('videoPlayer') videoPlayer!: ElementRef<HTMLVideoElement>;
  @ViewChild('videoPlayerMobile')
  videoPlayerMobile!: ElementRef<HTMLVideoElement>;

  interactionDetected: boolean = false;

  modalRef?: BsModalRef;

  selectedData: any = {
    ticket_id: null,
  };

  constructor(
    private modalService: BsModalService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.startVideo();
    this.startVideoMobile();
  }

  openModal(template: any) {
    const modalConfig = {
      ignoreBackdropClick: true,
    };
    this.modalRef = this.modalService.show(template, modalConfig);
  }

  closeModal() {
    this.modalRef?.hide();
  }

  async startVideo(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      const video = this.videoPlayer?.nativeElement as HTMLVideoElement;
      if (video) video.muted = true;
      document.body.click();
      if (await this.autoplayAllowed()) {
        video?.play().catch((error) => {
          // console.error('Error starting video playback:', error);
        });
      } else {
        // console.log('Autoplay is not allowed. Waiting for user interaction or 2-second timeout.');
        // Implement fallback mechanism here
        const timeoutId = setTimeout(() => {
          if (!this.interactionDetected) {
            // Start video playback automatically after 2 seconds
            video?.play().catch((error) => {
              // console.error('Error starting video playback:', error);
            });
            // this.playButton.nativeElement.style.display = 'none'; // Hide the play button after interaction
          }
        }, 2000);
      }
    }
  }

  async startVideoMobile(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      // Mobile
      const videoMobile = this.videoPlayerMobile
        ?.nativeElement as HTMLVideoElement;
      if (videoMobile) videoMobile.muted = true;
      document.body.click();
      if (await this.autoplayAllowedMobile()) {
        videoMobile?.play().catch((error) => {
          // console.error('Error starting video playback:', error);
        });
      } else {
        // console.log('Autoplay is not allowed. Waiting for user interaction or 2-second timeout.');
        // Implement fallback mechanism here
        const timeoutId = setTimeout(() => {
          if (!this.interactionDetected) {
            // Start video playback automatically after 2 seconds
            videoMobile?.play().catch((error) => {
              // console.error('Error starting video playback:', error);
            });
            // this.playButton.nativeElement.style.display = 'none'; // Hide the play button after interaction
          }
        }, 2000);
      }
    }
  }

  autoplayAllowed(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (isPlatformBrowser(this.platformId)) {
        const video = document.createElement('video');
        if (video) video.muted = true;
        video.setAttribute('autoplay-policy', 'no-user-gesture-required');
        video.playsInline = true;
        video.src = 'data:video/mp4;base64,';
        const playPromise = video?.play();
        document.body.click();
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              // Autoplay is allowed
              video.pause();
              resolve(true);
            })
            .catch(() => {
              // Autoplay is not allowed
              resolve(false);
            });
        } else {
          // play() method returned undefined, autoplay is likely allowed
          resolve(true);
        }
      }
    });
  }

  autoplayAllowedMobile(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (isPlatformBrowser(this.platformId)) {
        const videoMobile = document.createElement('video');
        if (videoMobile) videoMobile.muted = true;
        videoMobile.setAttribute('autoplay-policy', 'no-user-gesture-required');
        videoMobile.playsInline = true;
        videoMobile.src = 'data:video/mp4;base64,';
        const playPromise = videoMobile?.play();
        document.body.click();
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              // Autoplay is allowed
              videoMobile.pause();
              resolve(true);
            })
            .catch(() => {
              // Autoplay is not allowed
              resolve(false);
            });
        } else {
          // play() method returned undefined, autoplay is likely allowed
          resolve(true);
        }
      }
    });
  }
}
