import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root',
})


export class ContactUsService {

    constructor(private http: HttpClient) { }

    setContactForm(data: any): Observable<any> {
        return this.http.post(`${environment.bookingApiUrl}/contact_description`, data);
    }

    getCountryDetails(): Observable<any> {
        return this.http.get(`${environment.bookingApiUrl}/country-for-dropdown`);
    }

    contactUs = gql`{
        contactUs{
            id
            email
            phoneNo
            address
        }
    }`

}