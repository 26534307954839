import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'trpx-disney-carousel',
  templateUrl: './disney-carousel.component.html',
  styleUrls: ['./disney-carousel.component.scss'],
})
export class DisneyCarouselComponent {
  @Input() slides: any;
  @Input() componentName: any;
  modalRef?: BsModalRef;

  selectedData: any = {
    ticket_id: null,
  };

  constructor(private modalService: BsModalService, public router: Router) {}
  ngOnInit(): void {}

  openModal(template: any) {
    const modalConfig = {
      ignoreBackdropClick: true,
    };
    this.modalRef = this.modalService.show(template, modalConfig);
  }

  closeModal() {
    this.modalRef?.hide();
  }
}
