<div class="header primary_background">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-between" (scroll)="onWindowScroll();">
                    <div class="d-flex justify-content-between align-items-center w-100 w-md-auto">
                        <div class="d-flex align-items-center logo_section pointer">
                            <div *ngIf="!['LandingComponent'].includes(componentName)" class="menu_icon white_color"
                                (click)="toggleMenuWidth()">
                                <img src="./assets/icons/menu.svg" alt="">
                            </div>
                            <a [routerLink]="subScribeLogo?.homeUrl">
                                <img [src]="subScribeLogo?.logoSrc" class="logo_img_width" alt="">
                            </a>
                            <div class="ms-4 d-none d-md-block"
                                *ngIf="activeTab == 'experiences' || activeTab == 'country' || activeTab == 'category' || activeTab == 'destination'">
                                <div class="box">
                                    <div class="container-2">
                                        <span class="icon">
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12.8333 22.1667C17.988 22.1667 22.1667 17.988 22.1667 12.8333C22.1667 7.67868 17.988 3.5 12.8333 3.5C7.67868 3.5 3.5 7.67868 3.5 12.8333C3.5 17.988 7.67868 22.1667 12.8333 22.1667Z"
                                                    stroke="black" stroke-width="1.5" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <path d="M24.5 24.5001L19.4833 19.4834" stroke="black"
                                                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </span>
                                        <input type="text" id="search" [(ngModel)]="searchQuery"
                                            placeholder="Search experiences and destinations" required
                                            (keyup.enter)="experianceSearchParam()" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex d-md-none align-items-center"
                            *ngIf="!['LandingComponent'].includes(componentName)">
                            <div class="tooltips pointer me-2" (click)="goToCart()">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_2821_3994)">
                                        <path
                                            d="M6.66671 18.3332C7.12694 18.3332 7.50004 17.9601 7.50004 17.4998C7.50004 17.0396 7.12694 16.6665 6.66671 16.6665C6.20647 16.6665 5.83337 17.0396 5.83337 17.4998C5.83337 17.9601 6.20647 18.3332 6.66671 18.3332Z"
                                            stroke="#151515" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path
                                            d="M15.8333 18.3332C16.2936 18.3332 16.6667 17.9601 16.6667 17.4998C16.6667 17.0396 16.2936 16.6665 15.8333 16.6665C15.3731 16.6665 15 17.0396 15 17.4998C15 17.9601 15.3731 18.3332 15.8333 18.3332Z"
                                            stroke="#151515" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path
                                            d="M1.70837 1.7085H3.37504L5.59171 12.0585C5.67302 12.4375 5.88393 12.7764 6.18813 13.0167C6.49233 13.257 6.8708 13.3838 7.25837 13.3752H15.4084C15.7877 13.3745 16.1554 13.2446 16.4509 13.0067C16.7463 12.7688 16.9518 12.4373 17.0334 12.0668L18.4084 5.87516H4.26671"
                                            stroke="#151515" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_2821_3994">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <span class="countCart"
                                    *ngIf="authService.getCustomer() && cartCount>0">{{cartCount}}</span>
                                <span class="tooltiptexts">Cart</span>
                            </div>
                            <div class="tooltips pointer me-2" (click)="goToWishlist()">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M15.8333 11.6667C17.075 10.45 18.3333 8.99167 18.3333 7.08333C18.3333 5.86776 17.8504 4.70197 16.9909 3.84243C16.1313 2.98289 14.9655 2.5 13.75 2.5C12.2833 2.5 11.25 2.91667 9.99996 4.16667C8.74996 2.91667 7.71663 2.5 6.24996 2.5C5.03438 2.5 3.86859 2.98289 3.00905 3.84243C2.14951 4.70197 1.66663 5.86776 1.66663 7.08333C1.66663 9 2.91663 10.4583 4.16663 11.6667L9.99996 17.5L15.8333 11.6667Z"
                                        stroke="#151515" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>

                                <span class="tooltiptexts">Wish List</span>

                            </div>
                            <div class="pointer login-user">
                                <div class="pointer" *ngIf="!authService.getCustomer()" (click)="openModal(signin)">

                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M15.8333 17.5V15.8333C15.8333 14.9493 15.4821 14.1014 14.857 13.4763C14.2319 12.8512 13.384 12.5 12.5 12.5H7.49996C6.6159 12.5 5.76806 12.8512 5.14294 13.4763C4.51782 14.1014 4.16663 14.9493 4.16663 15.8333V17.5"
                                            stroke="#151515" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path
                                            d="M9.99996 9.16667C11.8409 9.16667 13.3333 7.67428 13.3333 5.83333C13.3333 3.99238 11.8409 2.5 9.99996 2.5C8.15901 2.5 6.66663 3.99238 6.66663 5.83333C6.66663 7.67428 8.15901 9.16667 9.99996 9.16667Z"
                                            stroke="#151515" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>

                                </div>

                                <div class="btn-group" dropdown placement="bottom right"
                                    *ngIf="authService.getCustomer()">
                                    <button id="button-basic" dropdownToggle type="button"
                                        class="btn dropdown-toggle bg-transparent border-0 p-0"
                                        aria-controls="dropdown-basic">
                                        <span>{{customerProfile?.first_name?.length ? customerProfile?.first_name[0] :
                                            ''}}</span>
                                    </button>
                                    <ul id="dropdown-basic" *dropdownMenu
                                        class="profile_dropdown dropdown-menu dropdown-menu-right" role="menu"
                                        aria-labelledby="button-basic">
                                        <li role="menuitem"><a class="dropdown-item" (click)="profilePage()">Profile</a>
                                        </li>
                                        <li role="menuitem"><a class="dropdown-item" (click)="logout(true)">Logout</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex d-md-none align-items-center"
                            *ngIf="['LandingComponent'].includes(componentName)">
                            <a>
                                <img [src]="landingLogo" class="logo_img_width" alt="">
                            </a>
                        </div>
                    </div>

                    <div class="menu_container menu_width_none md_flex_align_center" id="menu"
                        *ngIf="!['LandingComponent'].includes(componentName)">
                        <div class="menu_body">
                            <div class="menu_content d-none d-md-flex">

                                <div class="tooltips pointer" (click)="goToCart()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                        fill="none">
                                        <path
                                            d="M0 1.5C0 1.22386 0.223858 1 0.5 1H2C2.22943 1 2.42943 1.15615 2.48507 1.37873L2.89039 3H14.5C14.6498 3 14.7917 3.06716 14.8867 3.18301C14.9816 3.29885 15.0197 3.45117 14.9903 3.59806L13.9903 8.59806C13.9454 8.82257 13.7537 8.9879 13.525 8.99937L4.12822 9.47069L4.41496 11H13C13.2761 11 13.5 11.2239 13.5 11.5C13.5 11.7761 13.2761 12 13 12H12H5H4C3.75939 12 3.55291 11.8286 3.50856 11.5921L2.01131 3.6068L1.60961 2H0.5C0.223858 2 0 1.77614 0 1.5ZM3.10246 4L3.94223 8.47876L13.0861 8.02013L13.8901 4H3.10246ZM5 12C3.89543 12 3 12.8954 3 14C3 15.1046 3.89543 16 5 16C6.10457 16 7 15.1046 7 14C7 12.8954 6.10457 12 5 12ZM12 12C10.8954 12 10 12.8954 10 14C10 15.1046 10.8954 16 12 16C13.1046 16 14 15.1046 14 14C14 12.8954 13.1046 12 12 12ZM5 13C5.55228 13 6 13.4477 6 14C6 14.5523 5.55228 15 5 15C4.44772 15 4 14.5523 4 14C4 13.4477 4.44772 13 5 13ZM12 13C12.5523 13 13 13.4477 13 14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14C11 13.4477 11.4477 13 12 13Z"
                                            fill="black" />
                                    </svg>
                                    <span class="countCart"
                                        *ngIf="authService.getCustomer() && cartCount>0">{{cartCount}}</span>
                                    <span class="tooltiptexts">Cart</span>
                                </div>
                                <div class="tooltips pointer" (click)="goToWishlist()">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                        fill="none">
                                        <path
                                            d="M8 2.74805L7.28325 2.01133C5.5989 0.280067 2.51415 0.877695 1.40036 3.05284C0.876534 4.07583 0.75875 5.55246 1.71429 7.43758C2.63457 9.25313 4.54767 11.4265 8 13.7946C11.4523 11.4265 13.3654 9.25313 14.2857 7.43758C15.2413 5.55246 15.1235 4.07583 14.5996 3.05284C13.4859 0.877695 10.4011 0.280067 8.71675 2.01133L8 2.74805ZM8 15C-7.33313 4.86841 3.27876 -3.04087 7.82432 1.14308C7.88395 1.19797 7.94253 1.25493 8 1.314C8.05747 1.25494 8.11605 1.19797 8.17567 1.14309C12.7212 -3.04088 23.3331 4.8684 8 15Z"
                                            fill="black" />
                                    </svg>
                                    <span class="tooltiptexts">Wish List</span>

                                </div>
                                <div class="pointer d-flex position-relative">

                                    <div class="position-relative" id="dropdownCurrency">
                                        <a class="country-drop-btn currency-drop-btn"
                                            (click)="showCurrencyDrop=!showCurrencyDrop;">
                                            <div class="currency_btn">
                                                {{selectedCurrency}}
                                            </div>
                                        </a>
                                        <div class="countrydrop-con currencydrop-con shadow" *ngIf="showCurrencyDrop">
                                            <ul class="row">
                                                <li class="col-md-4" *ngFor="let item of currencyList">
                                                    <a class="d-flex" (click)="changeCurrency(item.code)">
                                                        {{ item.code }} &nbsp; {{ item.name }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex position-relative">
                                    <div class="position-relative" id="dropdownCountry">
                                        <a class="country-drop-btn" (click)="showCountryDrop=!showCountryDrop;">
                                            <div class="btn-flag-icon">
                                                <img *ngIf="selectedCountry"
                                                    src="https://tripxoxo-prod.s3.ap-south-1.amazonaws.com/tripxoxo_prod/general/countries/{{selCountryCode | lowercase}}.png?timestamp={{timestamp}}">
                                            </div>
                                        </a>
                                        <div hidden class="countrydrop-con shadow d-none" *ngIf="showCountryDrop">
                                            <ul>
                                                <li *ngFor="let item of countryDetailsData">
                                                    <a class="d-flex" (click)="changeCountry(item.id)">
                                                        <img *ngIf="selectedCountry"
                                                            src="https://tripxoxo-prod.s3.ap-south-1.amazonaws.com/tripxoxo_prod/general/countries/{{item.countryCode | lowercase}}.png?timestamp={{timestamp}}">
                                                        {{ item.countryName }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="pointer login-user">
                                    <button *ngIf="!authService.getCustomer()" (click)="openModal(signin) "
                                        type="button" class="btn btn-primary rounded-pill">
                                        Log In
                                    </button>

                                    <div class="btn-group" dropdown placement="bottom right"
                                        *ngIf="authService.getCustomer()">
                                        <button id="button-basic" dropdownToggle type="button"
                                            class="btn dropdown-toggle bg-transparent border-0 p-0"
                                            aria-controls="dropdown-basic">
                                            <span>{{customerProfile?.first_name?.length ? customerProfile?.first_name[0]
                                                :
                                                'U'}}</span>
                                            <small
                                                *ngIf="authService.getCustomer()">{{customerProfile?.first_name}}</small>
                                        </button>
                                        <ul id="dropdown-basic" *dropdownMenu
                                            class="profile_dropdown dropdown-menu dropdown-menu-right" role="menu"
                                            aria-labelledby="button-basic">
                                            <li role="menuitem"><a class="dropdown-item"
                                                    (click)="profilePage()">Profile</a></li>
                                            <li role="menuitem"><a class="dropdown-item"
                                                    (click)="logout(true)">Logout</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="menu_content d-md-none d-block">
                                <div class="d-flex align-items-center border-0">
                                    <div class="d-flex position-relative me-3 w-100">
                                        <div class="position-relative w-100" id="dropdownCountryMob">
                                            <a class="country-drop-btn"
                                                (click)="showCountryDropMob=!showCountryDropMob;">
                                                <div class="btn-flag-icon">
                                                    <img *ngIf="selectedCountry"
                                                        src="https://tripxoxo-prod.s3.ap-south-1.amazonaws.com/tripxoxo_prod/general/countries/{{selCountryCode | lowercase}}.png?timestamp={{timestamp}}">
                                                    {{selectedCountryName}}
                                                </div>
                                            </a>
                                            <div hidden class="countrydrop-con shadow d-none"
                                                *ngIf="showCountryDropMob">
                                                <ul>
                                                    <li *ngFor="let item of countryDetailsData">
                                                        <a class="d-flex" (click)="changeCountry(item.id)">
                                                            <img *ngIf="selectedCountry"
                                                                src="https://tripxoxo-prod.s3.ap-south-1.amazonaws.com/tripxoxo_prod/general/countries/{{item.countryCode | lowercase}}.png?timestamp={{timestamp}}">
                                                            {{ item.countryName }}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pointer d-flex position-relative w-100">

                                        <div class="position-relative w-100" id="dropdownCurrencyMob">
                                            <a class="country-drop-btn currency-drop-btn"
                                                (click)="showCurrencyDropMob=!showCurrencyDropMob;">
                                                <div class="currency_btn">
                                                    <span>{{getCurrencySymbol(selectedCurrency)}}</span>
                                                    {{selectedCurrency}}
                                                </div>
                                            </a>
                                            <div class="countrydrop-con currencydrop-con shadow"
                                                *ngIf="showCurrencyDropMob">
                                                <ul class="row">
                                                    <li class="col-md-4" *ngFor="let item of currencyList">
                                                        <a class="d-flex" (click)="changeCurrency(item.code)">
                                                            {{ item.symbol }} &nbsp; {{ item.code }} &nbsp; {{ item.name
                                                            }}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <a href="/" routerLink="/" (click)="closeMenu()">
                                        Home
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                            viewBox="0 0 20 20" fill="none">
                                            <path d="M7.5 15L12.5 10L7.5 5" stroke="#A1A1A1" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </a>
                                </div>
                                <div>
                                    <a href="/about-us" routerLink="/about-us" (click)="closeMenu()">
                                        About US
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                            viewBox="0 0 20 20" fill="none">
                                            <path d="M7.5 15L12.5 10L7.5 5" stroke="#A1A1A1" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </a>
                                </div>
                                <div>
                                    <a href="/blog" routerLink="/blog" (click)="closeMenu()">
                                        Blog
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                            viewBox="0 0 20 20" fill="none">
                                            <path d="M7.5 15L12.5 10L7.5 5" stroke="#A1A1A1" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </a>
                                </div>

                                <div>
                                    <a href="/contact-us" routerLink="/contact-us" (click)="closeMenu()">
                                        Contact Us
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                            viewBox="0 0 20 20" fill="none">
                                            <path d="M7.5 15L12.5 10L7.5 5" stroke="#A1A1A1" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </a>
                                </div>
                                <div>
                                    <a href="/privacy-policy" routerLink="/privacy-policy" (click)="closeMenu()">
                                        Privacy Policy
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                            viewBox="0 0 20 20" fill="none">
                                            <path d="M7.5 15L12.5 10L7.5 5" stroke="#A1A1A1" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </a>
                                </div>
                                <div>
                                    <a href="/terms-and-condition" routerLink="/terms-and-condition"
                                        (click)="closeMenu()">
                                        Terms and condition
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                            viewBox="0 0 20 20" fill="none">
                                            <path d="M7.5 15L12.5 10L7.5 5" stroke="#A1A1A1" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="menu_container menu_width_none md_flex_align_center" id="menu"
                        *ngIf="['LandingComponent'].includes(componentName)">
                        <div class="menu_body">
                            <div class="menu_content d-none d-md-flex">
                                <a>
                                    <img [src]="landingLogo" class="logo_img_width" alt="">
                                </a>
                            </div>
                            <div class="menu_content d-md-none d-block">
                                <a [routerLink]="subScribeLogo?.homeUrl">
                                    <img [src]="subScribeLogo?.logoSrc" class="logo_img_width" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #signin>
    <trpx-sign-in (closeModal)="closeModal()"></trpx-sign-in>
</ng-template>