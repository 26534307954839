import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { HomeService } from 'src/app/service/home.service';
import { SearchResultService } from 'src/app/service/search-results.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'trpx-search-suggestions',
  templateUrl: './search-suggestions.component.html',
  styleUrls: ['./search-suggestions.component.scss'],
})
export class SearchSuggestionsComponent implements OnInit {
  @Input() suggestionData: any;
  private dataSubscription: Subscription = new Subscription();

  searchSuggestionListData: any;
  countryId: any = '15a89f73-b053-473c-b984-0ed6ee98eb36';

  hostUrl: any;
  baseUrl: any;
  constructor(
    private apollo: Apollo,
    private router: Router,
    private searchService: SearchResultService,
    private homeService: HomeService,
    private locationStrategy: LocationStrategy,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.dataSubscription = this.homeService.country$.subscribe((data) => {
      this.countryId = data;
      if (this.countryId) this.getSuggestionDetails(this.countryId);
    });
  }

  ngOnInit(): void {}

  searchCountry(countryName: any) {
    if (isPlatformBrowser(this.platformId)) {
      let urlTree: UrlTree = this.router.createUrlTree(['/search-results'], {
        queryParams: { type: 'country', country: countryName },
      });
      this.baseUrl = this.router.serializeUrl(urlTree);

      if (this.locationStrategy instanceof HashLocationStrategy) {
        const baseHref = this.locationStrategy.getBaseHref();
        this.baseUrl = `${baseHref}#${this.baseUrl}`;
      }

      this.hostUrl = window.location.origin;
    }

    return `${this.hostUrl}${this.baseUrl}`;
  }

  searchCategory(categoryName: any) {
    if (isPlatformBrowser(this.platformId)) {
      let urlTree: UrlTree = this.router.createUrlTree(['/search-results'], {
        queryParams: { type: 'category', category: categoryName },
      });
      this.baseUrl = this.router.serializeUrl(urlTree);

      if (this.locationStrategy instanceof HashLocationStrategy) {
        const baseHref = this.locationStrategy.getBaseHref();
        this.baseUrl = `${baseHref}#${this.baseUrl}`;
      }

      this.hostUrl = window.location.origin;
    }
    return `${this.hostUrl}${this.baseUrl}`;
  }

  searchDestination(destinationName: any) {
    if (isPlatformBrowser(this.platformId)) {
      let urlTree: UrlTree = this.router.createUrlTree(['/search-results'], {
        queryParams: { type: 'destination', destination: destinationName },
      });
      this.baseUrl = this.router.serializeUrl(urlTree);

      if (this.locationStrategy instanceof HashLocationStrategy) {
        const baseHref = this.locationStrategy.getBaseHref();
        this.baseUrl = `${baseHref}#${this.baseUrl}`;
      }

      this.hostUrl = window.location.origin;
    }
    return `${this.hostUrl}${this.baseUrl}`;
  }

  destinationLog(destinationName: any) {
    this.searchService.getExperienceLog(destinationName).subscribe((res) => {
      let data = res;
    });
  }

  getSuggestionDetails(country: string) {
    this.apollo
      .watchQuery({
        query: this.searchService.searchBoxSuggestion,
        variables: {
          variable1: country,
        },
      })
      .valueChanges.subscribe(({ data, error }: any) => {
        this.searchSuggestionListData = data?.suggestion;
      });
  }

  openLink(productSlug: string) {
    if (isPlatformBrowser(this.platformId)) {
      let urlTree: UrlTree = this.router.createUrlTree([
        `/product/${productSlug}`,
      ]);
      this.baseUrl = this.router.serializeUrl(urlTree);

      if (this.locationStrategy instanceof HashLocationStrategy) {
        const baseHref = this.locationStrategy.getBaseHref();
        this.baseUrl = `${baseHref}#${this.baseUrl}`;
      }

      this.hostUrl = window.location.origin;
    }
    return `${this.hostUrl}${this.baseUrl}`;
  }

  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
  }
}
