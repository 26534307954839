import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private http: HttpClient) {}

  setBookingCreate(data: any): Observable<any> {
    return this.http.post(`${environment.bookingApiUrl}/bookings`, data);
  }

  singleproductDetails = gql`
    query productDetails(
      $variable1: String!
      $variable2: String!
      $variable3: String!
    ) {
      productDetails(
        productSlug: $variable1
        country: $variable2
        currency: $variable3
      ) {
        id
        price
        review
        rating
        productSlug
        offerPercentage
        imageUrl
        isEnquiry
        ticketdetailsSet {
          id
          profiles {
            id
            name
            max
            min
            price
            slashedPrice
          }
          tickets {
            id
            name
            price
            profileName
            description
            slashedPrice
          }
        }
        attributes
        productDescriptionSet {
          heading
          description
        }
        destinations {
          destinationName
          longitude
          latitude
        }
        productTourDetail{
          latitude
          longitude
        }
        name
        productStatus
        productCode
        productInfo
        provider
        productVersion
        productSlug
        relatedProducts
        productOffers
        durationHours
        durationDays
        highlights
        overview
        about
        moreDetails
        parity
        isWishlist
      }
    }
  `;

  productDetails = gql`
    query productDetails(
      $variable1: Int!
      $variable2: Int!
      $variable3: String!
      $variable4: String!
    ) {
      productDetails(
        limit: $variable1
        offset: $variable2
        country: $variable3
        currency: $variable4
      ) {
        id
        name
        attributes
        productOffers
        highlights
        price
        review
        rating
        imageUrl
        productSlug
        tag
      }
    }
  `;

  productUpsell = gql`
    query productUpsellProducts(
      $variable1: Int!
      $variable2: Int!
      $variable3: String!
      $variable4: String!
    ) {
      productUpsellProducts(
        limit: $variable1
        offset: $variable2
        product: $variable3
        currency: $variable4
      ) {
        id
        name
        attributes
        productOffers
        highlights
        price
        review
        rating
        imageUrl
        productSlug
        tag
      }
    }
  `;

  productReviews = gql`
    query productReviews(
      $variable1: Int!
      $variable2: Int!
      $variable3: String!
    ) {
      productReviews(
        limit: $variable1
        offset: $variable2
        product: $variable3
      ) {
        totalResults
        averageRating
        ratingCount
        userReviews {
          customerName
          comment
          rating
          reviewedAt
        }
      }
    }
  `;

  checkProductAvailability(
    id: any,
    currency: any,
    ticket?: any
  ): Observable<any> {
    let params = new HttpParams()
      .set('product_id', id)
      .set('currency', currency);
    if (ticket !== undefined && ticket !== null) {
      params = params.set('ticket', ticket);
    }
    return this.http.get(`${environment.bookingApiUrl}/product_availability`, {
      params,
    });
  }

  getAffiliateAssign(): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/affiliate-assign`);
  }

  addProductBooking(data: any): Observable<any> {
    return this.http.post(`${environment.bookingApiUrl}/bookings`, data);
  }

  groupBookingProduct(data: any): Observable<any> {
    return this.http.post(`${environment.bookingApiUrl}/group-bookings`, data);
  }

  enquiryProduct(data: any): Observable<any> {
    return this.http.post(`${environment.bookingApiUrl}/enquiry-product`, data);
  }

  addProductReview(data: any): Observable<any> {
    return this.http.post(`${environment.bookingApiUrl}/product-review`, data);
  }

  addProductRating(data: any): Observable<any> {
    return this.http.post(`${environment.bookingApiUrl}/product-rating`, data);
  }

  shareImage() {
    return this.http.get(`${environment.bookingApiUrl}/affiliate-check`);
  }

  getProductViewLog(id: any, name: any, slug: any): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/product-view-log?product_id=${id}&product_name=${name}&product_slug=${slug}`);
  }

  getAffiliateLink(url: any, affiliate_id: any): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/affiliate-link-landing?url=${url}&affiliate_id=${affiliate_id}`);
  }

 }
