<div class="my-wallet">
    <div class="row">
        <div class="col-md-12" *ngIf="!wishListData?.length">
            <div class="wish_list_content">
                <div class="mb-5">
                    <img src="https://tripxoxo-prod.s3.ap-south-1.amazonaws.com/tripxoxo_prod/assets/images/wish_list.svg" class="img-fluid" alt="">
                </div>
                <div class="heading_20 mb-3">Your Wishlist Awaits</div>
                <div class="description_16">
                    Add Items to your Wishlist as you browse and they will magically appear here.
                </div>

                <button class="btn button-green" type="button" [routerLink]="'/'">
                    Start Exploring
                </button>
            </div>
        </div>
        <div class="col-md-12" *ngIf="wishListData?.length">
            <div class="heading_32">Wish List</div>
        </div>
        <div class="col-md-12" *ngIf="wishListData?.length">
            <div *ngFor="let item of wishListData">
                <div class="booking-profile">
                    <div class="d-md-flex">
                        <div class="background-image  pointer" [routerLink]="['/product', item.product_slug]"
                            target="_blank"
                            [ngStyle]="{background: 'url('+item?.product_details?.image_url + ')' + 'no-repeat center / cover,#cccccc'}">
                        </div>
                        <div class="d-md-grid width_balance_320">
                            <div class="d-flex w-100">
                                <div class="heading_20 mb-3 mb-md-3 w-100 pointer"
                                    [routerLink]="['/product', item.product_slug]" target="_blank">
                                    {{item?.product_details?.name}}</div>
                                <div class="cancle_ticket pointer"
                                    (click)="removewishListItem(item?.customer, item?.product)">
                                    <img class="img-fluid" src="../../../assets/icons/trash.svg" alt="">
                                </div>
                            </div>
                            <div class="trpx-star-rating mb-3">
                                <span class="me-2">
                                    <img src="./assets/icons/Star.svg" class="img-star" alt="">
                                </span>
                                <span class="heading_20 me-2">{{item?.product_details?.rating}}</span>
                                <span class="description_16">({{item?.product_details?.review}} Reviews)</span>
                            </div>
                            <div class="trpx-activity-labels">
                                <span *ngFor="let subCards of item?.product_details?.attributes">
                                    {{subCards}}
                                </span>
                            </div>
                            <div class="d-flex align-items-end justify-content-between">
                                <div class="">
                                    <div class="description_14 mb-1">
                                        Starts from
                                    </div>
                                    <div class="heading_20">{{item?.product_details?.price}}</div>
                                </div>
                                <div>
                                    <button class="btn btn-primary button-green"
                                        [routerLink]="['/product', item.product_slug]" target="_blank">
                                        Book Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>