import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  getCustomerSale(fromDate: any, toDate: any): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/customer-sale?fromDate=${fromDate}&toDate=${toDate}`);
  }

  getCustomerOrders(fromDate: any, toDate: any): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/customer-orders?fromDate=${fromDate}&toDate=${toDate}`);
  }

  getTotalCommission(fromDate: any, toDate: any): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/get-total-commission?fromDate=${fromDate}&toDate=${toDate}`);
  }

 getAffiliateLinkLanding(): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/get-affiliate-link-landing`);
  }

  getAffiliateLinkBooking(): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/get-affiliate-link-booking`);
  }
}
