import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { SharedService } from 'src/app/service/shared.service';
import { DatePipe } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'trpx-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss'],
})
export class PersonalInfoComponent implements OnInit {
  updateForm: any = FormGroup;
  formData: any;
  countryData: any;

  genders: any[] = [];
  genderSubscription!: Subscription;

  selectedDial: string = '+91';
  minDate: Date;

  disableSelect = new FormControl(false);
  isTravelAgent = false;
  minAge = new Date(
    new Date().getFullYear() - 18,
    new Date().getMonth(),
    new Date().getDate()
  ).toISOString();

  @Output() updateprofile = new EventEmitter<any>();

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private http: HttpClient,
    public cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private toastr: ToastrService,
    public authService: AuthService,
    private datePipe: DatePipe,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.isTravelAgent = localStorage.getItem('Trip_Travel_Agent')
        ? true
        : false;
    }
    this.getData().subscribe((response: any) => {
      this.countryData = response;
      this.cdr.detectChanges();
    });

    this.minDate = new Date();
    this.minDate.setFullYear(this.minDate.getFullYear() - 18);
    this.dateAdapter.setLocale('en-GB');
  }
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const data = localStorage.getItem('token');
      if (data) {
        this.getCustomerProfile();
      } else {
        this.router.navigate([`/`]);
      }
    }
    this.genderSubscription = this.sharedService.genders$.subscribe(
      (updatedGenders) => {
        this.genders = updatedGenders;
      }
    );
    this.genders = this.sharedService.getGenders();
    this.resetForm();
  }

  receiveCountryCode(code: string) {
    this.updateForm.controls['country_code'].setValue(code);
  }

  resetForm(data?: any) {
    this.updateForm = this.fb.group({
      first_name: [data ? data.first_name : '', Validators.required],
      last_name: [data ? data.last_name : '', Validators.required],
      email: [data ? data.email : '', Validators.required],
      dob: [data ? data.dob : '', [this.minimumAgeValidator.bind(this)]],
      country_code: [data ? data.country_code : '', Validators.required],
      mobile_number: [data ? data.mobile_number : '', Validators.required],
      gender: [data ? data.gender : ''],

      company_name: [data ? data.company_name : ''],
      address: [data ? data.address : ''],
      city: [data ? data.city : ''],
      state: [data ? data.state : ''],
      zip_code: [data ? data.zip_code : ''],
      gst_no: [data ? data.gst_no : ''],
      pancard_no: [data ? data.pancard_no : ''],
    });
  }
  minimumAgeValidator(control: FormControl): { [key: string]: boolean } | null {
    if (control.value) {
      const birthDate = new Date(control.value);
      const today = new Date();
      const minDate = new Date();
      minDate.setFullYear(minDate.getFullYear() - 18); // Calculate the minimum date

      if (birthDate > today || birthDate > minDate) {
        return { invalidAge: true }; // Invalid age
      }
    }
    return null; // Age is valid
  }

  getData() {
    return this.http.get('./assets/json/countrycodes.json');
  }

  getCustomerProfile() {
    if (this.isTravelAgent) {
      this.authService.getAgentCustomerProfile().subscribe((result) => {
        this.formData = result;
        this.authService.setCustomer(this.formData);
        this.updateprofile.emit();
      });
    } else {
      this.authService.getCustomerProfile().subscribe((result) => {
        this.formData = result;
        this.authService.setCustomer(this.formData);
        this.updateprofile.emit();
      });
    }
  }

  updateFormSubmit(id: any, form: any) {
    form.value.dob = this.datePipe.transform(form.value.dob, 'yyyy-MM-dd');
    if (this.isTravelAgent) {
      this.authService
        .updateAgentCustomerProfile(id, form.value)
        .subscribe((res) => {
          this.getCustomerProfile();
          this.toastr.success('Profile updated successfully', 'Updated.');
        });
    } else {
      this.authService
        .updateCustomerProfile(id, form.value)
        .subscribe((res) => {
          this.getCustomerProfile();
          this.toastr.success('Profile updated successfully', 'Updated.');
        });
    }
  }
  ngOnDestroy() {
    this.genderSubscription.unsubscribe();
  }
}
