import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Options } from 'ngx-slider-v2';

@Component({
  selector: 'trpx-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit {
  @Input() componentName: any;
  @Input() componentData: any;
  @Input() countrytData: any;
  @Input() disabled: any;
  countryList: any;
  destinationList: any;
  categoryList: any;
  @Input() destinationData: any;
  @Input() categoryData: any;
  @Output() newItemEvent = new EventEmitter<any>();
  aminityList: any;
  nestedCategory: any[] = [];
  nestedDummyCategory: any[] = [];

  @Input() minValue: number = 0;
  @Input() maxValue: number = 100000;
  @Input() sortItems: any;
  selectedSort: string = '';
  selectedRating: number = 0;

  transferOptions: Options = {
    floor: 0,
    ceil: 100000,
    step: 1000,
  };
  productOptions: Options = {
    floor: 0,
    ceil: 100000,
    step: 2000,
  };

  @Input() set vehiclePrice(numbers: any) {
    if (Array.isArray(numbers) && numbers?.length > 0) {
      this.transferOptions = {
        floor: this.minValue,
        ceil: this.maxValue,
        step: 1000,
      };
    }
  }

  @Input() set aminities(value: any) {
    if (value) {
      this.aminityList = [];
      value.forEach((element: any) => {
        this.aminityList.push({
          name: element.name,
          selected: false,
        });
      });
    }
  }

  dummyCategoryData: any = [
    {
      parentCategory: 'others',
      category: [
        {
          id: 'ca55b561-cf52-4d79-934f-f9515cd9fe75',
          categoryName: 'ACTIVITY',
          __typename: 'CategoryType',
        },
        {
          id: '805f2d42-c2c6-421e-8ac6-b55e324de631',
          categoryName: 'Day tour',
          __typename: 'CategoryType',
        },
        {
          id: '8134ab60-86a8-42a9-b2b1-8d906fa51ac9',
          categoryName: 'HOP_ON_HOP_OFF',
          __typename: 'CategoryType',
        },
        {
          id: '5c950c72-0d7a-464e-b45f-7ada67b0a3ae',
          categoryName: 'STANDARD',
          __typename: 'CategoryType',
        },
        {
          id: '68d35321-852a-4d3f-b76b-40cad4e94b91',
          categoryName: 'UNSTRUCTURED',
          __typename: 'CategoryType',
        },
      ],
      __typename: 'ParentCategoryType',
    },
  ];

  constructor() {
    if (this.dummyCategoryData?.length) {
      this.dummyCategoryData.forEach((category: any) => {
        this.nestedDummyCategory.push({
          name: category.parentCategory,
          selected: false,
          color: 'primary',
          subcategory: category.category.map((item: any) => {
            return { ...item, selected: false }; // Adding the "selected" key with value `false`
          }),
        });
      });
    }
  }

  ngOnInit(): void {
    if (this.categoryData?.length) {
      this.categoryData.forEach((category: any) => {
        this.nestedCategory.push({
          name: category.parent_category,
          selected: false,
          color: 'primary',
          subcategory: category.category.map((item: any) => {
            return { ...item, selected: false }; // Adding the "selected" key with value `false`
          }),
        });
      });
    }
  }

  selectOption(value: string) {
    this.selectedSort = value;
    this.getFilter(true);
  }

  onRadioChange(event: any) {
    this.selectedRating = event.value;
    this.getFilter(true);
  }

  clearSelection() {
    this.selectedRating = 0;
    this.getFilter(true);
  }

  // getTransferFilter() {
  //   console.log(this.aminityList);
  //   this.newItemEvent.emit(this.aminityList);
  // }

  getFilter(isSort?: boolean) {
    const filterNames: any = [];
    const selectedCategory: any = [];

    this.aminityList?.forEach((result: any, index: any) => {
      if (result.selected) {
        filterNames.push(this.aminityList[index].name);
      }
    });

    this.nestedCategory?.forEach((category: any, index: any) => {
      category?.subcategory.forEach((subcat: any) => {
        if (subcat.selected) selectedCategory.push(subcat.category_name);
      });
    });
    const data = {
      aminityList: filterNames,
      minPrice: this.minValue,
      maxPrice: this.maxValue,
      categoryList: selectedCategory,
      sortValue: this.selectedSort,
      ratingValue: this.selectedRating,
      isSort: isSort ?? false,
    };
    this.newItemEvent.emit(data);
  }
  resetFilter() {
    for (let i = 0; i < this.aminityList?.length; i++) {
      this.aminityList[i].selected = false;
    }
    for (let i = 0; i < this.destinationList?.length; i++) {
      this.destinationList[i].selected = false;
    }
    for (let i = 0; i < this.categoryList?.length; i++) {
      this.categoryList[i].selected = false;
    }
    const data = {
      aminityList: [],
      minPrice: this.minValue,
      maxPrice: this.maxValue,
      categoryList: [],
    };

    this.newItemEvent.emit(data);
  }

  panelOpenState = false;
  allComplete: boolean[] = [false];

  updateAllComplete(category: any, i: number) {
    category.selected =
      category.subcategory != null &&
      category.subcategory.every((t: any) => t.selected);
  }

  someComplete(category: any, i: number): boolean {
    if (category.subcategory == null) {
      return false;
    }
    return (
      category.subcategory.filter((t: any) => t.selected).length > 0 &&
      !category.selected
    );
  }

  setAll(selected: boolean, category: any, i: number) {
    category.selected = selected;
    if (category.subcategory == null) {
      return;
    }
    category.subcategory.forEach((t: any) => (t.selected = selected));
    this.getFilter();
  }
}
