<div id="loading-wrapper" *ngIf="firstLoading">
  <div class="loader-img">
    <img *ngIf="!loaderGif" src="./assets/images/loader-1.gif" alt>
    <img *ngIf="loaderGif && indigoLabel" src="./assets/images/loader-2.gif"
      alt>
    <img *ngIf="loaderGif && yesrewardLabel" src="./assets/images/loader-3.gif"
      alt>
  </div>
</div>

<div class="payment-progress" style="background: #f2faf6">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-10">
        <ul class="progress-bar">
          <li class="progress-item heading_16 mb-0 text-center text-md-start">
            Choose <br class="d-block d-md-none" />
            Booking
          </li>
          <li class="progress-item heading_16 mb-0 text-center">
            Traveller <br class="d-block d-md-none" />
            Details
          </li>
          <li class="progress-item description_16 mb-0 text-center text-md-end">
            Billing <br class="d-block d-md-none" />
            Details
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="trpx-payment-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 col-xxl-8">
        <div class="trpx-traveller-details">
          <div class="heading_18 mb-md-4">Select your Payment Option</div>
          <div class="select_payment_option d-flex align-items-center">
            <div class="paymnt_option pointer"
              (click)="selectedPayMethod = 'upi'"
              *ngIf="this.selectedCurrency === 'INR'"
              [ngClass]="{ 'pay-selected': selectedPayMethod == 'upi' }">

              <div>
                <img src="https://tripxoxo-prod.s3.ap-south-1.amazonaws.com/tripxoxo_prod/assets/images/upi-icon.png"
                  style="height: 50px;margin-bottom: 8px;" class="img-fluid"
                  alt>
                <div class="description_16 color_13">UPI</div>
              </div>
            </div>
            <div class="paymnt_option pointer"
              (click)="selectedPayMethod = 'cards'"
              [ngClass]="{ 'pay-selected': selectedPayMethod == 'cards' }">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg"
                  style="margin-bottom: 8px;" width="50" height="50"
                  viewBox="0 0 36 36" fill="none">
                  <path
                    d="M33.6753 23.7732L8.9163 28.1389C7.38658 28.4086 5.92788 27.3872 5.65816 25.8575L2.93284 10.4017C2.66312 8.87194 3.68455 7.41324 5.2142 7.14352L29.9732 2.77781C31.5029 2.5081 32.9616 3.52953 33.2313 5.05917L35.9567 20.5151C36.2264 22.0447 35.205 23.5035 33.6753 23.7732Z"
                    fill="#3555A2" />
                  <path
                    d="M29.9732 2.77781L17.5938 4.96067L21.2958 25.9561L33.6753 23.7733C35.205 23.5035 36.2264 22.0448 35.9566 20.5151L33.2313 5.05924C32.9617 3.52953 31.503 2.5081 29.9732 2.77781Z"
                    fill="#1B3555" />
                  <path
                    d="M27.4219 33.2656H2.8125C1.25923 33.2656 0 32.0063 0 30.4531V15.1448C0 13.5915 1.25923 12.3323 2.8125 12.3323H27.4219C28.9751 12.3323 30.2344 13.5915 30.2344 15.1448V30.4531C30.2344 32.0063 28.9751 33.2656 27.4219 33.2656Z"
                    fill="#53D8E3" />
                  <path
                    d="M27.4219 12.3323H15.1172V33.2656H27.4219C28.9751 33.2656 30.2344 32.0063 30.2344 30.4531V15.1448C30.2344 13.5915 28.9751 12.3323 27.4219 12.3323Z"
                    fill="#18A8C9" />
                  <path d="M0 17.1169H30.2344V21.9017H0V17.1169Z"
                    fill="#ECF6FD" />
                  <path
                    d="M8.37363 28.6221H3.58887C3.0064 28.6221 2.53418 29.0943 2.53418 29.6768C2.53418 30.2592 3.0064 30.7314 3.58887 30.7314H8.37363C8.9561 30.7314 9.42832 30.2592 9.42832 29.6768C9.42832 29.0943 8.9561 28.6221 8.37363 28.6221Z"
                    fill="#EAF6FF" />
                  <path d="M15.1172 17.1169H30.2344V21.9017H15.1172V17.1169Z"
                    fill="#D6E8FE" />
                </svg>
                <div class="description_16 color_13">Card</div>
              </div>
            </div>
            <div class="paymnt_option pointer"
              (click)="selectedPayMethod = 'net_banking'"
              *ngIf="this.selectedCurrency === 'INR'"
              [ngClass]="{ 'pay-selected': selectedPayMethod == 'net_banking' }">
              <div>
                <img src="https://tripxoxo-prod.s3.ap-south-1.amazonaws.com/tripxoxo_prod/assets/images/online-banking.webp"
                  style="height: 50px;margin-bottom: 8px;"
                  class="img-fluid" alt>
                <div class="description_16 color_13">Net Banking</div>
              </div>
            </div>
            <div class="paymnt_option pointer"
              (click)="selectedPayMethod = 'wallet'; setWalletPay()"
              [ngClass]="{ 'pay-selected': selectedPayMethod == 'wallet' }">
              <div>
                <img src="https://tripxoxo-prod.s3.ap-south-1.amazonaws.com/tripxoxo_prod/assets/images/cash.webp"
                  style="height: 50px;margin-bottom: 8px;" class="img-fluid"
                  alt>
                <div class="description_16 color_13">tX Wallet</div>
              </div>
            </div>
            <div class="paymnt_option pointer"
              (click)="selectedPayMethod = 'value_design'; setValuePay()"
              [ngClass]="{ 'pay-selected': selectedPayMethod == 'value_design' }">
              <div>
                <img src="https://tripxoxo-prod.s3.ap-south-1.amazonaws.com/tripxoxo_prod/assets/images/gift-card.png"
                  style="height: 50px;margin-bottom: 8px;" class="img-fluid"
                  alt>
                <div class="description_16 color_13">Gift Card</div>
              </div>
            </div>
          </div>
          <div class="value_design" *ngIf="isValueCard">
            <div class="d-flex align-items-center justify-content-between">
              <div class="heading_18 my-md-4 my-3">Enter Gift Card Voucher
                Details</div>
            </div>
            <div class="card_details_section value_card">
              <form [formGroup]="voucherForm"
                (ngSubmit)="getBalanceCard(voucherForm)">
                <!-- Bind your form group here -->
                <div class="row d-none d-md-flex">
                  <div class="col-md-5">
                    <div class="form-outline">
                      <label class="form-label color_13" for="voucherNumber">
                        Voucher Number
                      </label>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-outline">
                      <label class="form-label color_13" for="voucherPin">
                        Voucher Pin
                      </label>
                    </div>
                  </div>
                  <div class="col-md-2">

                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <div class="form-outline mb-4">
                      <label class="form-label color_13 d-md-none d-block"
                        for="voucherNumber">
                        Voucher Number
                      </label>
                      <input type="text" maxlength="16" appNumeric
                        formControlName="voucherNumber"
                        placeholder="Enter your voucher number"
                        class="form-control rounded-pill"
                        [readonly]="isValueLoading" />
                      <div
                        *ngIf="voucherForm.get('voucherNumber')?.invalid && voucherForm.get('voucherNumber')?.touched"
                        class="mt-2 ms-2 description_12 text-danger">
                        Voucher number must be 16 digits.
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-outline mb-4">
                      <label class="form-label color_13 d-md-none d-block"
                        for="voucherPin">
                        Voucher Pin
                      </label>
                      <input type="text" formControlName="voucherPin" appNumeric
                        placeholder="Pin" maxlength="6"
                        class="form-control rounded-pill"
                        [readonly]="isValueLoading" />
                      <div
                        *ngIf="voucherForm.get('voucherPin')?.invalid && voucherForm.get('voucherPin')?.touched"
                        class="mt-2 ms-2 description_12 text-danger">
                        Pin must be 6 digits.
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="mb-4">
                      <button class="btn btn-primary button-green w-100"
                        type="submit"
                        [disabled]="voucherForm.invalid  || isButtonDisabled">
                        <span *ngIf="isValueLoading"
                          class="spinner-border spinner-border-sm" role="status"
                          aria-hidden="true"></span> Add
                      </button>
                    </div>
                  </div>
                  <div *ngIf="errorMessage" class="col-md-12 mb-3 text-danger">
                    {{ errorMessage }}
                  </div>
                  <div class="col-md-12 mb-3"
                    *ngIf="errorMessage != 'You can use a maximum of 15 cards per transaction.'">
                    You can use a maximum of 15 cards per transaction.
                  </div>
                </div>
              </form> <!-- Form ends here -->
            </div>

            <div class="col-md-10 vocher_details_section">
              <ul class="p-0">
                <li class="ps-3 p-2"
                  *ngFor="let item of valueCardBalance; let i = index">
                  <div class="row">
                    <div class="col-6 text-black">
                      {{item?.card_no}}
                    </div>
                    <div class="col-4 color_00A651">
                      {{ travellerDetails?.currency_symbol }}
                      {{item?.balance_amount}}
                    </div>
                    <div class="col-2 colorFF2742 pointer"
                      (click)="removeCard(i)">
                      <img class="img-fluid"
                        src="../../../assets/icons/trash.svg" alt>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="selectedPayMethod == 'wallet'">
            Wallet Balance :
            <b class="text-success">{{travellerDetails?.currency_symbol}} {{
              walletAmount }}</b>
          </div>
        </div>
      </div>

      <div class="col-lg-5 col-xxl-4 px-0 px-md-3"
        *ngIf="bookingId || transfersBookingId">
        <div class="trpx-price-details"
          [ngClass]="{ 'price_show': isOpenPriceDetails }" *ngIf="!isTransfer">
          <div class="spacing">
            <div
              class="price_heading d-flex align-items-center justify-content-between">
              <div class="heading_18">Booking Details</div>
              <div class="close_svg d-block d-md-none"
                (click)="hidePriceDetails()">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none">
                  <path d="M19.0024 5L7.00244 17" stroke="black"
                    stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M7.00244 5L19.0024 17" stroke="black"
                    stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </div>
            </div>
            <div *ngFor="let ticket of travellerDetails?.tickets"
              class="mb-md-3">
              <div class="image_item mb-3"
                [ngStyle]="{background: 'url(' + ticket?.image_url + ')' + 'no-repeat center / cover,#cccccc'}">
              </div>
              <div class="heading_20 mb-3">{{ticket?.product_name}}</div>
              <div
                class="description_16 color_151515BD">{{ticket?.ticket_title}}</div>
              <div class="row">
                <div class="col-5 d-flex align-items-center">
                  <span class="d-block">
                    <img src="./assets/icons/calendar.svg" alt />
                  </span>
                  <span class="d-block mt-1 ps-2 color_15">{{ ticket?.date |
                    date: 'dd-MM-yy'}}</span>
                </div>
                <div class="col-3 ps-0 d-flex align-items-center">
                  <span class="d-block"><img src="./assets/icons/user.svg"
                      alt /></span>
                  <span class="d-block mt-1 ps-2 color_15">{{ ticket?.total_pax
                    }}</span>
                </div>
                <div class="col-4 ps-0 d-flex align-items-center"
                  *ngIf="ticket?.time_slot">
                  <span class="d-block"><img src="./assets/icons/clock.svg"
                      alt></span>
                  <span
                    class="d-block mt-1 ps-2 color_15">{{ticket?.time_slot}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="promo_code position-relative" *ngIf="!whiteLabelIndiGo">
            <div *ngIf="!appliedCode">
              <!-- <div class="heading_16 mb-2">Enter Promo Code</div> -->
              <div class="d-flex align-items-center position-relative">
                <input (click)="getCouponList()"
                  (keyup)="setToOriginalAmount($event)" [(ngModel)]="promoCode"
                  type="text" placeholder="Enter your promo code"
                  class="form-control rounded-pill promo_input" />
                <button class="btn btn-primary button-green button-promo"
                  (click)="applyCoupon()">
                  Apply
                </button>
              </div>

              <small *ngIf="message"
                class="text-danger d-block text-center mt-1">
                {{ message }}
              </small>
              <div *ngIf="showCouponList && couponList.length">
                <div class="coupon-box" #couponBox>
                  <div class="coupon-results">
                    <div class="coupon-scroll">
                      <div class="row">
                        <div class="col-md-12">
                          <ul>
                            <li class="description_16 mb-2"
                              *ngFor="let item of couponList">
                              <a class="pointer"
                                (click)="couponSelect(item?.code)">
                                <div class="d-flex align-items-center pointer">
                                  <div class="text-uppercase heading_16 me-3">
                                    {{item?.code}}
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="appliedCode">
              <div class="d-flex align-items-center justify-content-between">
                <div class="heading_16">
                  Promo Code <span class="color_00A651">{{appliedCode}}</span>
                  Applied
                </div>
                <small class="text-danger"
                  (click)="removeCoupen()">Remove</small>
              </div>

            </div>
          </div>

          <div class="line-round">
            <div class="border-dashed">
              <div class="d-flex w-100 justify-content-between">
                <div class="half-circle vertical right">
                  <span class="rotate"></span>
                </div>
                <div class="half-circle vertical left">
                  <span class="rotate"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="spacing">
            <div class="heading_18">Price Breakdown</div>
            <div class="row mb-3"
              *ngFor="let prices of travellerDetails?.prices">
              <div class="col-md-12 heading_16 color_13 mb-0">
                {{ prices?.ticket }}
                <hr class="mt-3 mb-2" />
              </div>
              <div class="col-12 description_16 mt-2 color_13 mb-0">
                <div class="row" *ngFor="let break of prices?.price_breakdown">
                  <div class="col-7 description_16 mb-1 color_13">
                    {{ break?.display_name }}
                  </div>
                  <div class="col-5 description_16 mb-1 color_13 text-end"
                    [ngClass]="{'color_00A651' : break?.is_discount || break?.is_product_discount}">
                    {{break?.is_discount || break?.is_product_discount ? '-' :
                    ''}} {{ travellerDetails?.currency_symbol
                    }} {{ break?.price }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3" *ngIf="couponResponse?.offer_price">
              <div class="col-12 description_16 mb-0">
                <div class="row">
                  <div class="col-7 heading_14 mb-0 color_13">Coupon
                    Discount</div>
                  <div class="col-5 heading_14 color_00A651 mb-0 text-end">
                    -{{ travellerDetails?.currency_symbol }}
                    {{ couponResponse?.offer_price }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3" *ngIf="totalSumBalance">
              <div class="col-12 description_16 mb-0">
                <div class="row">
                  <div class="col-7 heading_14 mb-0 color_13">Gift Card</div>
                  <div class="col-5 heading_14 color_00A651 mb-0 text-end">
                    -{{ travellerDetails?.currency_symbol }}
                    {{ totalSumBalance }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="row description_16 mb-1"
                  *ngIf="walletDeduction > 0 && selectedPayMethod == 'wallet'">
                  <div class="col-7 description_16 color_13">Deducted From
                    Wallet</div>
                  <div
                    class="col-5 description_16 color_13 text-secondary text-end wallet-minus">
                    {{ travellerDetails?.currency_symbol }}&nbsp;{{
                    walletDeduction
                    }}
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="row mb-3 pb-5 pb-md-0">
                  <div class="col-7 heading_16 color_13">Total Payable</div>
                  <div class="col-5 heading_16 color_13 text-end">
                    {{ travellerDetails?.currency_symbol }}&nbsp;{{
                    totalPayableAmount ? totalPayableAmount : 0
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="proceed_fixed">
              <button [disabled]="paymentDisable"
                class="btn btn-primary button-green w-100" type="submit"
                (click)="paymentOption(selectedPayMethod)">
                Proceed
              </button>
            </div>
          </div>
        </div>

        <div class="trpx-price-details"
          [ngClass]="{ 'price_show': isOpenPriceDetails }" *ngIf="isTransfer">
          <div class="spacing">
            <div
              class="price_heading d-flex align-items-center justify-content-between">
              <div class="heading_18">Transfer Details</div>
              <div class="close_svg d-block d-md-none"
                (click)="hidePriceDetails()">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none">
                  <path d="M19.0024 5L7.00244 17" stroke="black"
                    stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M7.00244 5L19.0024 17" stroke="black"
                    stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </div>
            </div>
            <div class="mb-3">
              <div class="row">
                <div class="col-12">
                  <span class="heading_16">
                    From <br>
                  </span>
                  <span class="color_151515BD"> {{
                    travellerDetails?.start_location }}</span>
                </div>
                <div class="col-11 mt-3">
                  <span class="heading_16">
                    To <br>
                  </span>
                  <span class="color_151515BD"> {{
                    travellerDetails?.end_location }}</span>
                </div>
                <div class="col-12 mt-3">
                  <span>
                    <img src="./assets/icons/calendar.svg" alt />
                  </span>
                  <span class="ps-2 color_151515BD">
                    {{ travellerDetails?.formated_pick_up_datetime |
                    date:'dd-MMM-yyyy' }}
                    &nbsp;
                    {{ travellerDetails?.formated_pick_up_datetime |
                    date:'hh:mm a' }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="line-round mt-0">
            <div class="border-dashed">
              <div class="d-flex w-100 justify-content-between">
                <div class="half-circle vertical right">
                  <span class="rotate"></span>
                </div>
                <div class="half-circle vertical left">
                  <span class="rotate"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="spacing">
            <div class="row mb-3">
              <div class="col-12 description_16 mt-2 mb-0">
                <div class="row">
                  <div class="col-7 heading_18 mb-0">
                    Price Final
                  </div>
                  <div class="col-5 heading_18 mb-0 text-end">
                    {{ travellerDetails?.currency_symbol }} {{
                    travellerDetails?.total_price }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-1"
                *ngIf="walletDeduction > 0 && selectedPayMethod == 'wallet'">
                <div class="row description_16">
                  <div class="col-7 description_16 mb-0">
                    Deducted From Wallet
                  </div>
                  <div
                    class="col-5 mb-0 text-end d-flex justify-content-end text-secondary heading_16 wallet-minus">
                    {{ travellerDetails?.currency_symbol }}&nbsp;{{
                    walletDeduction
                    }}
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row mb-3 pb-5 pb-md-0">
                  <div class="col-7 heading_16">Total Payable</div>
                  <div class="col-5 heading_16 text-end">
                    {{ travellerDetails?.currency_symbol }}&nbsp;{{
                    totalPayableAmount ? totalPayableAmount: 0
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="proceed_fixed">
              <button [disabled]="paymentDisable"
                class="btn btn-primary button-green w-100" type="submit"
                (click)="paymentOption(selectedPayMethod)">
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-5 col-xxl-4 px-0 px-md-3" *ngIf="attractionPassId">
        <div class="trpx-price-details"
          [ngClass]="{ 'price_show': isOpenPriceDetails }">
          <div class="spacing">
            <div
              class="price_heading d-flex align-items-center justify-content-between">
              <div class="heading_18">Booking Details</div>
              <div class="close_svg d-block d-md-none"
                (click)="hidePriceDetails()">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none">
                  <path d="M19.0024 5L7.00244 17" stroke="black"
                    stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M7.00244 5L19.0024 17" stroke="black"
                    stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </div>
            </div>
            <div class="mb-md-3">
              <div class="image_item mb-3"
                [ngStyle]="{background: 'url(' + attractionPrice?.image + ')' + 'no-repeat center / cover,#cccccc'}">
              </div>
              <div
                class="heading_20 mb-3">{{attractionPrice?.description}}</div>
              <!-- <div class="description_16 color_151515BD">{{attractionPrice?.description}}</div> -->
              <div class="row">
                <div class="col-3 d-flex align-items-center">
                  <span class="d-block"><img src="./assets/icons/user.svg"
                      alt /></span>
                  <span class="d-block mt-1 ps-2 color_15">1</span>
                </div>
              </div>
            </div>
          </div>
          <div class="line-round">
            <div class="border-dashed">
              <div class="d-flex w-100 justify-content-between">
                <div class="half-circle vertical right">
                  <span class="rotate"></span>
                </div>
                <div class="half-circle vertical left">
                  <span class="rotate"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="spacing">
            <div class="heading_18">Price Breakdown</div>
            <div class="row mb-3">
              <div class="col-12 description_16 mt-2 color_13 mb-0">
                <!-- <div class="row">
                  <div class="col-7 description_16 mb-1 color_13">
                    {{attractionPrice?.description}}
                  </div>
                  <div class="col-5 description_16 mb-1 color_13 text-end">
                    1
                  </div>
                </div> -->

                <div class="row">
                  <div class="col-7 description_16 color_13 mb-sm-1">
                    {{attractionPrice?.description}} x 1
                  </div>
                  <div class="col-5 description_16 color_13 mb-1 text-end">
                    {{attractionPrice?.currency_symbol}}&nbsp;
                    {{attractionPrice?.actual_price}}
                  </div>
                </div>

                <div class="row"
                  *ngIf="attractionPrice?.coupon_discount">
                  <div class="col-7 description_16 color_13 mb-sm-1">
                    Coupon Discount
                  </div>
                  <div class="col-5 description_16 color_13 mb-1 text-end">
                    {{attractionPrice?.currency_symbol}}&nbsp;
                    {{attractionPrice?.coupon_discount}}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 row description_16 mb-1"
                *ngIf="walletDeduction > 0 && selectedPayMethod == 'wallet'">
                <div class="col-7 description_16 mb-0">
                  Deducted From Wallet
                </div>
                <div
                  class="col-5 mb-0 text-end d-flex text-secondary heading_16 wallet-minus">
                  {{ travellerDetails?.currency_symbol }}&nbsp;{{
                  walletDeduction
                  }}
                </div>
              </div>
              <div class="col-md-12">
                <div class="row mb-3 pb-5 pb-md-0">
                  <div class="col-7 heading_16">Total Payable</div>
                  <div class="col-5 heading_16 text-end">
                    {{attractionPrice?.currency_symbol}}&nbsp;{{
                    attractionPrice?.total_payable_price }}
                  </div>
                </div>
              </div>
            </div>
            <div class="proceed_fixed">
              <button [disabled]="paymentDisable"
                class="btn btn-primary button-green w-100" type="submit"
                (click)="attractionPayment(selectedPayMethod)">
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="w-100 d-block d-md-none">
  <div class="w-100 fixed_bottom">
    <div class="heading_30 mb-3" *ngIf="bookingId || transfersBookingId">
      {{travellerDetails?.currency_symbol}}&nbsp; {{totalPayableAmount ?
      totalPayableAmount : 0}}
    </div>
    <div class="heading_30 mb-3" *ngIf="attractionPassId">
      {{ attractionPrice?.price }}
    </div>
    <div
      class="d-flex align-items-center d-md-block flex-row-reverse flex-md-row">
      <div class="w-100">
        <button class="btn btn-primary button-green w-100"
          (click)="showPriceDetails()">
          <!-- Show Price Details -->
          Proceed
        </button>
      </div>
    </div>
  </div>
</div>