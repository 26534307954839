import { Directive, ElementRef, HostListener, Input } from '@angular/core';
@Directive({
    selector: '[appNumeric]'
})
export class NumericDirective {
    @Input() allowDecimal: boolean = false;
    constructor(private el: ElementRef) { }
    @HostListener('input', ['$event']) onInput(event: Event): void {
        const inputElement = this.el.nativeElement as HTMLInputElement;
        const inputValue: string = inputElement.value;
        // Remove non-numeric characters
        const numericValue = inputValue.replace(/[^0-9.]/g, '');
        // Allow only one decimal point
        if (!this.allowDecimal) {
            inputElement.value = numericValue.replace('.', '');
        } else {
            inputElement.value = numericValue;
        }
    }
}