<div *ngIf="isSignIn && !fromPayment">
    <div #signInModal>
        <div class="trpx-login-modal-header modal-header">
            <h4 class="modal-title pull-left heading_40" *ngIf="isCustomerExist">Login or Register</h4>
            <h5 class="text-danger d-block font-17" *ngIf="!isCustomerExist">
                Hey there! You're not signed up yet. A verification code has been sent to your Whatsapp number
                {{mobileNumberShow}}
            </h5>
            <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="close()">
                <span aria-hidden="true" class="visually-hidden">&times;</span>
            </button>
        </div>
        <div class="trpx-login-modal-body modal-body">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="position-relative">
                            <div class="signin_form_outer_cont">
                                <form [formGroup]="otpGenerationForm" (ngSubmit)="generateOtp()"
                                    *ngIf="!isOtpGenerated">
                                    <div class="col-12 position-relative">
                                        <div class="custom_selet_component">
                                            <trpx-custom-select-box
                                                (countryCodeEvent)="receiveCountryCode($event)"></trpx-custom-select-box>
                                        </div>
                                        <mat-form-field class="mat_field_width" appearance="outline">
                                            <mat-label class="mat_field_mobile_label">WhatsApp Number</mat-label>
                                            <input type="tel" numbersOnly matInput placeholder="123-456-7890"
                                                class="mat_field_mobile_number" required
                                                formControlName="mobile_number">
                                        </mat-form-field>
                                    </div>
                                    <small class="text-warning mt-1 fw-sm" *ngIf="!isRegistered">WhatsApp Number not
                                        registered! <a class="fw-bolder pointer blue_link text-warning"
                                            (click)="signUp()">Register</a> to continue.</small>
                                    <div class="row">
                                        <div class="col-md-12 my-3 text-center">
                                            <button type="submit" class="btn btn-primary button-green w-100">
                                                Verify
                                            </button>
                                        </div>
                                    </div>

                                </form>
                                <form [formGroup]="otpVariForm" (ngSubmit)="otpVerify()" *ngIf="isOtpGenerated">
                                    <div class="row justify-content-center">
                                        <div class="col-md-12 text-center">
                                            <div class="form-outline mb-4">
                                                <div class="inputfield" formArrayName="otpFormArray">
                                                    <input type="number" maxlength="1" min="0" max="9"
                                                        class="otp_input input" required pattern="[0-9]*"
                                                        *ngFor="let input of otpInputs.controls; let i=index"
                                                        autocomplete="off" [formControlName]="i"
                                                        (input)="nextElem($event,i)" id="otpNum{{i}}"
                                                        (keydown)="disableArrowKeys($event,i)"
                                                        (paste)="onPaste($event)" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-8 col-md-7 description_14">
                                            Check WhatsApp for your OTP!
                                        </div>
                                        <div class="col-4 col-md-5 text-end description_14" *ngIf="timeToShow!='00:00'">
                                            Resend in {{timeToShow}}
                                        </div>
                                        <div class="col-4 col-md-5 text-end description_14" *ngIf="timeToShow=='00:00'">
                                            <span (click)="resendOtp()"
                                                class="pointer color_00A651 text-decoration-underline"
                                                tabindex="-1">Resend
                                                OTP </span>
                                        </div>

                                        <div class="col-md-12 mt-4 text-center">
                                            <button type="submit" class="btn btn-primary button-green w-100"
                                                tabindex="0" id="proceed-button">
                                                Proceed</button>
                                        </div>
                                    </div>

                                </form>
                                <div class="text-center mt-3">By signing in or creating an account you agree with our <a
                                        href="/terms-and-condition" routerLink="/terms-and-condition"
                                        (click)="close()">Terms & conditions</a> and <a href="/privacy-policy"
                                        routerLink="/privacy-policy" (click)="close()">Privacy statement</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!isSignIn && !fromPayment">
    <trpx-sign-up (signInTab)="isSignIn=true;" [showForm]="showForm" [mobileNumber]="mobileNumber"
        [countryCode]="countryCode" (closeModal)="close()" [isAgent]="isAgent"
        [isAffiliate]="isAffiliate"></trpx-sign-up>
</div>


<div class="row from-pay" *ngIf="fromPayment">
    <div class="col-12 position-relative">
        <form [formGroup]="otpGenerationForm" (ngSubmit)="generateOtp()" *ngIf="!isOtpGenerated">
            <div class="d-flex align-items-center">
                <div class="me-4 w-100">
                    <div class="custom_selet_component">
                        <trpx-custom-select-box
                            (countryCodeEvent)="receiveCountryCode($event)"></trpx-custom-select-box>
                    </div>

                    <mat-form-field class="mat_field_width" appearance="outline">
                        <mat-label class="mat_field_mobile_label">WhatsApp Number</mat-label>
                        <input type="tel" numbersOnly matInput placeholder="123-456-7890"
                            class="mat_field_mobile_number" required formControlName="mobile_number">
                    </mat-form-field>
                </div>
                <button type="submit" class="btn btn-primary button-green mb-4">
                    Verify
                </button>
            </div>
            <small class="text-warning mt-1" *ngIf="!isRegistered">WhatsApp Number not
                registered! <a class="fw-bolder pointer blue_link text-warning" (click)="signUp()">Register</a> to
                continue.</small>
        </form>

        <form [formGroup]="otpVariForm" (ngSubmit)="otpVerify()" *ngIf="isOtpGenerated">
            <div class="row justify-content-center mb-4">
                <div class="col-md-12 text-center">
                    <div class="form-outline">
                        <div class="inputfield d-flex align-items-center" formArrayName="otpFormArray">
                            <input type="number" maxlength="1" min="0" max="9" class="otp_input input" required
                                pattern="[0-9]*" *ngFor="let input of otpInputs.controls; let i=index"
                                autocomplete="off" [formControlName]="i" (input)="nextElem($event,i)" id="otpNum{{i}}"
                                (keydown)="disableArrowKeys($event)" (paste)="onPaste($event)" />
                            <button type="submit" class="btn btn-primary button-green ms-3" tabindex="0"
                                id="proceed-button">
                                Proceed</button>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-8 col-md-7 description_14">
                        Check WhatsApp for your OTP!
                    </div>
                    <div class="col-4 col-md-5 text-end description_14" *ngIf="timeToShow!='00:00'">
                        Resend in {{timeToShow}}
                    </div>
                    <div class="col-4 col-md-5 text-end description_14" *ngIf="timeToShow=='00:00'">
                        <span (click)="resendOtp()" class="pointer pointer color_00A651 text-decoration-underline"
                            tabindex="-1">Resend
                            OTP </span>
                    </div>
                </div>
            </div>
        </form>
    </div>

</div>

<ng-template #signup>
    <trpx-sign-up [isNew]="true" [countryCode]="countryCode" [mobileNumber]="mobileNumber" [showForm]="true"
        (closeModal)="closeSignUpModal()"></trpx-sign-up>
</ng-template>