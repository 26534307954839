<div class="promo_codes">
    <div class="row">
        <div class="col-md-12">
            <div class="heading_32">Promo Codes</div>
        </div>
        <div class="col-md-12">
            <div class="promo_code_item" *ngIf="clubPass">
                <div class="d-flex align-items-center">
                    <div class="promo_offf bg-cpass">
                        <div class="heading_24 text-white">
                            Club Pass
                        </div>
                    </div>
                    <div class="promo_content">
                        <div class="heading_16">Current Month Balance : {{clubPass?.current_month_balance}}</div>
                        <div class="description_16">Total Balance : {{clubPass?.total_balance}}</div>
                    </div>
                </div>
                <div class="promo_code pointer" (click)="clubCopyContent()">
                    <span class="pointer"><svg width="18" height="19" viewBox="0 0 18 19" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15 6.5H7.5C6.67157 6.5 6 7.17157 6 8V15.5C6 16.3284 6.67157 17 7.5 17H15C15.8284 17 16.5 16.3284 16.5 15.5V8C16.5 7.17157 15.8284 6.5 15 6.5Z"
                                stroke="#FAA633" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M3 12.5C2.175 12.5 1.5 11.825 1.5 11V3.5C1.5 2.675 2.175 2 3 2H10.5C11.325 2 12 2.675 12 3.5"
                                stroke="#FAA633" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span><span class="ms-1 heading_16" #clubPasscontentToCopy>{{clubPass?.code}}</span>
                </div>
            </div>
            <div class="promo_code_item pointer" *ngFor="let item of promoCodeData;">
                <div class="d-flex align-items-center">
                    <div class="promo_offf" (click)="goTolink(item);copyContent(item?.code);">
                        <div class="heading_24 text-white">
                            {{item?.discount_type_display}} <div class="text-white heading_14">OFF</div>
                        </div>
                    </div>
                    <div class="promo_content" (click)="goTolink(item);copyContent(item?.code);">
                        <div class="heading_16">{{item?.description}} <span *ngIf="item?.applicable_to_all_products"
                                class="color_83">(General Coupon)</span></div>
                        <div class="description_12" *ngIf="item?.valid_to">Ends {{item?.valid_to}}</div>
                    </div>
                </div>
                <div class="promo_code" (click)="copyContent(item?.code);">
                    <span class="me-2 heading_16" #contentToCopy>{{item?.code}}</span>
                    <span class="pointer"><svg width="18" height="19" viewBox="0 0 18 19" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15 6.5H7.5C6.67157 6.5 6 7.17157 6 8V15.5C6 16.3284 6.67157 17 7.5 17H15C15.8284 17 16.5 16.3284 16.5 15.5V8C16.5 7.17157 15.8284 6.5 15 6.5Z"
                                stroke="#FAA633" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M3 12.5C2.175 12.5 1.5 11.825 1.5 11V3.5C1.5 2.675 2.175 2 3 2H10.5C11.325 2 12 2.675 12 3.5"
                                stroke="#FAA633" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>