import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import {
  LocationStrategy,
  HashLocationStrategy,
  isPlatformBrowser,
} from '@angular/common';

@Component({
  selector: 'trpx-activity-card',
  templateUrl: './activity-card.component.html',
  styleUrls: ['./activity-card.component.scss'],
})
export class ActivityCardComponent implements OnInit {
  @Input() activityCards: any;

  productSlug = '';
  showInfoText: { [key: string]: boolean } = {};
  hostUrl: any;

  constructor(
    private router: Router,
    private locationStrategy: LocationStrategy,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  ngOnInit(): void {}

  openLink(productSlug: string) {
    let urlTree: UrlTree = this.router.createUrlTree([
      `/product/${productSlug}`,
    ]);
    let url: string = this.router.serializeUrl(urlTree);

    if (this.locationStrategy instanceof HashLocationStrategy) {
      const baseHref = this.locationStrategy.getBaseHref();
      url = `${baseHref}#${url}`;
    }
    if (isPlatformBrowser(this.platformId)) {
      this.hostUrl = window.location.origin;
    }
    // window.open(url, '_blank');
    return `${this.hostUrl}${url}`;
  }
}
