import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'trpx-custom-select-box',
  templateUrl: './custom-select-box.component.html',
  styleUrls: ['./custom-select-box.component.scss'],
})
export class CustomSelectBoxComponent implements OnInit {
  @Output() countryCodeEvent = new EventEmitter<string>();
  @ViewChild('selectBox') selectBox: ElementRef | undefined;

  countryData: any;
  selectedCountryCode: string = '';
  searchText: string = '';
  showCountryListData: boolean = false;

  constructor(
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.getData().subscribe((response: any) => {
      this.countryData = response;
      this.cdr.detectChanges();
    });
  }

  ngOnInit(): void {
    this.setCountryCode();
  }

  getData() {
    return this.http.get('./assets/json/countrycodes.json');
  }

  filterCountryData(): { name: string; dial_code: string }[] {
    if (!this.countryData) {
      console.error('Country data is not available.');
      return [];
    }

    if (!this.searchText.trim()) {
      return this.countryData;
    }

    return this.countryData.filter(
      (country: { name: string; dial_code: string | string[] }) => {
        if (typeof country.dial_code === 'string') {
          return (
            country.name
              .toLowerCase()
              .includes(this.searchText.toLowerCase()) ||
            country.dial_code?.includes(this.searchText)
          );
        } else if (Array.isArray(country.dial_code)) {
          return (
            country.name
              .toLowerCase()
              .includes(this.searchText.toLowerCase()) ||
            country.dial_code?.includes(this.searchText)
          );
        } else {
          console.error('Invalid dial code format:', country.dial_code);
          return false;
        }
      }
    );
  }

  changeCountryCode(countryCode: any) {
    this.selectedCountryCode = countryCode;
    this.countryCodeEvent.emit(countryCode);
    this.showCountryListData = false;
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    if (isPlatformBrowser(this.platformId)) {
      if (
        this.selectBox &&
        !this.selectBox.nativeElement.contains(event.target)
      ) {
        this.showCountryListData = false;
      }
    }
  }

  setCountryCode() {
    if (isPlatformBrowser(this.platformId)) {
      const customerProfileLS = localStorage.getItem('customer_profile')
        ? localStorage.getItem('customer_profile')
        : null;
      const customerProfile = customerProfileLS
        ? JSON.parse(customerProfileLS)
        : null;
      this.selectedCountryCode = customerProfile?.country_code ?? '+91';
    }
  }
}
